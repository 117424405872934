import {
    Alert,
    Form,
    Button,
    Icon,
    SpaceBetween,
    ContentLayout,
    Header,
    Box,
} from '@amzn/awsui-components-react-v3';
import { pick, isEmpty } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { ExpiredClassModal } from '@/components';
import CapacityCheckModal, * as STATUSES from '@/components/capacityCheckModal/CapacityCheckModal';
import ClassDurationWarningModal from '@/components/classDurationWarningModal/ClassDurationWarningModal';
import InstructorAssign from '@/components/instructorAssign/InstructorAssign';
import JamFormSection from '@/components/jamFormSection/JamFormSection';
import { useProvider } from '@/data/ProviderContext';
import { useFlags } from '@/utils/flags';
import { parseError } from '@/utils/gonzo-error-parse-utils';
import { paths } from '@/utils/paths';
import { getDateTimeData, getEpochDate, timeHasPassed } from '@/utils/timestamp-utils';
import { useUserInfo } from '@/utils/userInfo';

import environment from '@/.config/environment';

import AdvancedSettings from './advancedSettings/AdvancedSettings';
import ClassAccess from './classAccess/ClassAccess';
import { messages } from './ClassForm.messages';
import {
    LOCATION_TYPES,
    handleFormValueChange,
    transformIncomingDataToFormData,
    transformIncomingDataToDateTimeData,
    initialFieldsInvalidState,
    validateData,
    prepareFormData,
    putUserEmailFirstInArray,
    getFirstErrorSectionId,
    CLASS_ACCESS_TYPES,
    waitForNode,
    getStudentListEditState,
    DEFAULT_JAM_TEAM_SIZE,
    getJamTraining,
    LOCATION_SOURCES,
} from './ClassForm.utils';
import ClassLocation from './classLocation/ClassLocation';
import ClassTime from './classTime/ClassTime';
import CourseInformation from './courseInformation/CourseInformation';
import CourseInformationV2 from './courseInformation/CourseInformationV2';
import StudentCode from './studentCode/StudentCode';

import './ClassForm.css';

const ClassForm = ({ onSubmit, onError, classroomId, data, state, jamTrainings }) => {
    const flags = useFlags();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation();
    const userInfo = useUserInfo();
    const providerFromStorage = useProvider();
    const isATP = providerFromStorage?.type !== 'DIRECT';
    const minClassCapacity = isATP ? 0 : 1;
    const authorizedSubProviderArns = Array.isArray(providerFromStorage?.subProviderData)
        ? providerFromStorage.subProviderData.map((sub) => sub.providerArn)
        : [];

    const [formProcessing, setFormProcessing] = useState(false);
    let initialClassDataState = {
        courseId: '',
        courseVersionId: '',
        langLocale: '',
        locationType: LOCATION_TYPES.virtual,
        classCapacity: `${minClassCapacity}`,
        virtualUrl: '',
        timezone: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        instructors: [],
        ...transformIncomingDataToFormData(data),
    };

    if (flags.studentRoster) {
        initialClassDataState = {
            ...initialClassDataState,
            accessType: CLASS_ACCESS_TYPES.roster,
            ...transformIncomingDataToFormData(data),
        };
    }

    if (flags.multiGilmoreId && providerFromStorage?.requiresSubProvider) {
        //ensure all sub providers are present in initialClassDataState.capacityBySubProvider
        if (!initialClassDataState.capacityBySubProvider) {
            initialClassDataState.capacityBySubProvider = {};
        }
        if (providerFromStorage && !isEmpty(providerFromStorage.subProviderData)) {
            providerFromStorage.subProviderData.forEach((subProvider) => {
                if (!initialClassDataState.capacityBySubProvider[subProvider.providerArn]) {
                    initialClassDataState.capacityBySubProvider[subProvider.providerArn] = {
                        capacity: '0',
                    };
                }
            });
        }
    }

    if (flags.aurousIntegration) {
        initialClassDataState = {
            ...initialClassDataState,
            learningActivityID: '',
            source: LOCATION_SOURCES.DEFAULT,
            ...transformIncomingDataToFormData(data),
        };
    }

    const [classData, setClassData] = useState(initialClassDataState);
    const [classCapacityCheckStatus, classCapacityCheckStatusSet] = useState(STATUSES.NO_NEED);
    const [availableQuantity, availableQuantitySet] = useState(0);
    const isNewClass = classroomId === undefined;
    const isGrimsbyClass = flags.grimsbyIntegration && !!classData.learningActivityID; // https://sim.amazon.com/issues/BKR-7186
    const {
        grimsby: { smtUrl },
    } = environment(window);
    const getGrimsbyActivityUrl = (activityId) => `${smtUrl}/activities/${activityId}`;

    // Jam initialization
    const [jamInformation, setJamInformation] = useState({});
    const jamTraining = getJamTraining(jamTrainings);
    let jamEventDateTimes = {};
    let jamTeamSize = DEFAULT_JAM_TEAM_SIZE;
    if (jamTraining) {
        jamEventDateTimes = getDateTimeData(jamTraining.metaData);
        jamTeamSize = jamTraining.metaData.maxTeamSize;
    }
    const [jamInputData, jamInputDataSet] = useState({
        ...jamEventDateTimes,
        trainingId: jamTraining?.trainingId,
        teamSize: jamTeamSize,
    });
    // Jam end

    let providerName = data?.trainingProviderName; // provider name when editing class
    let providerType = data?.trainingProviderType; // provider type when editing class
    if (isNewClass) {
        providerName = providerFromStorage?.name;
        providerType = providerFromStorage?.type;
    }
    // set default valid state of all fields to true
    const [fieldsInvalid, setFieldsInvalid] = useState(initialFieldsInvalidState);
    const [stateFieldsInvalid, setStateFieldsInvalid] = useState(state?.invalidFields);
    const [dateTimeData, setDateTimeData] = useState(transformIncomingDataToDateTimeData(data));
    const [durationWarningModalVisible, durationWarningModalVisibleSet] = useState(false);
    const [durationWarningAcknowledged, durationWarningAcknowledgedSet] = useState(false);

    const hasClassExpired = !isNewClass && timeHasPassed(data.endsOn);
    const hasClassStarted = isNewClass ? false : timeHasPassed(data.startsOn);
    const rosterSizeDifference = parseInt(classData?.classCapacity) - (data?.classCapacity ?? 0);

    useEffect(() => {
        if (userInfo.isLoading || !userInfo.email) return;

        if (classData.instructors.length === 0) {
            const initialValue = !userInfo.userIsTrainingCoordinator ? userInfo.email : '';
            handleFormValueChange({
                value: [initialValue],
                setData: setClassData,
                keyPath: 'instructors',
            });
        } else if (
            !userInfo.userIsTrainingCoordinator &&
            classData.instructors[0] !== userInfo.email
        ) {
            const initialValue = putUserEmailFirstInArray(classData.instructors, userInfo.email);
            handleFormValueChange({
                value: initialValue,
                setData: setClassData,
                keyPath: 'instructors',
            });
        }
    }, [userInfo, classData.instructors]);

    useEffect(() => {
        if (classCapacityCheckStatus === STATUSES.CLEARED) {
            handleFormSubmit(new Event('submit', { cancelable: true }));
        } else if (classCapacityCheckStatus === STATUSES.CANCELLED) {
            setFormProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classCapacityCheckStatus]);

    useEffect(() => {
        if (location.state?.scrollToSection) {
            const sectionId = location.state?.scrollToSection;
            waitForNode(sectionId).then((section) => {
                section.scrollIntoView({ behavior: 'smooth' });
                const stateWithoutScrollTarget = {
                    ...location.state,
                    scrollToSection: undefined,
                };
                history.replace({ ...location, state: stateWithoutScrollTarget });
            });
        }
    });

    useEffect(() => {
        const sectionId = getFirstErrorSectionId(fieldsInvalid);
        if (sectionId) {
            document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                const firstInvalidInput = document.querySelector('[aria-invalid]');
                if (firstInvalidInput) firstInvalidInput.focus();
            }, 500);
        }
    }, [fieldsInvalid]);

    const isJamOnly = useRef(
        (jamInfo) => jamInfo?.contentTypes?.includes('JAMS') && jamInfo?.contentTypes?.length === 1,
    );

    useEffect(() => {
        if (!stateFieldsInvalid?.instructors) return;
        const sectionId = getFirstErrorSectionId(stateFieldsInvalid);
        if (sectionId) {
            waitForNode(sectionId).then((section) => {
                section.scrollIntoView({ behavior: 'smooth' });
                let invalidInstructors = [];
                for (let i = 0; i < stateFieldsInvalid.instructors.length; i += 1) {
                    invalidInstructors = [
                        ...invalidInstructors,
                        ...stateFieldsInvalid.instructors[i].values,
                    ];
                }
                handleFormValueChange({
                    value: [...new Set([...classData.instructors, ...invalidInstructors])],
                    setData: setClassData,
                    keyPath: 'instructors',
                });
                setFieldsInvalid(stateFieldsInvalid);
                setStateFieldsInvalid(undefined);
                setTimeout(() => {
                    const firstInvalidInput = document.querySelector('[aria-invalid]');
                    if (firstInvalidInput) firstInvalidInput.focus();
                }, 500);
            });
        }
    }, [stateFieldsInvalid, classData.instructors]);

    const acknowledgeDurationWarning = () => {
        durationWarningAcknowledgedSet(true);
        durationWarningModalVisibleSet(false);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Disable form action buttons to provide user feedback
        setFormProcessing(true);
        setFieldsInvalid(initialFieldsInvalidState);

        // Skip instructor validation if no instructors were added by a Training Coordinator
        if (
            userInfo.userIsTrainingCoordinator &&
            classData.instructors.every((email) => email === '')
        ) {
            classData.instructors = [];
        }

        const jamOnlyClassroom = isJamOnly.current(jamInformation);
        const jamData = jamInformation?.challengeSetID
            ? {
                  ...jamInputData,
                  challengeId: jamInformation?.challengeSetID,
                  isJamOnly: jamOnlyClassroom,
              }
            : {};
        const overridenClassData = jamOnlyClassroom
            ? {
                  ...classData,
                  startsOn: jamData.startsOn,
                  endsOn: jamData.endsOn || jamData.startsOn + 3600 * jamData.duration,
              }
            : classData;
        const { dataValidity, allValid } = validateData(
            {
                ...overridenClassData,
                ...dateTimeData,
                shortClassDurationAcknowledged: durationWarningAcknowledged || jamOnlyClassroom,
                jamData,
            },
            userInfo,
            jamTrainings,
            data,
        );

        // if any values are false
        if (!allValid) {
            setFieldsInvalid(dataValidity);
            setFormProcessing(false);
            return;
        }

        // show warning modal if trying to change roster size
        const needToStopForCapacityCheckModal =
            !isNewClass &&
            rosterSizeDifference !== 0 &&
            classCapacityCheckStatus !== STATUSES.CLEARED;

        if (needToStopForCapacityCheckModal && providerFromStorage?.type === 'ATP') {
            classCapacityCheckStatusSet(STATUSES.NEED_TO_CLEAR);
            setFormProcessing(false);
            return;
        }

        const mutationVars = prepareFormData(
            {
                ...classData,
                ...getEpochDate(dateTimeData, classData.timezone),
                classroomId,
                providerArn: data?.providerArn || providerFromStorage?.arn,
                authorizedSubProviderArns,
                jamData,
            },
            { flags },
        );

        const checkClassCapacityAndVirtualStocks = (invalidFields) => {
            if (Array.isArray(invalidFields?.classCapacity)) {
                invalidFields.classCapacity.some((errorCode) => {
                    if (
                        errorCode.code === 'IncreaseInvalid' ||
                        errorCode.code === 'AvailableVirtualStocksMismatch'
                    ) {
                        classCapacityCheckStatusSet(STATUSES.LICENSE_ERROR);
                        availableQuantitySet(errorCode?.values || 0);
                        return true;
                    }
                    return false;
                });
            }
        };

        const checkInvalidInstructors = (invalidFields, classId) => {
            if (isNewClass && Array.isArray(invalidFields?.instructors) && classId) {
                history.push(paths.classEditPage(classId), { invalidFields });
            }
        };

        return onSubmit(mutationVars)
            .then((responseData) => {
                setFormProcessing(false);
                if (responseData) {
                    history.push(
                        paths.classDetailPage(encodeURIComponent(responseData.classroomId)),
                        getStudentListEditState(responseData.classroomId, location),
                    );
                }
            })
            .catch((err) => {
                setFormProcessing(false);
                try {
                    const errObj = JSON.parse(err);
                    const invalidFields = parseError(errObj);
                    checkClassCapacityAndVirtualStocks(invalidFields);
                    checkInvalidInstructors(invalidFields, errObj.classroomId);
                    setFieldsInvalid(invalidFields);
                } catch (anotherError) {
                    // the original error was not because of backend validation, escalate
                    console.log('original error', err);
                    // weird behavior from graphql where the caught error is undefined
                    onError(err || 'graphql error');
                }
            });
    };

    const FormActions = () => (
        <SpaceBetween direction='horizontal' size='xs'>
            {!isNewClass ? (
                <Button
                    variant='secondary'
                    disabled={formProcessing}
                    onClick={() =>
                        history.push(paths.classDetailPage(encodeURIComponent(classroomId)))
                    }
                >
                    {formatMessage(messages.cancelButtonText)}
                </Button>
            ) : null}
            <Button
                variant='primary'
                loading={formProcessing}
                disabled={hasClassExpired}
                ariaLabel={formatMessage(messages.saveButtonLabel)}
                onClick={handleFormSubmit}
            >
                {formatMessage(messages.saveButtonText)}
            </Button>
        </SpaceBetween>
    );

    return (
        <div>
            {isGrimsbyClass ? (
                <Alert>
                    {formatMessage(messages.grimsbyClassEditBanner)}
                    <Box padding={{ horizontal: 'xs' }} margin={{ top: 's' }}>
                        <a href={getGrimsbyActivityUrl(classData.learningActivityID)}>
                            Grimsby SMT <Icon variant='link' name='external' />
                        </a>
                    </Box>
                </Alert>
            ) : null}

            <ContentLayout
                header={
                    <Header variant='h1' actions={<FormActions />}>
                        {providerName ? `${providerName} - ` : ''}
                        {isNewClass
                            ? formatMessage(messages.addPageTitle)
                            : formatMessage(messages.editPageTitle)}
                    </Header>
                }
            >
                <Form actions={<FormActions />}>
                    <SpaceBetween direction='vertical' size='m'>
                        {flags.ccsIntegration ? (
                            <CourseInformationV2
                                handleFormValueChange={handleFormValueChange}
                                isNewClass={isNewClass}
                                fieldsInvalid={fieldsInvalid}
                                courseId={classData.courseId}
                                courseVersionId={classData.courseVersionId}
                                langLocale={classData.langLocale}
                                setClassData={setClassData}
                                setJamInformation={setJamInformation}
                            />
                        ) : (
                            <CourseInformation
                                handleFormValueChange={handleFormValueChange}
                                isNewClass={isNewClass}
                                fieldsInvalid={fieldsInvalid}
                                courseId={classData.courseId}
                                courseVersionId={classData.courseVersionId}
                                langLocale={classData.langLocale}
                                setClassData={setClassData}
                                setJamInformation={setJamInformation}
                            />
                        )}
                        <InstructorAssign
                            providerName={providerName}
                            providerType={providerType}
                            instructors={classData.instructors}
                            fieldsInvalid={fieldsInvalid}
                            instructorsSet={(newValue) => {
                                handleFormValueChange({
                                    value: newValue,
                                    setData: setClassData,
                                    keyPath: 'instructors',
                                });
                            }}
                            isNewClass={isNewClass}
                            isGrimsbyClass={isGrimsbyClass}
                        />
                        {flags.studentRoster && classData.accessType ? (
                            <ClassAccess
                                fieldsInvalid={fieldsInvalid}
                                hasClassExpired={hasClassExpired}
                                data={data}
                                setClassData={setClassData}
                                provider={providerFromStorage}
                                classData={classData}
                            />
                        ) : (
                            <StudentCode
                                fieldsInvalid={fieldsInvalid}
                                hasClassExpired={hasClassExpired}
                                classCapacity={classData.classCapacity}
                                data={data}
                                setClassData={setClassData}
                            />
                        )}

                        <ClassTime
                            {...{
                                fieldsInvalid,
                                timezone: classData.timezone,
                                setClassData,
                                dateTimeData,
                                setDateTimeData,
                                hasClassStarted,
                                hasClassExpired,
                                isNewClass,
                                startsOn: data?.startsOn,
                                endsOn: data?.endsOn,
                                isJamOnly: isJamOnly.current(jamInformation),
                                triggerDurationWarningModal: () =>
                                    durationWarningModalVisibleSet(true),
                                durationWarningAcknowledged,
                                durationWarningAcknowledgedSet,
                                isGrimsbyClass,
                            }}
                        />
                        <ClassLocation
                            {...pick(classData, [
                                'locationType',
                                'virtualUrl',
                                'addressLine1',
                                'addressLine2',
                                'city',
                                'state',
                                'postalCode',
                                'country',
                            ])}
                            setClassData={setClassData}
                            fieldsInvalid={fieldsInvalid}
                            handleFormSubmit={handleFormSubmit}
                            isGrimsbyClass={isGrimsbyClass}
                            isNewClass={isNewClass}
                        />
                        <JamFormSection
                            jamInputData={jamInputData}
                            jamInputDataSet={jamInputDataSet}
                            enabled={
                                jamInformation?.contentTypes?.includes('JAMS') ||
                                !!jamInformation?.challengeSetID
                            }
                            jamTraining={jamTraining}
                            fieldsInvalid={fieldsInvalid}
                        />
                        {flags.aurousIntegration ? (
                            <AdvancedSettings
                                {...pick(classData, ['learningActivityID', 'source'])}
                                setClassData={setClassData}
                                fieldsInvalid={fieldsInvalid}
                                handleFormSubmit={handleFormSubmit}
                            />
                        ) : null}
                        <CapacityCheckModal
                            status={classCapacityCheckStatus}
                            statusSet={classCapacityCheckStatusSet}
                            newSize={parseInt(classData.classCapacity)}
                            originalSize={data?.classCapacity}
                            availableQuantity={availableQuantity}
                            isSubProvider={
                                flags.multiGilmoreId && providerFromStorage?.requiresSubProvider
                            }
                        />
                        <ExpiredClassModal
                            initiallyExpired={hasClassExpired}
                            expiredFromBackend={(fieldsInvalid.endsOn || []).includes(
                                'EndDatePassed',
                            )}
                        />
                        <ClassDurationWarningModal
                            isVisible={durationWarningModalVisible}
                            onDismiss={() => durationWarningModalVisibleSet(false)}
                            onConfirm={acknowledgeDurationWarning}
                        />
                    </SpaceBetween>
                </Form>
            </ContentLayout>
        </div>
    );
};

export default ClassForm;
