import { defineMessages } from 'react-intl.macro';

const labMessages = defineMessages({
    dayOf: {
        id: 'NavigationDropdown.dayOf',
        description: 'Link to day-of page',
        defaultMessage: 'Class day-of',
    },
    classDetails: {
        id: 'NavigationDropdown.classDetails',
        description: 'Link to class details page',
        defaultMessage: 'Class details',
    },
    manageLabs: {
        id: 'NavigationDropdown.manageLabs',
        description: 'Link to manage labs page',
        defaultMessage: 'Manage labs',
    },
    studentView: {
        id: 'NavigationDropdown.studentView',
        description: 'Link to student view',
        defaultMessage: 'Student view',
    },
    externalLabel: {
        id: 'NavigationDropdown.externalLabel',
        description: 'Aria text for external icon',
        defaultMessage: '(opens in new tab)',
    },
});

export default labMessages;
