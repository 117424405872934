import { getCurrentUser } from '@/utils/helpers';

import { redirectToLoginPage } from './authService';

/**
 * Pulls the current identity provider from the current token, then initiates a login redirect against that provider
 * with email verification.
 */
export async function signInWithCurrentProvider(verifyEmail = true) {
    const { public_provider_name } = await getCurrentUser();
    const idpName = public_provider_name || 'COGNITO';
    redirectToLoginPage(verifyEmail, idpName);
}
