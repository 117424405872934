import moment from 'moment-timezone';

export const dateTimeToUnix = (date, time, timezone) => {
    const formattedDateTime = `${date} ${time}`.replace(/\//g, '-');
    return moment.tz(formattedDateTime, timezone).unix();
};

export const isoDateTimeToUnix = (dateTimeData, timezone) => {
    const [date, timeWithOffset] = dateTimeData.split('T');
    const time = timeWithOffset.replace(/[-+].+/, '');
    return moment.tz(`${date} ${time}`, timezone).unix();
};

/**
 *
 * @param {*} param0
 * @param {string} timezone
 */
export const getEpochDate = ({ startDate, endDate }, timezone) => {
    const start = isoDateTimeToUnix(startDate, timezone);
    const end = isoDateTimeToUnix(endDate, timezone);

    return { startsOn: start, endsOn: end };
};

export const DATEPICKER_FORMAT = 'YYYY/MM/DD';

/**
 * @param {String} startsOn Start date in epoch format
 * @param {String} endsOn End date in epoch format
 * @param {String} timezone Timezone for dates
 * @param dateFormat Format of output date
 * @return {Object} Object containing dates and times for input values
 *
 * @note Start and end data is currently in epoch seconds
 */
export const getDateTimeData = ({ startsOn, endsOn, timezone, dateFormat }) => {
    if (!startsOn || !endsOn) return defaultDateTimeData;

    const start = moment.unix(startsOn).tz(timezone);
    const end = moment.unix(endsOn).tz(timezone);

    return {
        startDate: start.format(dateFormat || DATEPICKER_FORMAT),
        endDate: end.format(dateFormat || DATEPICKER_FORMAT),
        startTime: formatTime(start),
        endTime: formatTime(end),
    };
};

export const formatTime = (dateTime) => dateTime.format('HH:mm');

export const isInvalidDateRange = (start, end) => {
    if (!start || !end) return false;
    const startDate = moment(start);
    const endDate = moment(end);
    return startDate.isAfter(endDate);
};

export const isInvalidDateDiff = (start, end) => {
    if (!start || !end) return false;
    const startDate = moment(start);
    const endDate = moment(end);
    return endDate.diff(startDate, 'weeks') >= 5;
};

export const isInvalidTime = ({ startTime, endTime, startDate, endDate }) => {
    if (startDate !== endDate) return false;
    if (startTime && endTime && startTime >= endTime) return true;
    return false;
};

export const isDateTimeInPast = (date, timezone) => {
    if (!date || !timezone) return false;
    const dateTime = isoDateTimeToUnix(date, timezone);
    return moment.tz(moment.now(), timezone).unix() > dateTime;
};

export const timeHasPassed = (unixTime) => moment.unix(unixTime).isBefore(moment());

export const formatDateString = (mom) => mom.format(DATEPICKER_FORMAT);

export const sameStartAndEnd = ({ startDate, endDate }) => startDate === endDate;

export const defaultDateTimeData = {
    startDate: moment().add(1, 'days').hours(9).minutes(30).format(),
    endDate: moment().add(30, 'days').hours(17).minutes(0).format(),
};

export const unixToDateString = (unixTime, timezone, addWeeks = 0) =>
    moment.unix(unixTime).tz(timezone).add(addWeeks, 'weeks').format();

export const isEndDateEnabled = (currentEndDate, newEndDate, hasClassStarted) => {
    return (
        !isInvalidDateDiff(currentEndDate, newEndDate) &&
        (!hasClassStarted || !isInvalidDateRange(currentEndDate, newEndDate))
    );
};

export const nowInUnixTimeInSec = () => moment().unix();

/**
 * Custom method that returns time in a formatted string with
 * short time zone name, ex: 9:00 AM- 10:00 AM
 * @param {Number} value Date value to be formatted, expected in unix format
 * @param {String} timezone Timezone for date value
 * @return {String} Formatted value of time based on locale settings
 */
export const toDetailPageTimeFormat = (value, timezone) =>
    moment.unix(value).tz(timezone).format('hh:mm A');

export const durationInHours = ({ startsOn, endsOn, timezone }) =>
    Math.round(moment.unix(endsOn).tz(timezone).diff(moment.unix(startsOn).tz(timezone), 'hours'));

/**
 *
 * (moment('2023-04-01'), moment.unix(1617235200)) => true
 * (moment('2023-04-01'), moment('2023-05-01)) => false
 * @param {Moment} moment1
 * @param {Moment} moment2
 * @returns
 */
export const isSameDay = (moment1, moment2) => moment1.isSame(moment2, 'day');

/**
 * Converts a Moment date into a string suitable for screenreaders
 *
 * @param {Moment} mDate
 * @returns {String} The date represented as one would speak it, e.g. "Monday May 20th, 2024"
 */
export const dateToLanguage = (mDate) => mDate.format('dddd MMMM Do, YYYY');
