import {
    Box,
    Button,
    ColumnLayout,
    Container,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { WidgetContainer, JamStatusWidget } from '@/components';
import { JamHeader } from '@/components/jamHeader/JamHeader';
import parentMessages from '@/containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { DATEPICKER_FORMAT, getDateTimeData, toDetailPageTimeFormat } from '@/utils';

import { messages } from './JamDetailsSection.messages';

import './JamDetailsSection.scss';

const { dateLabel, timeLabel } = parentMessages;

const { teamSizeLabel, teamSizeValueFormat, testEventUrlLabel, adminConsoleBtnLabel } = messages;

const TIMEOUT_DELAY = 5000;
const DEBOUNCE_DELAY = 4000;

const JamDetailsSection = ({ jamTrainings, classroomRefetch = () => {} }) => {
    const { formatMessage } = useIntl();

    let firstValidTraining = jamTrainings?.find((training) => training?.trainingId);

    const timeoutId = useRef(null);

    const debounceRefetch = useMemo(
        () => debounce(classroomRefetch, DEBOUNCE_DELAY),
        [classroomRefetch],
    );

    useEffect(() => {
        return () => {
            timeoutId.current && clearTimeout(timeoutId.current);
        };
    }, []);

    if (!firstValidTraining) {
        return null;
    }
    const { eventStatus, startsOn, endsOn, timezone, maxTeamSize, testEventURL, adminEventURL } =
        firstValidTraining.metaData;

    const { startDate } = getDateTimeData({
        startsOn,
        endsOn,
        timezone,
        dateFormat: DATEPICKER_FORMAT,
    });

    if (firstValidTraining.fulfillmentStatus === 'PENDING') {
        timeoutId.current = setTimeout(debounceRefetch, TIMEOUT_DELAY);
    } else {
        clearTimeout(timeoutId.current);
    }

    const mergedDisplayStatus = eventStatus || firstValidTraining.fulfillmentStatus;

    return (
        <Box margin={{ bottom: 'l' }} data-testid='class-details-jam-section'>
            <Container
                header={
                    <JamHeader
                        showDescription={false}
                        actions={
                            <SpaceBetween direction='horizontal' size='m'>
                                {!!testEventURL && (
                                    <Button
                                        iconName='external'
                                        iconAlign='right'
                                        target='_blank'
                                        href={testEventURL}
                                    >
                                        {formatMessage(testEventUrlLabel)}
                                    </Button>
                                )}
                                {!!adminEventURL && (
                                    <Button
                                        iconName='external'
                                        iconAlign='right'
                                        target='_blank'
                                        href={adminEventURL}
                                    >
                                        {formatMessage(adminConsoleBtnLabel)}
                                    </Button>
                                )}
                            </SpaceBetween>
                        }
                    />
                }
            >
                <div className='jam-detail__container'>
                    <ColumnLayout columns={4} borders='all'>
                        <WidgetContainer title={formatMessage(dateLabel)}>
                            <span> {startDate} </span>
                        </WidgetContainer>
                        <WidgetContainer title={formatMessage(timeLabel)}>
                            {[startsOn, endsOn]
                                .map((value) => toDetailPageTimeFormat(value, timezone))
                                .join(' - ')}
                        </WidgetContainer>
                        <WidgetContainer title={formatMessage(teamSizeLabel)}>
                            {formatMessage(teamSizeValueFormat, { num: maxTeamSize })}
                        </WidgetContainer>
                        <JamStatusWidget status={mergedDisplayStatus} />
                    </ColumnLayout>
                </div>
            </Container>
        </Box>
    );
};

export default JamDetailsSection;
