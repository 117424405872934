import { get as _get } from 'lodash';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components';
import { LabList, JamList } from '@/components/labList/LabList';
import { Layout } from '@/containers/Layout';
import { useClassroomData } from '@/data/useClassroomData';
import { classPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';
import { isEmpty } from '@/utils/helpers';

import messages from './ClassPage.messages';

const ClassPage = () => {
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;

    const { formatMessage } = useIntl();
    const { classData } = useClassroomData(classroomId);
    const courseTitle = _get(classData, 'course.title');
    const classAbstract = _get(classData, 'course.abstract');

    const jamTrainings = classData?.jamTrainings?.trainings ?? [];

    return (
        <Layout
            breadcrumbItems={[
                classesPageBreadcrumb(formatMessage),
                classPageBreadcrumb(formatMessage, classroomId),
            ]}
            useMaxWidth
        >
            <Helmet>
                <title>{courseTitle}</title>
            </Helmet>
            <Loader message={formatMessage(messages.loading)} isLoading={isEmpty(classData)}>
                <LabList
                    title={courseTitle}
                    description={classAbstract}
                    labs={classData?.content}
                />
                <JamList
                    title={courseTitle}
                    jamTrainings={jamTrainings}
                    hideActions={jamTrainings.length && classData?.content?.length}
                />
            </Loader>
        </Layout>
    );
};

export default ClassPage;
