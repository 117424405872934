import { Container, Spinner } from '@amzn/awsui-components-react-v3';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { GilmoreHealthBanner } from '@/components';
import ClassAttendanceList from '@/components/classAttendance/ClassAttendanceList';
import messages from '@/containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { Layout } from '@/containers/Layout';
import { useGetAttendanceListingEnabled } from '@/data/useAttendanceManagement';
import { useClassroomData } from '@/data/useClassroomData';
import { useGetLearningActivity } from '@/data/useLearningActivity';
import { paths } from '@/utils';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';

import attendancePageMessages from './ClassAttendancePage.messages';

const ClassAttendancePage = () => {
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const { formatMessage } = useIntl();
    const { classData } = useClassroomData(classroomId, {
        withConsistencyRetry: true,
        withInstructors: false,
    });
    const { data: learnerActivity } = useGetLearningActivity(classData);
    const enableAttendanceMarking = useGetAttendanceListingEnabled(learnerActivity);

    return (
        <Layout
            breadcrumbItems={[
                classesPageBreadcrumb(formatMessage),
                classDetailPageBreadcrumb(formatMessage, classroomId),
                {
                    text: 'Attendance',
                    href: paths.classEditPage(classroomId),
                },
            ]}
        >
            <Helmet>
                <title>{formatMessage(attendancePageMessages.pageTitle)}</title>
            </Helmet>
            <GilmoreHealthBanner />

            {enableAttendanceMarking ? (
                <ClassAttendanceList classroom={classData} learningActivity={learnerActivity} />
            ) : (
                <Container>
                    {' '}
                    <Spinner /> {formatMessage(messages.classLoading)}
                </Container>
            )}
        </Layout>
    );
};

export default ClassAttendancePage;
