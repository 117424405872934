import { Header } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';

import { messages } from '@/components/jamFormSection/JamFormSection.messages';

const { sectionHeaderDescription, sectionHeaderBody } = messages;

export const JamHeader = ({ showDescription = true, actions }) => {
    const { formatMessage } = useIntl();
    return (
        <Header
            variant='h2'
            description={showDescription && formatMessage(sectionHeaderDescription)}
            actions={actions}
        >
            <span data-testid='class-form-jam-section-header'>
                {formatMessage(sectionHeaderBody)}
            </span>
        </Header>
    );
};

export default JamHeader;
