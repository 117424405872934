export const listAuthorizedCoursesMockData = [
    {
        title: 'Amazon SageMaker Studio for Data Scientists',
        id: '482747f9-c1b7-4a10-b038-da810c3d3ffc',
    },
    {
        title: 'Migrating to AWS',
        id: 'c66b4f90-4202-486c-85cc-b58103af3146',
    },
];

export const listMajorVersionsMockData = {
    '482747f9-c1b7-4a10-b038-da810c3d3ffc': [
        {
            collectionArn:
                'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-DD-300-MLASMS-1',
            collectionTitle: 'Amazon SageMaker Studio for Data Scientists v1.x',
            supportedLangLocales: ['es-LA', 'pt-BR', 'ja-JP', 'en-US', 'ko-KR', 'zh-CN'],
        },
    ],
    'c66b4f90-4202-486c-85cc-b58103af3146': [
        {
            collectionArn:
                'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-TF-200-MTMAWS-2',
            collectionTitle: 'Migrating to AWS v2.x',
            supportedLangLocales: [
                'zh-CN',
                'es-LA',
                'pt-BR',
                'ja-JP',
                'de-DE',
                'zh-TW',
                'en-US',
                'id-ID',
                'ko-KR',
                'it-IT',
                'fr-FR',
            ],
        },
        {
            collectionArn:
                'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-TF-200-MTMAWS-3',
            collectionTitle: 'Migrating to AWS v3.x',
            supportedLangLocales: [
                'zh-CN',
                'es-LA',
                'pt-BR',
                'ja-JP',
                'de-DE',
                'zh-TW',
                'en-US',
                'id-ID',
                'th-TH',
                'ko-KR',
                'it-IT',
                'fr-FR',
            ],
        },
    ],
};

export const getAllCourseVersionsMockData = {
    'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-DD-300-MLASMS-1': {
        'es-LA': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'pt-BR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ja-JP': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.1.7-ae80f86b',
                versionId: '1.1.7-ae80f86b',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'en-US': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.0.6-28d16538',
                versionId: '1.0.6-28d16538',
                createdOn: 1696285455,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.1.7-ae80f86b',
                versionId: '1.1.7-ae80f86b',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ko-KR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.1.7-ae80f86b',
                versionId: '1.1.7-ae80f86b',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'zh-CN': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.1.7-ae80f86b',
                versionId: '1.1.7-ae80f86b',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-32e65fc5',
                versionId: '1.2.3-32e65fc5',
                createdOn: 1696285456,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
    },
    'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-TF-200-MTMAWS-2': {
        'zh-CN': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'es-LA': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'pt-BR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ja-JP': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'de-DE': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'zh-TW': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'en-US': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'id-ID': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ko-KR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'it-IT': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'fr-FR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-2:2.1.9-0493b672',
                versionId: '2.1.9-0493b672',
                createdOn: 1696285498,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
    },
    'arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-TF-200-MTMAWS-3': {
        'zh-CN': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'es-LA': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'pt-BR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ja-JP': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.2.6-6766dd15',
                versionId: '3.2.6-6766dd15',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'de-DE': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'zh-TW': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'en-US': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.1.0-bab03583',
                versionId: '3.1.0-bab03583',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.2.6-6766dd15',
                versionId: '3.2.6-6766dd15',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'id-ID': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'th-TH': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'ko-KR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.1.0-bab03583',
                versionId: '3.1.0-bab03583',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.2.6-6766dd15',
                versionId: '3.2.6-6766dd15',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'it-IT': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
        'fr-FR': [
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.0.7-5554ab76',
                versionId: '3.0.7-5554ab76',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
            {
                courseId:
                    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-MTMAWS-3:3.3.6-993e837c',
                versionId: '3.3.6-993e837c',
                createdOn: 1696285499,
                status: 'VALIDATED',
                availability: 'ACTIVE',
            },
        ],
    },
};
