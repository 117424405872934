import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    labNavigationHeader: {
        id: 'labNavigation.header',
        defaultMessage: 'Labs',
    },
});

export default messages;
