import { Box } from '@amzn/awsui-components-react-v3';
import { Children } from 'react';

/**
 * Contains a widget within a description list.
 * @param title - the title of the widget
 * @param className - styles to apply to the description list
 * @param children - children elements which will be list items
 */
const WidgetContainer = (props) => {
    const { title, children, className } = props;
    return (
        <div className={className} data-testid={props['data-testid']}>
            <Box variant='awsui-key-label'>{title}</Box>
            {Children.map(children, (child) => (
                <div className={`${className ?? 'widget-container'}__child`}>{child}</div>
            ))}
        </div>
    );
};

export default WidgetContainer;
