import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    termsLink: {
        id: 'app.footer.termsLink',
        description: 'Hypertext link (localized) for the legal terms in the footer',
        defaultMessage: 'https://aws.amazon.com/legal/?nc1=f_cc',
    },
    termsLinkText: {
        id: 'app.footer.termsLinkText',
        description: 'Text for the terms link in the footer',
        defaultMessage: 'Legal',
    },
    privacyLinkText: {
        id: 'app.footer.privacyLinkText',
        description: 'Text for the privacy link in the footer',
        defaultMessage: 'Privacy',
    },
    supportLinkText: {
        id: 'app.footer.supportLinkText',
        description: 'Text for the support link in the footer',
        defaultMessage: 'Support',
    },
    copyrightText: {
        id: 'app.footer.copyrightText',
        description: 'Text for copyright message',
        defaultMessage: 'Amazon Web Services, Inc. or its affiliates. All rights reserved.',
    },
});

export default messages;
