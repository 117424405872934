import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import InfoModal from '@/components/infoModal/InfoModal';
import { useGilmoreEvents } from '@/data/useGilmoreEvents';

import { messages } from '../ClassForm.messages';
import {
    handleFormValueChange,
    MAX_CLASS_CAPACITY,
    MIN_CLASS_CAPACITY,
    getDisabledCapacityInputHint,
} from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import '../ClassForm.css';

const StudentCode = ({ fieldsInvalid, classCapacity, data, hasClassExpired, setClassData }) => {
    const { formatMessage } = useIntl();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const { mostRecent, thereIsActiveEvent } = useGilmoreEvents();
    const disabledCapacityHint = !thereIsActiveEvent
        ? null
        : getDisabledCapacityInputHint(mostRecent, formatMessage);

    return (
        <Container
            // id='class-access'
            header={<Header variant='h3'>{formatMessage(messages.codeInfoHeader)}</Header>}
            data-testid='class-capacity__form-section'
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root='true'>
                    <p>{formatMessage(messages.multiUseCodeLabel)}</p>
                    <FormField
                        stretch
                        description={formatMessage(messages.numberOfStudentsHint, {
                            max: MAX_CLASS_CAPACITY,
                            min: MIN_CLASS_CAPACITY,
                        })}
                        constraintText={disabledCapacityHint}
                        label={
                            <div>
                                <span>{formatMessage(messages.numberOfStudentsLabel)}</span>
                                &nbsp;-&nbsp;
                                <button
                                    className='info-link'
                                    onClick={() => setOpenInfoModal(true)}
                                    aria-label={formatMessage(messages.infoButtonOpenLabel)}
                                >
                                    {formatMessage(messages.infoButtonText)}
                                </button>
                            </div>
                        }
                        errorText={showErrorMessage(formatMessage, {
                            fieldsInvalid,
                            property: 'classCapacity',
                        })}
                    >
                        <Input
                            ariaRequired
                            invalid={!!fieldsInvalid.classCapacity}
                            disabled={!!(hasClassExpired || thereIsActiveEvent)}
                            value={classCapacity}
                            type='number'
                            data-testid='class-form__class-capacity'
                            onChange={(e) => {
                                handleFormValueChange({
                                    value: Math.min(
                                        MAX_CLASS_CAPACITY,
                                        Math.max(e.detail.value, MIN_CLASS_CAPACITY),
                                    ).toString(),
                                    setData: setClassData,
                                    keyPath: 'classCapacity',
                                });
                                const accessCode =
                                    parseInt(e.detail.value) !== data?.classCapacity
                                        ? data?.accessCodes[0]?.accessCode
                                        : '';
                                handleFormValueChange({
                                    value: accessCode,
                                    keyPath: 'accessCode',
                                    setData: setClassData,
                                });
                            }}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
            <InfoModal openInfoModal={openInfoModal} setOpenInfoModal={setOpenInfoModal} />
        </Container>
    );
};

export default StudentCode;
