import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    classDurationWarningBody: {
        id: 'classForm.classDurationWarning.modalBody',
        defaultMessage:
            'You have entered a start and end-date that spans less than the recommended {num} weeks. ' +
            'Students are unable to access labs after the specified class end-date. ' +
            'The amount of time labs are made available does not increase the cost. ' +
            'Are you sure you want to reduce the duration of time students can revisit the labs for this class?',
    },
    classDurationWarningHeader: {
        id: 'classForm.classDurationWarning.modalHeader',
        defaultMessage: 'Shortened class duration',
    },
    classDurationWarningConfirmButton: {
        id: 'classForm.classDurationWarning.confirmBtn',
        defaultMessage: 'Yes',
    },
});
