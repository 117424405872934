const environment = (window) => {
    const cognito = window.localStorage.getItem('__BEAKER_COGNITO__');
    if (window.__CONFIGURATION__) {
        return Object.assign({}, window.__CONFIGURATION__, JSON.parse(cognito));
    }

    if (window.sessionStorage.getItem('__E2E_TEST__') && process.env.NODE_ENV !== 'production') {
        try {
            return JSON.parse(window.sessionStorage.getItem('__E2E_TEST__'));
        } catch (error) {
            console.log(error);
        }
    }

    return { hostName: {}, studentUI: {}, domains: {}, grimsby: {} };
};

export default environment;
