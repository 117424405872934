import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    preloadLabel: {
        id: 'ingress.preload.button',
        defaultMessage: 'Pre-load all',
    },
    preloadSelectionLabel: {
        id: 'ingress.preload-selection.button',
        defaultMessage: 'Pre-load',
    },
    refresh: {
        id: 'ingress.button.refresh',
        defaultMessage: 'Refresh',
    },
    selectLabel: {
        id: 'ingress.header.select.label',
        defaultMessage: 'Selected Region',
    },
    optionSelectedLabel: {
        id: 'ingress.header.option.label',
        defaultMessage: 'Selected',
    },
    copyIngressToClipboard: {
        id: 'ingress.header.button.copyIngressToClipboard',
        defaultMessage: 'Copy Console URL',
    },
});

export default ingressTableMessages;
