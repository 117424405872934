import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'errorPage.pageTitle',
        description: 'Error page title',
        defaultMessage: 'Page not found - AWS Training and Certification',
    },
    pageHeading: {
        id: 'errorPage.pageHeading',
        description: 'Page heading',
        defaultMessage: '404 Error',
    },
    pageNotFound: {
        id: 'errorPage.pageNotFound',
        description: 'H2 level message',
        defaultMessage: 'Page not found',
    },
    pageAdvice: {
        id: 'errorPage.pageAdvice',
        description: 'Suggestion of what to do',
        defaultMessage: 'Please go back to the homepage.',
    },
    pageLink: {
        id: 'errorPage.pageLink',
        description: 'Link to homepage',
        defaultMessage: 'Homepage',
    },
});

export default messages;
