import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    provisionPoolModalTitle: {
        id: 'provisionPoolModal.title',
        defaultMessage: 'Provision lab pool',
    },
    provisionPoolModalMessage: {
        id: 'provisionPoolModal.message',
        defaultMessage:
            'Provisioned labs will be assigned to students in {region}, when they launch their labs from the student page.',
    },
    provisionPoolModalEdit: {
        id: 'provisionPoolModal.edit',
        defaultMessage: 'Edit',
    },
    provisionPoolModalCancel: {
        id: 'provisionPoolModal.cancel',
        defaultMessage: 'Cancel',
    },
    provisionPoolModalSubmit: {
        id: 'provisionPoolModal.submit',
        defaultMessage: 'Submit',
    },

    provisionPoolModalCreateInputLabel: {
        id: 'provisionPoolModal.create.inputLabel',
        defaultMessage: 'Number of labs to provision',
    },
    provisionPoolModalCreateInputDescription: {
        id: 'provisionPoolModal.create.inputDescription',
        defaultMessage:
            'Number of labs to provision Labs cannot exceed the number of learners in the class',
    },

    provisionPoolModalEditInputLabel: {
        id: 'provisionPoolModal.edit.inputLabel',
        defaultMessage: 'Additional labs to provision',
    },
    provisionPoolModalEditInputDescription: {
        id: 'provisionPoolModal.edit.inputDescription',
        defaultMessage:
            'This is in addition to previous lab pool, number of labs cannot exceed learners in the class.',
    },
});

export default messages;
