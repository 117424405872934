import { QueryClientProvider, QueryClient } from 'react-query';

import { calculateRetryDelay } from '@/utils/helpers';

const FIVE_MIN_IN_MS = 5 * 60 * 1000;

export const QueryClientProviderWrapper = ({ children }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 3,
                // Add attempt+1 as react-query attempts starts with 0.
                retryDelay: (attempt) => calculateRetryDelay(attempt + 1),
                cacheTime: FIVE_MIN_IN_MS,
            },
            mutations: {
                retry: 0,
            },
        },
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
