import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    signInAmazonEmployeeSSO: {
        id: 'IdentityErrorPage.signInMethod.AmazonEmployeeSSO',
        description: 'message for Amazon Employee SSO sign-in method',
        defaultMessage: 'Amazon Employee Single Sign-on',
    },
    signInAWSBuilderID: {
        id: 'IdentityErrorPage.signInMethod.AWSBuilderID',
        description: 'message for AWS Builder ID sign-in method',
        defaultMessage: 'AWS Builder ID',
    },
    signInOTP: {
        id: 'IdentityErrorPage.signInMethod.OTP',
        description: 'message for OTP sign-in method',
        defaultMessage: 'One-time Email Passcode',
    },
    signInLWA: {
        id: 'IdentityErrorPage.signInMethod.loginWithAmazon',
        description: 'message for Login with Amazon sign-in method',
        defaultMessage: 'Login with Amazon',
    },
    signInAPN: {
        id: 'IdentityErrorPage.signInMethod.AWSPartnerNetwork',
        description: 'message for AWS Partner Network sign-in method',
        defaultMessage: 'AWS Partner Network',
    },
    signInOrgSSO: {
        id: 'IdentityErrorPage.signInMethod.organizationSSO',
        description: 'message for Organization SSO sign-in method',
        defaultMessage: 'Organization SSO',
    },
});

export default messages;
