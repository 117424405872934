import { defineMessages } from 'react-intl.macro';

const reportTableMessages = defineMessages({
    reportDateColumn: {
        id: 'ReportTable.reportDateColumn',
        defaultMessage: 'Report date',
    },
    reportNameColumn: {
        id: 'ReportTable.reportNameColumn',
        defaultMessage: 'Report name',
    },
    reportDescriptionColumn: {
        id: 'ReportTable.reportDescriptionColumn',
        defaultMessage: 'Description',
    },
    reportDownloadColumn: {
        id: 'ReportTable.reportDownloadColumn',
        defaultMessage: 'Download report',
    },
    tableHeader: {
        id: 'ReportTable.tableHeader',
        defaultMessage: 'Report dashboard',
    },
    downloadButtonText: {
        id: 'ReportTable.downloadButtonText',
        defaultMessage: 'Download report',
    },
    tableEmpty: {
        id: 'ReportTable.tableEmpty',
        defaultMessage: 'No reports to display.',
    },
    previousPageLabel: {
        id: 'ReportTable.pagination.previousPageLabel',
        description: 'text for previous page button for pagination',
        defaultMessage: 'Previous page',
    },
    nextPageLabel: {
        id: 'ReportTable.pagination.nextPageLabel',
        description: 'text for next page button for pagination',
        defaultMessage: 'Next page',
    },
    pageLabel: {
        id: 'ReportTable.pagination.pageLabel',
        description: 'text for each page button for pagination',
        defaultMessage: 'Go to page {pageNumber}',
    },
});

export default reportTableMessages;
