import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'CreateClassPage.pageTitle',
        description: 'CreateClass page title',
        defaultMessage: 'Create class - AWS Training and Certification',
    },
    noCreateHeader: {
        id: 'ClassCreatePage.modal.noCreateHeader',
        description: 'header message for when class data is not created',
        defaultMessage: 'Class not created',
    },
    noCreateContent: {
        id: 'ClassCreatePage.modal.noCreateContent',
        description: 'body message for when class data is not created',
        defaultMessage: 'We were not able to create the class, try again.',
    },
    createClass409Error: {
        id: 'ClassCreatePage.modal.createClass409Error',
        description: 'body message for when class data is not created due to a 409 error',
        defaultMessage: 'We are having trouble processing the request, try again later',
    },
    closeModalButtonLabel: {
        id: 'ClassCreatePage.modal.closeButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default messages;
