import { isNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import localStorageDatabase from '../../modules/localStorageDb';

import { COLUMN_IDS, getPageSizeOptions } from './classlistTableConfig';

/**
 * ReactJS custom hook for retrieving classlisting UI preference (i.e. number of classroom to show per page).
 * Uses browser storage to persist the preference across sessions.
 */
export const useClasslistingPreference = ({
    isUserTrainingCoordinator,
    archivedOnly,
    isLoadingUserInfo,
}) => {
    const intl = useIntl();
    const [preferenceInitializationComplete, setPreferenceInitializationComplete] =
        useState(!isLoadingUserInfo);
    const [tablePreferences, setTablePreferences] = useState(
        getPreference(intl, isUserTrainingCoordinator, archivedOnly),
    );
    const preferenceUpdater = useMemo(() => {
        return (newPreference) => {
            savePreference(newPreference, archivedOnly);
            setTablePreferences(newPreference);
        };
    }, [setTablePreferences, archivedOnly]);

    useEffect(() => {
        if (!preferenceInitializationComplete && !isLoadingUserInfo) {
            const userPreference = getPreference(intl, isUserTrainingCoordinator, archivedOnly);
            setTablePreferences(userPreference);
            setPreferenceInitializationComplete(true);
        }
    }, [
        intl,
        isUserTrainingCoordinator,
        isLoadingUserInfo,
        preferenceInitializationComplete,
        archivedOnly,
        setTablePreferences,
    ]);

    return {
        tablePreferences,
        preferenceUpdater,
    };
};

const getStorageKey = (archivedOnly) => {
    if (archivedOnly) {
        return 'classlisting::preference::archivedOnly';
    }
    return 'classlisting::preference';
};

const getPreference = (intl, isUserTrainingCoordinator, archivedOnly, pageSizeOptions) => {
    let preferenceFromStorage;
    try {
        const preferenceJsonString = localStorageDatabase.get(getStorageKey(archivedOnly));
        preferenceFromStorage = JSON.parse(preferenceJsonString);
    } catch (e) {
        preferenceFromStorage = null;
    }
    if (isValidPreference(preferenceFromStorage)) {
        return preferenceFromStorage;
    } else {
        return getDefaultPreference(intl, isUserTrainingCoordinator, pageSizeOptions);
    }
};

const savePreference = (newPreference, archivedOnly) => {
    localStorageDatabase.set({
        id: getStorageKey(archivedOnly),
        value: JSON.stringify(newPreference),
    });
};

const getDefaultPreference = (intl, isUserTrainingCoordinator) => {
    const pageSizeOptions = getPageSizeOptions(intl.formatMessage);
    const visibleColumnIds = [
        COLUMN_IDS.courseTitle,
        COLUMN_IDS.startDate,
        COLUMN_IDS.startTime,
        COLUMN_IDS.timeZone,
        COLUMN_IDS.country,
        COLUMN_IDS.classStatus,
    ];
    if (isUserTrainingCoordinator) {
        visibleColumnIds.push(COLUMN_IDS.instructor);
        visibleColumnIds.push(COLUMN_IDS.createdBy);
    } else {
        visibleColumnIds.push(COLUMN_IDS.deliveryMethod);
        visibleColumnIds.push(COLUMN_IDS.endDate);
    }
    return {
        pageSize: pageSizeOptions[0].value,
        visibleContent: visibleColumnIds.map((id) => ({ id, visible: true })),
        wraplines: false,
        custom: 'table',
    };
};

const isValidPreference = (preference) => {
    return preference && isNumber(preference.pageSize);
};
