import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    forbiddenHeader: {
        id: 'errorFallback.forbidden.header',
        description: '403 error header',
        defaultMessage: 'Class unavailable',
    },
    forbiddenContent: {
        id: 'errorFallback.forbidden.content',
        description: '403 error content',
        defaultMessage: 'You can only {pageType} classes you are teaching.',
    },
    notFoundHeader: {
        id: 'errorFallback.notFound.header',
        description: '404 error message',
        defaultMessage: 'No class data found',
    },
    notFoundContent: {
        id: 'errorFallback.notFound.content',
        description: '404 error content',
        defaultMessage:
            'We were not able to retrieve the data for this class, try refreshing the page.',
    },
    serverErrorHeader: {
        id: 'errorFallback.serverError.header',
        description: '500 error message',
        defaultMessage: 'Something went wrong',
    },
    serverErrorContent: {
        id: 'errorFallback.serverError.content',
        description: '500 error content',
        defaultMessage: 'Verify the URL is correct and try again in a few minutes.',
    },
    getClassroomErrorButton: {
        id: 'errorFallback.getClassroom.error.button',
        description: 'error button when we cannot get classroom data',
        defaultMessage: 'Create class',
    },
    closeModalButtonLabel: {
        id: 'errorFallback.modal.closeButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default messages;
