import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    infoModalLabel: {
        id: 'infoModal.infoModalLabel',
        defaultMessage: 'Info panel',
    },
    closeLabel: {
        id: 'infoModal.closeLabel',
        defaultMessage: 'Close info panel',
    },
    studentCodeTitle: {
        id: 'infoModal.studentCodeTitle',
        defaultMessage: 'Student eKits',
    },
    studentCodePartnersOnly: {
        id: 'infoModal.studentCodePartnersOnly',
        defaultMessage: 'For AWS training partners only',
    },
    studentCodeEKits: {
        id: 'infoModal.studentCodeEKits',
        defaultMessage:
            'Student eKits are reserved or released as you add or remove students to a class.',
    },
    studentCodeSubProviderEKits: {
        id: 'infoModal.studentCodeSubProviderEKits',
        defaultMessage:
            'Student eKits are reserved or released as you add or remove students to a class and will be returned to your virtual stock after the class ends within 24 hours.',
    },
    studentCodeUnusedEKits: {
        id: 'infoModal.studentCodeUnusedEKits',
        defaultMessage:
            'Unused eKits will be returned to your virtual stock after the class ends <b>within 24 hours.</b>',
        values: {
            b: (chunks) => <b>{chunks}</b>,
        },
    },
    studentCodeUnusedSubProviderEKits: {
        id: 'infoModal.studentCodeUnusedSubProviderEKits',
        defaultMessage:
            'To recoup unused eKits after the class end-date, reach out to your Gilmore contact directly.',
    },
    gilmoreStorefrontLink: {
        id: 'infoModal.gilmoreStorefrontLink',
        defaultMessage: 'Gilmore storefront',
    },
    closeModalButtonText: {
        id: 'infoModal.closeModalButtonText',
        defaultMessage: 'Close',
    },
    closeModalButtonLabel: {
        id: 'infoModal.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
});
