import { Hub, Cache } from 'aws-amplify';
import { useState, useEffect } from 'react';

import { Loader } from '@/components';
import { clearProviderOutsideCtx } from '@/data/ProviderContext';
import { hasValidToken, redirectToLoginPage, SESSION_EXPIRED } from '@/modules';

const Authenticator = ({ children, globals }) => {
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        // Check for token multiple times with a delay in case a sign-in event happens
        let attempts = 1;
        const intervalId = setInterval(() => {
            hasValidToken().then((hasToken) => {
                if (hasToken) {
                    setSignedIn(true);
                    clearInterval(intervalId);
                } else if (attempts > 6) {
                    clearInterval(intervalId);
                    redirectToLoginPage();
                }
                attempts += 1;
            });
        }, 500);
        Hub.listen('auth', ({ payload: { data, event } }) => {
            if (event === 'signIn') {
                const token = data.getSignInUserSession().getIdToken().getJwtToken();
                Cache.setItem('federatedInfo', { token });
                setSignedIn(true);
            } else if (event === SESSION_EXPIRED) {
                Cache.removeItem('federatedInfo');
            } else if (event === 'oAuthSignOut') {
                clearProviderOutsideCtx(globals);
            }
        });
    }, [globals]);

    return <Loader isLoading={!signedIn}>{children}</Loader>;
};

export default Authenticator;
