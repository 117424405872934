import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    headerLabel: {
        id: 'removeStudentModal.headerLabel',
        defaultMessage: 'Remove student from roster',
    },
    closeModalButtonLabel: {
        id: 'removeStudentModal.closeModalButtonLabel',
        defaultMessage: 'Remove from roster',
    },
    closeModalButtonText: {
        id: 'removeStudentModal.closeModalButtonText',
        defaultMessage: 'Cancel',
    },
    removeStudentModalButtonText: {
        id: 'removeStudentModal.removeStudentModalButtonText',
        defaultMessage: 'Remove',
    },
    removeStudentModalBody: {
        id: 'removeStudentModal.removeStudentModalBody',
        defaultMessage: 'Remove {email} from student roster?',
    },
    removeAcceptedStudentAlert: {
        id: 'removeStudentModal.removeAcceptedStudentAlert',
        defaultMessage:
            'Student {email} has already accepted the invitation and thus consumed a license (eKit). If you remove this student, they will lose access to this class <bold>but the license will not be returned</bold>.',
    },
});
