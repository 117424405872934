import {
    Modal,
    Box,
    SpaceBetween,
    Button,
    FormField,
    Input,
} from '@amzn/awsui-components-react-v3';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useFlags } from '@/utils';

import messages from './index.messages';

import type { InputProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3';
import type { MessageDescriptor } from 'react-intl';

const {
    provisionPoolModalTitle,
    provisionPoolModalCreateInputLabel,
    provisionPoolModalCreateInputDescription,
    provisionPoolModalEditInputLabel,
    provisionPoolModalEditInputDescription,
    provisionPoolModalMessage,
    provisionPoolModalEdit,
    provisionPoolModalCancel,
    provisionPoolModalSubmit,
} = messages;

interface ProvisionPoolModalProps {
    buttonText: MessageDescriptor;
    inputLabel: MessageDescriptor;
    inputDescription: MessageDescriptor;
    region: string;
    maxLabs: number;
}

const ProvisionPoolModal = ({
    buttonText,
    inputLabel,
    inputDescription,
    maxLabs,
    region,
}: ProvisionPoolModalProps) => {
    const { formatMessage } = useIntl();
    const [isVisible, isVisibleSet] = useState(false);
    const [inputValue, inputValueSet] = useState('10');
    const { pooledLabs } = useFlags();

    const onChange = (e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        inputValueSet(Math.min(maxLabs, Math.max(+e.detail.value, 1)).toString());
    };

    if (!pooledLabs) return null;

    return (
        <>
            <Button onClick={() => isVisibleSet(true)}>{formatMessage(buttonText)}</Button>
            <Modal
                onDismiss={() => isVisibleSet(false)}
                visible={isVisible}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link'>
                                {formatMessage(provisionPoolModalCancel)}
                            </Button>
                            <Button variant='primary'>
                                {formatMessage(provisionPoolModalSubmit)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={formatMessage(provisionPoolModalTitle)}
            >
                <SpaceBetween direction='vertical' size='m'>
                    <FormField
                        label={formatMessage(inputLabel)}
                        description={formatMessage(inputDescription)}
                    >
                        <Input
                            type='number'
                            value={inputValue}
                            onChange={onChange}
                            inputMode='numeric'
                            data-testid='pooled-labs-input'
                            step={1}
                        />
                    </FormField>
                    <Box variant='p'>{formatMessage(provisionPoolModalMessage, { region })}</Box>
                </SpaceBetween>
            </Modal>
        </>
    );
};

export const CreateProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalTitle}
        inputLabel={provisionPoolModalCreateInputLabel}
        inputDescription={provisionPoolModalCreateInputDescription}
        {...props}
    />
);

export const EditProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalEdit}
        inputLabel={provisionPoolModalEditInputLabel}
        inputDescription={provisionPoolModalEditInputDescription}
        {...props}
    />
);
