import { supportedLocales } from '../i18n';

class InMemoryLocalStorageDatabase {
    #db = {};

    constructor(storage) {
        this.storage = storage;
    }

    set({ id, value }) {
        this.#db[id] = { id, value };
        this.storage.setItem(id, value);
    }

    get(id) {
        const item = this.#db[id];

        if (item) {
            return item.value;
        }

        const value = this.storage.getItem(id);
        this.#db[id] = { id, value };

        return value;
    }
}
class localStorageDB extends InMemoryLocalStorageDatabase {
    // eslint-disable-next-line no-useless-constructor
    constructor(storage) {
        super(storage);
    }

    selectedLocale() {
        return (
            supportedLocales.find((supportedLocale) => supportedLocale === this.get('locale')) ||
            'en-US'
        );
    }
}

export const localStorageDatabaseFactory = (globals = window) => {
    return new localStorageDB(globals.localStorage);
};

const localStorageDatabase = localStorageDatabaseFactory();
export default localStorageDatabase;
