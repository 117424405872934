import { capitalize, toLower, startsWith } from 'lodash';
import moment from 'moment';

import { COLUMN_IDS, TIME_UNITS } from './classlistTableConfig';
import { messages } from './ClassTable.messages';
import {
    getHandlers,
    getPropertyFilterConfiguration,
    getPropertyFilterI18nStrings,
} from './propertyFilterV3Support';

/**
 * Returns handlers for filtering logic.
 * By default returns handler for Table property filter from Polaris v2.1.
 * @param baseHandler should contain core filtering, which is extended with Property Filter version specific implementation.
 */
export const getFilterHandlers = (baseHandler) => getHandlers(baseHandler);

/**
 * Returns configuration needed by components used in classroom list filtering: PropertyFilter and DateRangePicker.
 */
export const getFilterComponentConfig = ({ intl, isUserTrainingCoordinator, filterConfig }) => {
    const propertyFilterConfiguration = getPropertyFilterConfiguration({
        intl,
        isUserTrainingCoordinator,
    });
    const propertyFilterI18nStrings = getPropertyFilterI18nStrings(intl, filterConfig.archivedMode);

    return {
        filterFields: propertyFilterConfiguration,
        propertyFilterI18nStrings: propertyFilterI18nStrings,
        dateRangePickerI18nStrings: getDateRangeI18nStrings(intl, filterConfig),
        dateRangePicker: getDateRangePickerConfig(filterConfig),
    };
};

const getDateRangeI18nStrings = (intl, filterConfig) => {
    const { formatMessage } = intl;
    const timeUnitToMessage = {
        [TIME_UNITS.SECOND]: [messages.filterDateUnitSecond, messages.filterDateUnitSeconds],
        [TIME_UNITS.MINUTE]: [messages.filterDateUnitMinute, messages.filterDateUnitMinutes],
        [TIME_UNITS.HOUR]: [messages.filterDateUnitHour, messages.filterDateUnitHours],
        [TIME_UNITS.DAY]: [messages.filterDateUnitDay, messages.filterDateUnitDays],
        [TIME_UNITS.WEEK]: [messages.filterDateUnitWeek, messages.filterDateUnitWeeks],
        [TIME_UNITS.MONTH]: [messages.filterDateUnitMonth, messages.filterDateUnitMonths],
        [TIME_UNITS.YEAR]: [messages.filterDateUnitYear, messages.filterDateUnitYears],
    };

    const timeUnitFormatter = (unit, value) => {
        const messageIndexToUse = !value || Math.abs(value) === 1 ? 0 : 1;
        return formatMessage(timeUnitToMessage[unit][messageIndexToUse]);
    };

    /**
     * relativeRange format:
     * {
     *     key?: string,
     *     amount: number,
     *     unit: TimeUnit,
     *     type: 'relative'
     * }
     */
    const relativeRangeFormatter = (relativeRange, plainText = false) => {
        const absoluteTimeAmount = Math.abs(relativeRange.amount);
        if (plainText) {
            return `${relativeRange.amount} ${timeUnitFormatter(
                relativeRange.unit,
                absoluteTimeAmount,
            )}`;
        } else {
            let message;
            if (relativeRange.amount < 0) {
                //negative duration
                message =
                    absoluteTimeAmount === 1
                        ? messages.filterDatePreviousUnit
                        : messages.filterDatePreviousUnits;
            } else {
                if (
                    (relativeRange.amount === 1 && relativeRange.unit === TIME_UNITS.DAY) ||
                    relativeRange.amount === 0
                ) {
                    //special case: today
                    if (isKeyWithActiveClasses(relativeRange.key)) {
                        return capitalize(formatMessage(messages.filterDateActiveAndToday));
                    } else {
                        return capitalize(formatMessage(messages.filterDateToday));
                    }
                }
                const isSingleAmount = absoluteTimeAmount === 0 || absoluteTimeAmount === 1;
                if (isKeyWithActiveClasses(relativeRange.key)) {
                    message = isSingleAmount
                        ? messages.filterDateActiveAndNextUnit
                        : messages.filterDateActiveAndNextUnits;
                } else {
                    message = isSingleAmount
                        ? messages.filterDateNextUnit
                        : messages.filterDateNextUnits;
                }
            }

            return capitalize(
                formatMessage(message, {
                    numPeriod: absoluteTimeAmount,
                    unitOfTime: timeUnitFormatter(relativeRange.unit, absoluteTimeAmount),
                }),
            );
        }
    };

    const { lower: startDateFromDuration, upper: startDateToDuration } =
        filterConfig.boundaries.startDate;

    const dateTimeInvalidRangeText = formatMessage(messages.filterDateRangeInvalidText, {
        start: toLower(relativeRangeFormatter(startDateFromDuration, true)),
        end: toLower(relativeRangeFormatter(startDateToDuration, true)),
    });

    const enterValidDateWithinRangeText = formatMessage(messages.filterEnterValidDateWithinRange, {
        start: toLower(relativeRangeFormatter(startDateFromDuration, true)),
        end: toLower(relativeRangeFormatter(startDateToDuration, true)),
    });

    return {
        formatRelativeRange: relativeRangeFormatter,
        formatUnit: timeUnitFormatter,
        invalidRangeText: dateTimeInvalidRangeText,
        //These are used by business logic
        labelClassEndDate: formatMessage(messages.endsOnDate),
        labelCourseTitle: formatMessage(messages.courseTitle),
        labelCountry: formatMessage(messages.country),
        labelInstructor: formatMessage(messages.instructorList),
        labelCreatedBy: formatMessage(messages.createdBy),
        errorMessageEnterDateWithinRange: enterValidDateWithinRangeText,
    };
};

const getDateRangePickerConfig = (filterConfig) => {
    let relativeOptions;
    if (filterConfig.archivedMode) {
        relativeOptions = [
            {
                key: 'previous-1-day',
                amount: -1,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'previous-7-day',
                amount: -7,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'previous-14-day',
                amount: -14,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'previous-30-day',
                amount: -30,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'previous-3-month',
                amount: -3,
                unit: TIME_UNITS.MONTH,
                type: 'relative',
            },
        ];
    } else {
        relativeOptions = [
            {
                key: extendKeyWithActiveClasses('next-1-day'),
                amount: 1,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: extendKeyWithActiveClasses('next-7-day'),
                amount: 7,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: extendKeyWithActiveClasses('next-14-day'),
                amount: 14,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: extendKeyWithActiveClasses('next-30-day'),
                amount: 30,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'next-30-day',
                amount: 30,
                unit: TIME_UNITS.DAY,
                type: 'relative',
            },
            {
                key: 'next-3-month',
                amount: 3,
                unit: TIME_UNITS.MONTH,
                type: 'relative',
            },
        ];
    }

    const startDateInitialDuration = filterConfig.archivedMode
        ? filterConfig.initialOffset[COLUMN_IDS.startDate].after
        : filterConfig.initialOffset[COLUMN_IDS.startDate].before;

    let defaultRelativeOption = relativeOptions.find(
        (option) =>
            option.amount === startDateInitialDuration.amount &&
            option.unit === startDateInitialDuration.unit,
    );

    if (!defaultRelativeOption) {
        defaultRelativeOption = {
            ...startDateInitialDuration,
            type: 'relative',
        };
    }

    //Function called by date range picker to enable/disable dates on the calendar UI.
    const visualPickerDateEnablingFn = (date) => {
        const dateMoment = moment(date);
        return (
            dateMoment.isSameOrAfter(
                moment(filterConfig.referenceMoment).add(
                    filterConfig.boundaries[COLUMN_IDS.startDate].lower.amount,
                    filterConfig.boundaries[COLUMN_IDS.startDate].lower.unit,
                ),
            ) &&
            dateMoment.isBefore(
                moment(filterConfig.referenceMoment).add(
                    filterConfig.boundaries[COLUMN_IDS.startDate].upper.amount,
                    filterConfig.boundaries[COLUMN_IDS.startDate].upper.unit,
                ),
            )
        );
    };

    return {
        relativeOptions,
        defaultRelativeOption,
        dateOnly: true,
        dateEnabledCheckingFn: visualPickerDateEnablingFn,
    };
};

const INCLUDE_ACTIVE_CLASSES_DURATION_PREFIX = 'currently-active';
export const isKeyWithActiveClasses = (relativeOptionKey) => {
    if (relativeOptionKey) {
        return startsWith(relativeOptionKey, INCLUDE_ACTIVE_CLASSES_DURATION_PREFIX);
    }
    return false;
};

const extendKeyWithActiveClasses = (relationOptionKey) => {
    return `${INCLUDE_ACTIVE_CLASSES_DURATION_PREFIX}-${relationOptionKey}`;
};
