import { defineMessages } from 'react-intl.macro';

const errorMessages = defineMessages({
    genericErrorSomethingWentWrong: {
        id: 'error.genericErrorSomethingWentWrong',
        defaultMessage: 'Something went wrong',
    },
    genericErrorTryAgain: {
        id: 'error.genericErrorTryAgain',
        defaultMessage: 'Verify the URL is correct and try again in a few minutes.',
    },
});

export default errorMessages;
