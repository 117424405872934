import { COLUMN_IDS } from './classlistTableConfig';
import { messages } from './ClassTable.messages';

/**
 * Logic and configuration to enable Table property filter v3.0.
 * See propertyFilterV2Support to enable v2.1
 */
export const getHandlers = (baseHandler) => {
    return {
        ...baseHandler,

        getInitialState: () => {
            return {
                operation: 'or',
                tokens: [],
            };
        },

        /**
         * Converts filter options values to the format required by Polaris V3 Property filter filteringOptions
         * from filterValueOptions format
         * {
         *     attributeName: [
         *         {
         *             value: string,
         *             count: number
         *         }
         *     ]
         * }
         * to
         * [
         *  {
         *      propertyKey: string //attributeName,
         *      value: string
         *  }
         * ]
         *
         * @param filterValueOptions
         */
        filterOptionValuesAdapter: (filterValueOptions) => {
            const convertedFilterOptions = [];
            for (const propertyKey in filterValueOptions) {
                filterValueOptions[propertyKey].forEach(({ value }) => {
                    convertedFilterOptions.push({
                        propertyKey,
                        value,
                    });
                });
            }

            return convertedFilterOptions;
        },
    };
};

export const getPropertyFilterConfiguration = ({
    intl: { formatMessage },
    isUserTrainingCoordinator,
}) => {
    const config = [
        {
            key: COLUMN_IDS.courseTitle,
            operators: [':'],
            propertyLabel: formatMessage(messages.courseTitle),
        },
        {
            key: COLUMN_IDS.endDate,
            operators: ['=', '>=', '<'],
            propertyLabel: formatMessage(messages.endsOnDate),
        },
        {
            key: COLUMN_IDS.country,
            operators: ['='],
            propertyLabel: formatMessage(messages.country),
        },
        {
            key: COLUMN_IDS.createdBy,
            operators: ['='],
            propertyLabel: formatMessage(messages.createdBy),
        },
    ];
    if (isUserTrainingCoordinator) {
        config.push({
            key: COLUMN_IDS.instructor,
            operators: ['='],
            propertyLabel: formatMessage(messages.instructorList),
        });
    }
    return config;
};

/**
 * Note that string hasn't been localized since v3.0 is currently disabled.
 */
export const getPropertyFilterI18nStrings = ({ formatMessage }, archivedMode = false) => ({
    allPropertiesLabel: formatMessage(messages.filterAllPropertiesLabel),
    dismissAriaLabel: formatMessage(messages.filterDismissAriaLabel),
    editTokenHeader: formatMessage(messages.filterEditTokenHeader),
    cancelActionText: formatMessage(messages.filterCancelActionText),
    applyActionText: formatMessage(messages.filterApplyActionText),
    clearFiltersText: formatMessage(messages.filterClear),
    enteredTextLabel: (text) => formatMessage(messages.filterEnteredTextLabel, { text }),
    filteringAriaLabel: formatMessage(messages.filterLabel),
    filteringPlaceholder: formatMessage(
        archivedMode ? messages.filterPlaceholderForArchived : messages.filterPlaceholder,
    ),
    groupValuesText: formatMessage(messages.filterGroupValue),
    groupPropertiesText: formatMessage(messages.filterGroupProperty),
    operatorsText: formatMessage(messages.filterOperatorsText),
    operationAndText: formatMessage(messages.filterOperationAnd),
    operationOrText: formatMessage(messages.filterOperationOr),
    operatorLessText: formatMessage(messages.filterOperatorLessText),
    operatorLessOrEqualText: formatMessage(messages.filterOperatorLessOrEqualText),
    operatorGreaterText: formatMessage(messages.filterOperatorGreaterText),
    operatorGreaterOrEqualText: formatMessage(messages.filterOperatorGreaterOrEqualText),
    operatorContainsText: formatMessage(messages.filterOperatorContainsText),
    operatorDoesNotContainText: formatMessage(messages.filterOperatorDoesNotContainText),
    operatorEqualsText: formatMessage(messages.filterOperatorEqualsText),
    operatorDoesNotEqualText: formatMessage(messages.filterOperatorDoesNotEqualText),
    propertyText: formatMessage(messages.filterPropertyText),
    operatorText: formatMessage(messages.filterOperatorText),
    valueText: formatMessage(messages.filterValueText),
    tokenLimitShowMore: formatMessage(messages.filterTokenLimitShowMore),
    tokenLimitShowFewer: formatMessage(messages.filterTokenLimitShowFewer),
    removeTokenButtonAriaLabel: (token) =>
        formatMessage(messages.filterRemoveToken, {
            filterProperty: token.propertyLabel,
            filterValue: token.value,
        }),
});
