import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    RadioGroup,
} from '@amzn/awsui-components-react-v3/polaris';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ProviderType } from '@/containers/providerPages/ProvidersPage';
import { useGilmoreEvents } from '@/data/useGilmoreEvents';
import { fetchProviderAvailableLicenses } from '@/modules/api';
import { useFlags } from '@/utils/flags';

import InfoModal from '../../infoModal/InfoModal';
import { messages } from '../ClassForm.messages';
import {
    MAX_CLASS_CAPACITY,
    CLASS_ACCESS_TYPES,
    useAccessTypeLabel,
    handleFormValueChange,
    MIN_CLASS_CAPACITY,
    getDisabledCapacityInputHint,
} from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import SubProviderList from './SubProviderList';

const ClassAccess = ({
    setClassData,
    data,
    hasClassExpired,
    fieldsInvalid,
    provider,
    classData,
}) => {
    const flags = useFlags();
    const { accessType, classCapacity, capacityBySubProvider } = classData;
    const { formatMessage } = useIntl();
    const rosterAccessType = useAccessTypeLabel(CLASS_ACCESS_TYPES.roster);
    const accessCodeType = useAccessTypeLabel(CLASS_ACCESS_TYPES.accessCode);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [providerAvailableLicensesMap, setProviderAvailableLicensesMap] = useState(null);
    const [isLoadingAvailableLicenses, setIsLoadingAvailableLicenses] = useState(false);
    const { thereIsActiveEvent, mostRecent } = useGilmoreEvents();
    const disabledCapacityHint = !thereIsActiveEvent
        ? null
        : getDisabledCapacityInputHint(mostRecent, formatMessage);
    const allProviderArns = provider.requiresSubProvider
        ? provider.subProviderData.map(({ providerArn }) => providerArn)
        : [provider.arn];
    const isATPAndMultiGilmorePhase2Enabled =
        provider.type === ProviderType.ATP && flags.enableMultiGilmoreIdPhase2;

    useEffect(() => {
        if (
            isATPAndMultiGilmorePhase2Enabled &&
            !!classData.courseVersionId &&
            !!classData.langLocale
        ) {
            setIsLoadingAvailableLicenses(true);
            fetchProviderAvailableLicenses({
                providerArns: allProviderArns,
                collectionVersionArn: classData.courseVersionId,
                langLocale: classData.langLocale,
            })
                .then(setProviderAvailableLicensesMap)
                .finally(() => setIsLoadingAvailableLicenses(false));
        } else {
            setProviderAvailableLicensesMap(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classData.langLocale]);

    return (
        <Container
            id='class-access'
            header={<Header variant='h3'>{formatMessage(messages.classAccessHeader)}</Header>}
        >
            <FormField label={formatMessage(messages.accessTypeLabel)}>
                <RadioGroup
                    data-testid='class-access-radio-group'
                    value={accessType}
                    onChange={(e) => {
                        handleFormValueChange({
                            value: e.detail.value,
                            setData: setClassData,
                            keyPath: 'accessType',
                        });
                    }}
                    items={[
                        {
                            value: CLASS_ACCESS_TYPES.roster,
                            label: rosterAccessType,
                            disabled: !!(data && !!data.classroomId),
                        },
                        {
                            value: CLASS_ACCESS_TYPES.accessCode,
                            label: accessCodeType,
                            disabled:
                                !!(data && !!data.classroomId) || provider?.requiresSubProvider,
                        },
                    ]}
                    ariaRequired
                    id='ClassForm__accessType'
                />
            </FormField>
            {(!flags.multiGilmoreId || !provider || !provider.requiresSubProvider) && (
                <ColumnLayout>
                    <FormField
                        stretch
                        description={formatMessage(messages.numberOfStudentsHint, {
                            max: MAX_CLASS_CAPACITY,
                            min: MIN_CLASS_CAPACITY,
                        })}
                        constraintText={disabledCapacityHint}
                        label={
                            <div>
                                <span>{formatMessage(messages.numberOfStudentsLabel)}</span>
                                &nbsp;-&nbsp;
                                <button
                                    className='info-link'
                                    onClick={() => setOpenInfoModal(true)}
                                    aria-label={formatMessage(messages.infoButtonOpenLabel)}
                                >
                                    {formatMessage(messages.infoButtonText)}
                                </button>
                            </div>
                        }
                        errorText={showErrorMessage(formatMessage, {
                            fieldsInvalid,
                            property: 'classCapacity',
                        })}
                    >
                        <Input
                            ariaRequired
                            invalid={!!fieldsInvalid.classCapacity}
                            disabled={!!(hasClassExpired || thereIsActiveEvent)}
                            value={classCapacity}
                            type='number'
                            data-testid='class-form__class-capacity'
                            onChange={(e) => {
                                handleFormValueChange({
                                    value: Math.min(
                                        MAX_CLASS_CAPACITY,
                                        Math.max(e.detail.value, MIN_CLASS_CAPACITY),
                                    ).toString(),
                                    setData: setClassData,
                                    keyPath: 'classCapacity',
                                });
                                const accessCode =
                                    e.detail.value !== data?.classCapacity
                                        ? data?.accessCodes[0]?.accessCode
                                        : '';
                                handleFormValueChange({
                                    value: accessCode,
                                    keyPath: 'accessCode',
                                    setData: setClassData,
                                });
                            }}
                        />
                        <div>
                            <p>
                                <small>
                                    {isATPAndMultiGilmorePhase2Enabled
                                        ? formatMessage(messages.availableLicensesLabel) +
                                          ': ' +
                                          (isLoadingAvailableLicenses
                                              ? formatMessage(messages.loadingField)
                                              : providerAvailableLicensesMap
                                                ? providerAvailableLicensesMap[provider.arn]
                                                : '-')
                                        : null}
                                </small>
                            </p>
                        </div>
                    </FormField>
                </ColumnLayout>
            )}
            {flags.multiGilmoreId && provider?.requiresSubProvider && (
                <SubProviderList
                    subProviders={provider.subProviderData}
                    setClassData={setClassData}
                    classCapacity={classCapacity}
                    capacityBySubProvider={capacityBySubProvider}
                    fieldsInvalid={fieldsInvalid}
                    setOpenCapacityInfoModal={setOpenInfoModal}
                    providerAvailableLicensesMap={providerAvailableLicensesMap}
                    isLoadingAvailableLicenses={isLoadingAvailableLicenses}
                    isATPAndMultiGilmorePhase2Enabled={isATPAndMultiGilmorePhase2Enabled}
                />
            )}
            <InfoModal
                openInfoModal={openInfoModal}
                setOpenInfoModal={setOpenInfoModal}
                requiresSubProvider={provider?.requiresSubProvider}
            />
        </Container>
    );
};

export default ClassAccess;
