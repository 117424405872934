import { Box, Link } from '@amzn/awsui-components-react-v3';
import { injectIntl } from 'react-intl';

import messages from './Footer.messages';

import './Footer.scss';

export const FOOTER_SELECTOR = 'site-footer';

const insertYear = (s) => `${new Date().getFullYear()} ${s}`;

const Footer = ({ intl: { formatMessage } }) => {
    const footerText = {
        terms: {
            text: formatMessage(messages.termsLinkText),
            href: formatMessage(messages.termsLink),
        },
        privacy: {
            text: formatMessage(messages.privacyLinkText),
            href: 'https://aws.amazon.com/privacy/?src=aws_training',
        },
        support: {
            text: formatMessage(messages.supportLinkText),
            href: 'https://www.aws.training/Support',
        },
        copyright: formatMessage(messages.copyrightText),
    };

    return (
        <footer
            data-testid='site-footer'
            data-id={FOOTER_SELECTOR}
            className='footer'
            role='contentinfo'
        >
            <div className='footer__body'>
                <ul className='footer__body__links '>
                    {['terms', 'privacy', 'support'].map((link) => (
                        <li key={link}>
                            <Link external href={footerText[link].href} variant='primary'>
                                {footerText[link].text}
                            </Link>
                        </li>
                    ))}
                </ul>
                <Box variant='p'>&copy; {insertYear(footerText.copyright)}</Box>
            </div>
        </footer>
    );
};

export default injectIntl(Footer);
