import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { NavBar, ErrorFallback, Loader, Footer } from '@/components';
import ResourceConsolidationPage from '@/containers/resourceConsolidationPage/ResourceConsolidationPage';
import { checkResourceConsolidation } from '@/utils/resetResources';

import { Router } from './Router';

import './App.css';

const App = () => {
    const [consolidated, setConsolidated] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [consolidationChecked, setConsolidationChecked] = useState(false);

    useEffect(() => {
        checkResourceConsolidation().then((result) => {
            setConsolidated(result.hasResourcesConsolidated);
            setRedirecting(result.isRedirecting);
            setConsolidationChecked(true);
        });
    }, [consolidated]);

    return (
        <>
            <NavBar />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Loader isLoading={!consolidationChecked || redirecting}>
                    {consolidated ? <Router /> : <ResourceConsolidationPage />}
                </Loader>
            </ErrorBoundary>
            <Footer />
        </>
    );
};

export default App;
