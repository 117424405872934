const ROOT_URL = '/';
const PROVIDERS_PAGE = '/providers';
const CLASSES_PAGE = '/classes';
const LANDING_PAGE = CLASSES_PAGE;
const CALLBACK_PATH = '/callback';

export const paths = {
    root: ROOT_URL,
    landingPage: LANDING_PAGE,
    providersPage: PROVIDERS_PAGE,
    classListPage: CLASSES_PAGE,
    classPage: (classId) => `/class/${classId}`,
    classDetailPage: (classId) => `/class/${classId}/detail`,
    classEditPage: (classId) => `/class/${classId}/edit`,
    classAttendance: (classId) => `/class/${classId}/attendance`,
    labPage: (classId, labNumber) => `/class/${classId}/labs/${labNumber}`,
};

// A user should be returned to their target page (if defined) with a fallback to the landing page
export const redirect = (globals) => {
    const landingPage = LANDING_PAGE;
    let pathFromStorage = globals.sessionStorage.getItem('redirect_pathname');
    // NB: If path from storage is assigned to callback for some reason,
    // The URL will be set to `/callback` as an invalid route
    if (pathFromStorage === CALLBACK_PATH) {
        globals.sessionStorage.setItem('redirect_pathname', PROVIDERS_PAGE);
        pathFromStorage = PROVIDERS_PAGE;
    }
    return pathFromStorage && pathFromStorage !== ROOT_URL ? pathFromStorage : landingPage;
};
export const parameters = {
    classroomId: ':classroomId',
    labNumber: ':labNumber',
};

export const isLanguageCustomizablePage = (pathname) => {
    const [_empty, _sub1, _classId, sub2] = pathname.split('/');
    // check against an allow-list of pages
    return (
        [
            paths.root,
            paths.landingPage,
            paths.providersPage,
            paths.classListPage,
            paths.classPage('new'),
        ].includes(pathname) || ['detail', 'edit'].includes(sub2)
    );
};

export const isClassPage = (path) =>
    path !== '/class/new' && new RegExp('/class/[a-zA-Z0-9]+').test(path);
