import { defineMessages } from 'react-intl.macro';

const PREFIX = 'endLabsModal';

const messages = defineMessages({
    modalHeaderTitle: {
        id: `${PREFIX}.header.title`,
        defaultMessage: 'Confirm end lab',
    },
    modalBodyHeading: {
        id: `${PREFIX}.body.heading.notall`,
        defaultMessage: 'Are you sure you want to end this lab for the selected {num} student(s)?',
    },
    modalBodyHeadingAll: {
        id: `${PREFIX}.body.heading`,
        defaultMessage: 'Are you sure you want to end this lab for all students?',
    },
    modalBodyMainContent: {
        id: `${PREFIX}.body.mainContent`,
        defaultMessage:
            "This will end a student's progress and reset the associated AWS resources. Students can access the lab as long as it is marked available until {endDate}",
    },
    modalFooterEndLabLabel: {
        id: `${PREFIX}.footer.endLabButton.label`,
        defaultMessage: 'End lab',
    },
    modalFooterEndLabPluralLabel: {
        id: `${PREFIX}.footer.endLabPluralButton.label`,
        defaultMessage: 'End labs',
    },
});

export default messages;
