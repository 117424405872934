import { Button, Modal, Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';

import { messages } from '@/components/classForm/ClassForm.messages';

import './CapacityCheckModal.css';

export const NEED_TO_CLEAR = 'needToClear';
export const CLEARED = 'cleared';
export const CANCELLED = 'cancelled';
export const NO_NEED = 'noNeed';
export const LICENSE_ERROR = 'licenseError';

const getMessages = (status, additionalNum, isSubProvider) => {
    switch (status) {
        case LICENSE_ERROR:
            return {
                headerText: messages.classCapacityAndLicenseQuantityMismatchErrorHeader,
                mainContent: isSubProvider
                    ? messages.classCapacityAndLicenseQuantityMismatchErrorContentWithSubProvider
                    : messages.classCapacityAndLicenseQuantityMismatchErrorContent,
                primaryBtnLabel: messages.doneButtonText,
            };
        default: {
            if (additionalNum > 0) {
                return {
                    headerText: messages.rosterResizeIncreaseHeader,
                    mainContent: messages.rosterResizeIncreaseContent,
                    primaryBtnLabel: messages.doneButtonText,
                };
            } else {
                // additionalNum can either be > 0 or < 0.
                return {
                    headerText: messages.rosterResizeDecreaseHeader,
                    mainContent: messages.rosterResizeDecreaseContent,
                    primaryBtnLabel: messages.doneButtonText,
                };
            }
        }
    }
};

const CapacityCheckModal = ({
    statusSet,
    status,
    originalSize,
    newSize,
    availableQuantity,
    isSubProvider = false,
}) => {
    const { formatMessage } = useIntl();
    const visibleStatuses = [NEED_TO_CLEAR, LICENSE_ERROR];
    if (!visibleStatuses.includes(status)) {
        return null;
    }

    const additionalNum = newSize - originalSize;
    const { headerText, mainContent, primaryBtnLabel } = getMessages(
        status,
        additionalNum,
        isSubProvider,
    );
    return (
        <Modal
            onDismiss={() => statusSet(CANCELLED)}
            visible
            header={headerText && formatMessage(headerText)}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            onClick={() => statusSet(CANCELLED)}
                            variant='link'
                            data-testid='capacity-modal__cancel-btn'
                        >
                            {formatMessage(messages.cancelButtonText)}
                        </Button>
                        <Button
                            onClick={() => {
                                statusSet(status === NEED_TO_CLEAR ? CLEARED : CANCELLED);
                            }}
                            variant='primary'
                        >
                            {formatMessage(primaryBtnLabel)}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {formatMessage(mainContent, {
                additionalNum: Math.abs(additionalNum), // Using abs. val so that it makes sense in both capacity increase and decrease messages
                availableQuantity: availableQuantity,
            })}
        </Modal>
    );
};

export default CapacityCheckModal;
