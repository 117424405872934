import _ from 'lodash';
import moment from 'moment';

import { COLUMN_IDS, TIME_UNITS } from '../classesTableV2/classlistTableConfig';
import { EMAIL_REGEX } from '../classForm/ClassForm.utils';
import countries from '../classForm/countries.messages';

import { messages } from './ClassTable.messages';

/**
 * Checks whether targetMoment is within range.
 *
 * @param targetMoment the moment to be validated
 * @param referenceMoment the moment to which the range is applied to derive the actual lower and upper absolute date boundaries.
 * @param range duration consisting of lower and upper boundary expressed as duration in terms of amount and unit
 * @param inclusivity whether comparison for boundaries are inclusive or exclusive. Values can be "()" | "[)" | "(]" | "[]"
 * @returns {boolean} return whether targetMoment is within range of referenceMoment modified by range.
 */
export const isDateWithinRange = (targetMoment, referenceMoment, range, inclusivity = '[]') => {
    const afterMoment = moment(referenceMoment).add(range.lower.amount, range.lower.unit);
    const beforeMoment = moment(referenceMoment).add(range.upper.amount, range.upper.unit);
    return targetMoment.isBetween(afterMoment, beforeMoment, undefined, inclusivity);
};

/**
 * If the date of aMoment is today, return now, otherwise return the original aMoment
 */
export const adjustTimeIfToday = (aMoment) => {
    const now = moment();
    if (aMoment.isSame(now, TIME_UNITS.DAY)) {
        return now;
    } else {
        return aMoment;
    }
};

/**
 * Function for handling a special case with endDate filtering value on archived mode.
 * Filter value for endDate consists of a range, [before, after].  'Before' is set to start of day and 'After' is set
 * to end of day by default. For example, if 5/20/2022 is entered for endDate filtering, before=5/20/2022 00:00:00 and
 * after=5/20/2022 23:59:59. However, when endDate is today and table is archived mode, 'after' must be set to 'now'
 * rather than end of the day since end of today happens in the future but archive mode must limit dates to past.
 * This function extends the boundary to account this special case.
 * @param endDateUpperBoundary is the duration with amount and unit applied to a reference moment to derive the absolute moment used as upper limit.
 * @param isArchiveMode indicating whether class listing is for archived only
 * @returns new endDate upper boundary extended by the amount of seconds plus one passed since start of day.
 */
export const adjustEndDateUpperBoundary = (endDateUpperBoundary, isArchiveMode) => {
    const now = moment();
    if (isArchiveMode && endDateUpperBoundary.amount === 0) {
        return {
            amount: now.diff(moment(now).startOf(TIME_UNITS.DAY), TIME_UNITS.SECOND) + 1,
            unit: TIME_UNITS.SECOND,
        };
    } else {
        return endDateUpperBoundary;
    }
};

/**
 * Free text token is a filter value typed in by the user as opposed to a value selected from filter option dropdown.
 * Unbound token is a filter value that is not specific (bound) to a field.
 * For example 'course title: aws' is bound while 'aws' is unbound.  Unbound tokens have undefined propertyKey field.
 *
 * Here is an unbound free text token
 * {
 *     isFreeText: true,
 *     label:
 *     propertyKey: null,
 *     propertyLabel:
 *     value:
 * }
 */
export const isUnboundFreeTextToken = (token) => {
    return token && token.isFreeText && !token.propertyKey;
};

/**
 * This method runs some basic checks to determine whether unbound free text tokens can be handled by
 * trying one of the field handlers. Returns the first handler that is successful in generating a bound token or null otherwise.
 * @param token unbound free text token
 * @param stringBundle i18n strings
 */
export const convertUnboundFreeTextToken = (token, stringBundle) => {
    const handlers = [
        instructorFullEmailAddressOnlyHandler,
        endDateHandler,
        countryHandler,
        courseTitleHandler,
    ];
    for (const handler of handlers) {
        const { token: boundToken } = handler.getAttributeBoundToken(token, stringBundle);
        if (boundToken) {
            return boundToken;
        }
    }
    return null;
};

/**
 * This method handles free text tokens bound to a specific field.
 * @param token - bound free text token
 * @param stringBundle i18n strings
 */
export const convertBoundFreeTextToken = (token, stringBundle) => {
    const handlers = {
        [COLUMN_IDS.country]: countryHandler,
        [COLUMN_IDS.courseTitle]: courseTitleHandler,
        [COLUMN_IDS.createdBy]: createdByFullEmailAddressOnlyHandler,
        [COLUMN_IDS.endDate]: endDateHandler,
        [COLUMN_IDS.instructor]: instructorFullEmailAddressOnlyHandler,
    };

    const handler = handlers[token.propertyKey];
    return handler.getAttributeBoundToken(token, stringBundle);
};

const instructorFullEmailAddressOnlyHandler = {
    getAttributeBoundToken: (token, stringBundle) => {
        if (token.value.match(EMAIL_REGEX)) {
            return wrapValidResult({
                propertyKey: COLUMN_IDS.instructor,
                propertyLabel: stringBundle.labelInstructor,
                value: token.value,
            });
        }
        return wrapInvalidResult({
            message: messages.filterInvalidEmailInputText,
            params: { field: _.toLower(stringBundle.labelInstructor) },
        });
    },
};

const createdByFullEmailAddressOnlyHandler = {
    getAttributeBoundToken: (token, stringBundle) => {
        if (token.value.match(EMAIL_REGEX)) {
            return wrapValidResult({
                propertyKey: COLUMN_IDS.createdBy,
                propertyLabel: stringBundle.labelCreatedBy,
                value: token.value,
            });
        }
        return wrapInvalidResult({
            message: messages.filterInvalidEmailInputText,
            params: { field: _.toLower(stringBundle.labelCreatedBy) },
        });
    },
};

//Using limited set of characters for validation.
const invalidCharacters = /[`()"<>?~]/;
const stringBasedTokenHandler = (token, propertyKey, fieldLabel) => {
    if (invalidCharacters.test(token.value)) {
        return wrapInvalidResult({
            message: messages.filterInvalidInputDetailedText,
            params: {
                field: _.toLower(fieldLabel),
                restrictedChars: '`!()"<>?~',
            },
        });
    } else {
        return wrapValidResult({
            propertyKey: propertyKey,
            propertyLabel: fieldLabel,
            value: token.value,
        });
    }
};

const courseTitleHandler = {
    getAttributeBoundToken: (token, stringBundle) => {
        return stringBasedTokenHandler(
            token,
            COLUMN_IDS.courseTitle,
            stringBundle.labelCourseTitle,
        );
    },
};

const countryHandler = {
    getAttributeBoundToken: (token, stringBundle) => {
        if (countries[_.toUpper(token.value)]) {
            return wrapValidResult({
                propertyKey: COLUMN_IDS.country,
                propertyLabel: stringBundle.labelCountry,
                value: token.value,
            });
        } else {
            return wrapInvalidResult({
                message: messages.filterInvalidCountryText,
            });
        }
    },
};

const dataRangeRegExp = new RegExp(
    /^(\d{1,4}[/-]\d{1,4}[/-]\d{1,4})\s*[-~]\s*(\d{1,4}[/-]\d{1,4}[/-]\d{1,4})$/,
);
const endDateHandler = {
    getAttributeBoundToken: (token, stringBundle) => {
        const tokenValue = _.trim(token.value);
        const possibleMoment = moment(tokenValue);
        if (possibleMoment.isValid()) {
            return wrapValidResult({
                propertyKey: COLUMN_IDS.endDate,
                propertyLabel: stringBundle.labelClassEndDate,
                value: possibleMoment.format('YYYY-MM-DD'),
            });
        } else if (dataRangeRegExp.test(tokenValue)) {
            //check whether it is a range roughly matching a patter of dd/dd/dd - dd/dd/dd
            const matches = dataRangeRegExp.exec(tokenValue);
            const fromMoment = moment(matches[1]).startOf(TIME_UNITS.DAY);
            const toMoment = moment(matches[2]).endOf(TIME_UNITS.DAY);

            if (fromMoment.isValid() && toMoment.isValid()) {
                return wrapValidResult({
                    propertyKey: COLUMN_IDS.endDate,
                    value: `${fromMoment.format('YYYY-MM-DD')} - ${toMoment.format('YYYY-MM-DD')}`,
                    propertyLabel: stringBundle.labelClassEndDate,
                    range: {
                        after: fromMoment,
                        before: toMoment,
                    },
                });
            }
        }
        return wrapInvalidResult({
            message: messages.filterInvalidDate,
            params: { dateFormat: 'YYYY-MM-DD' },
        });
    },
};

const wrapValidResult = (token) => {
    return {
        token,
    };
};

const wrapInvalidResult = (invalidInputMessage) => {
    return {
        invalidInputMessage,
    };
};
