import { DateRangePicker, PropertyFilter } from '@amzn/awsui-components-react-v3';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '@/components/classesTableV2/ClassTable.messages';
import '@/styles/table-date-filter.scss';
import { useUserInfo } from '@/utils';

import {
    getFilterComponentConfig,
    getFilterHandlers,
    isKeyWithActiveClasses,
} from './classListFilterConfig';
import { classListingFilterEventBuilder } from './classlistStateManager';
import { COLUMN_IDS, TIME_UNITS } from './classlistTableConfig';
import {
    adjustEndDateUpperBoundary,
    adjustTimeIfToday,
    convertUnboundFreeTextToken,
    convertBoundFreeTextToken,
    isDateWithinRange,
    isUnboundFreeTextToken,
} from './classroomListFilterUtils';

/**
 * Filter component to support filtering classrooms by different attributes such as course title and start date.
 * Under the hood uses property filter and date range picker and dispatches filter change events to ClassListingTableV2.
 *
 * @param filterConfig - filter configuration such as initial startDate filtering range. It is defined in classlistTableConfig.getFilterConfig
 * @param filterOptionValues - filter option values used for auto suggestion
 * @param classListingEventDispatcher - dispatcher used to send filter change events
 * @returns {JSX.Element}
 * @constructor
 */
const ClassroomListFilter = ({ filterConfig, filterOptionValues, classListingEventDispatcher }) => {
    const user = useUserInfo();
    const intl = useIntl();

    const uiComponentAdapter = useMemo(() => getFilterHandlers(basePropertyFilterHandler), []);

    const [propertyFilterState, setPropertyFilterState] = useState(
        uiComponentAdapter.getInitialState(),
    );

    const uiConfig = useMemo(() => {
        return getFilterComponentConfig({
            intl,
            isUserTrainingCoordinator: user.userIsTrainingCoordinator,
            filterConfig,
        });
    }, [intl, filterConfig, user.userIsTrainingCoordinator]);

    const [dataRangePickerValue, setDataRangePickerValue] = useState(
        uiConfig.dateRangePicker.defaultRelativeOption,
    );

    const onPropertyChange = useMemo(
        () => (event) => {
            const { activeFilters, invalidInputMessage } =
                uiComponentAdapter.selectionChangeEventHandler(event, filterConfig, uiConfig);

            setPropertyFilterState(event.detail);
            if (invalidInputMessage) {
                const notificationMessage =
                    invalidInputMessage.localizedMessage ??
                    intl.formatMessage(invalidInputMessage.message, invalidInputMessage.params);
                classListingEventDispatcher.notifyWarning(notificationMessage);
            } else {
                classListingEventDispatcher.listingFilterChange(activeFilters);
            }
        },
        [
            setPropertyFilterState,
            classListingEventDispatcher,
            uiComponentAdapter,
            intl,
            filterConfig,
            uiConfig,
        ],
    );

    const dateRangePickerChangeHandler = useMemo(
        () => (event) => {
            const { dateRangePickerNewValue, startDateFilterUpdatedEvent } =
                uiComponentAdapter.dateRangeChangeHandler(
                    event.detail.value,
                    uiConfig,
                    filterConfig,
                );
            if (startDateFilterUpdatedEvent) {
                classListingEventDispatcher.startDateFilterChange(startDateFilterUpdatedEvent);
            }
            setDataRangePickerValue(dateRangePickerNewValue);
        },
        [
            setDataRangePickerValue,
            filterConfig,
            uiComponentAdapter,
            classListingEventDispatcher,
            uiConfig,
        ],
    );

    const filterOptionValuesForPropFilter = useMemo(() => {
        return uiComponentAdapter.filterOptionValuesAdapter(filterOptionValues);
    }, [filterOptionValues, uiComponentAdapter]);

    return (
        <div className='filter-container'>
            <div className='input-filter'>
                <PropertyFilter
                    data-testid='property-filter'
                    i18nStrings={uiConfig.propertyFilterI18nStrings}
                    hideOperations
                    disableFreeTextFiltering={false}
                    query={propertyFilterState}
                    filteringFunction={null}
                    filteringOptions={filterOptionValuesForPropFilter}
                    onChange={onPropertyChange}
                    filteringProperties={uiConfig.filterFields}
                />
            </div>
            <DateRangePicker
                data-testid='date-filter'
                onChange={(event) => dateRangePickerChangeHandler(event)}
                isValidRange={(newValue) =>
                    uiComponentAdapter.classStartDateRangeValidator(
                        newValue,
                        filterConfig,
                        uiConfig,
                    )
                }
                value={dataRangePickerValue}
                relativeOptions={uiConfig.dateRangePicker.relativeOptions}
                i18nStrings={uiConfig.dateRangePickerI18nStrings}
                placeholder={uiConfig.dateRangePickerI18nStrings.placeholderText}
                rangeSelectorMode='default'
                timeInputFormat='hh:mm'
                isDateEnabled={uiConfig.dateRangePicker.dateEnabledCheckingFn}
                showClearButton={true}
                dateOnly={uiConfig.dateRangePicker.dateOnly}
            />
        </div>
    );
};

const basePropertyFilterHandler = {
    /**
     * Tokens is an array consisting of filter values called 'token'.
     * Token from Property filter v2, aka table property filtering
     * {
     *    label: string, // 'Architecting in AWS'
     *    negated: boolean,
     *    propertyKey: string, //courseTitle
     *    propertyLabel: string, //Course title
     *    value: string //'Architecting in AWS'
     * }
     *
     * Token from Property filter v3
     * {
     *    propertyKey: string,
     *    operator: 'string', // =, <=, >=, etc
     *    value: string
     * }
     * @param tokens
     * @returns {{}}
     */
    selectionToActiveFilterAdapter: (tokens, filterConfig, uiConfig) => {
        const convertedValues = {};
        const updatedTokens = [];
        let invalidInputMessage = null;
        const stringBundle = uiConfig.dateRangePickerI18nStrings;
        for (const filterToken of tokens) {
            let attributeBoundToken = filterToken;
            if (isUnboundFreeTextToken(filterToken)) {
                attributeBoundToken = convertUnboundFreeTextToken(filterToken, stringBundle);
                if (!attributeBoundToken) {
                    invalidInputMessage = {
                        message: messages.filterInvalidFreeTextFilter,
                    };
                    continue;
                }
            }

            if (attributeBoundToken.isFreeText) {
                const { token: processedToken, invalidInputMessage: processedInvalidMessage } =
                    convertBoundFreeTextToken(attributeBoundToken, stringBundle);
                if (processedInvalidMessage) {
                    invalidInputMessage = processedInvalidMessage;
                    continue;
                } else {
                    attributeBoundToken = processedToken;
                }
            }

            let attributeValues = convertedValues[attributeBoundToken.propertyKey];
            if (attributeBoundToken.propertyKey === COLUMN_IDS.endDate) {
                const newEndDateDateRange = {};
                let newToken = attributeBoundToken;
                /**
                 * Note on adjustTimeIfToday based on archivedMode:
                 * EndDate filter by default is from the start of the day (00:00:00) to the end of day (23:59:59). However, if the endDate happens to be today,
                 * exception must be made based on whether it is archivedMode or not.
                 * When in archiveMode, endDate.before must be now rather than end of day. For example, if now is 2022/05/22 10:00:00, and endDate is set to
                 * '2022/05/22', endDate filter must be applied from 2022/05/22 00:00:00 to 2022/05/22 10:00:00 since in archivedMode we limit classes with endDate
                 * in the past. Similar logic applies to endDate.after when archivedMode=false to ensure endDate is in the future.
                 */
                if (attributeBoundToken.range) {
                    // This means token was processed and converted from free text token by convertUnboundFreeTextToken
                    newEndDateDateRange.after = filterConfig.archivedMode
                        ? attributeBoundToken.range.after
                        : adjustTimeIfToday(attributeBoundToken.range.after);
                    newEndDateDateRange.before = filterConfig.archivedMode
                        ? adjustTimeIfToday(attributeBoundToken.range.before)
                        : attributeBoundToken.range.before;
                    newToken = {
                        propertyKey: attributeBoundToken.propertyKey,
                        value: attributeBoundToken.value,
                        propertyLabel: attributeBoundToken.propertyLabel,
                    };
                } else {
                    const aMoment = moment(attributeBoundToken.value);
                    newEndDateDateRange.after = filterConfig.archivedMode
                        ? moment(aMoment).startOf(TIME_UNITS.DAY)
                        : adjustTimeIfToday(moment(aMoment).startOf(TIME_UNITS.DAY));
                    newEndDateDateRange.before = filterConfig.archivedMode
                        ? adjustTimeIfToday(moment(aMoment).endOf(TIME_UNITS.DAY))
                        : moment(aMoment).endOf(TIME_UNITS.DAY);
                }

                if (
                    isDateWithinRange(
                        newEndDateDateRange.after,
                        filterConfig.referenceMoment,
                        filterConfig.boundaries[COLUMN_IDS.endDate],
                    ) &&
                    isDateWithinRange(newEndDateDateRange.before, filterConfig.referenceMoment, {
                        lower: filterConfig.boundaries[COLUMN_IDS.endDate].lower,
                        upper: adjustEndDateUpperBoundary(
                            filterConfig.boundaries[COLUMN_IDS.endDate].upper,
                            filterConfig.archivedMode,
                        ),
                    })
                ) {
                    if (!attributeValues) {
                        attributeValues = {};
                        convertedValues[attributeBoundToken.propertyKey] = attributeValues;
                    }
                    attributeValues.after = newEndDateDateRange.after;
                    attributeValues.before = newEndDateDateRange.before;
                    updatedTokens.push(newToken);
                } else {
                    //new end date is over the boundaries
                    invalidInputMessage = {
                        localizedMessage: stringBundle.errorMessageEnterDateWithinRange,
                    };
                    continue;
                }
            } else {
                if (!attributeValues) {
                    attributeValues = [];
                    convertedValues[attributeBoundToken.propertyKey] = attributeValues;
                }
                //activeFilters.values[x].operator is ignored since "or" is the only supported operator
                attributeValues.push(attributeBoundToken.value);
                updatedTokens.push(attributeBoundToken);
            }
        }
        return {
            activeFilters: convertedValues,
            tokens: updatedTokens,
            invalidInputMessage,
        };
    },

    /**
     *  changeDetail when selection mode is relative:
     *  {
     *      key: "previous-1-week",
     *      amount: 1,
     *      unit: TIME_UNITS.WEEK,
     *      type: "relative"
     *   }
     *
     *  when selection mode is absolute
     *   {
     *      startDate: "YYYY-MM-DDThh:mm:ssZ",
     *      endDate: "2022-05-26T23:59:59-07:00",
     *      type: "absolute"
     *   }
     * @param changeDetail
     */
    dateRangeChangeHandler: (changeDetail, uiComponentConfig, filterConfig) => {
        let startDateFilterUpdatedEvent = null;
        let dateRangePickerNewValue = changeDetail;
        if (!changeDetail || !changeDetail.type) {
            //this can happen if "Clear and dismiss" is triggered
            dateRangePickerNewValue = uiComponentConfig.dateRangePicker.defaultRelativeOption;
            startDateFilterUpdatedEvent = classListingFilterEventBuilder.newRelativeStartDateEvent({
                amount: dateRangePickerNewValue.amount,
                unit: dateRangePickerNewValue.unit,
            });
        } else if (changeDetail.type === 'relative') {
            const relativeDurationResult = handleDateRangeChangeToRelativeDuration(
                changeDetail,
                uiComponentConfig,
                filterConfig,
            );
            startDateFilterUpdatedEvent = relativeDurationResult.event;
            dateRangePickerNewValue = relativeDurationResult.valueForUI;
        } else {
            startDateFilterUpdatedEvent = classListingFilterEventBuilder.newAbsoluteStartDateEvent({
                afterMoment: moment(changeDetail.startDate),
                beforeMoment: moment(changeDetail.endDate),
            });
            if (uiComponentConfig.dateRangePicker.dateOnly) {
                //if dateOnly mode is enabled, extend the 'before' time to 23:59:59
                startDateFilterUpdatedEvent.before.endOf(TIME_UNITS.DAY);
            }
        }

        return {
            startDateFilterUpdatedEvent,
            dateRangePickerNewValue,
        };
    },

    /**
     * converts tokens (e.g. selected filter values from PropertyFilter V3 component) to filter values
     * accepted by ClassListingTableV2.
     * event is:
     * {
     *    type: 'awsui:propertyFilteringChange',
     *    ...
     *    detail: {
     *       operation: 'and',
     *       tokens: [
     *           {
     *               label: string, // 'Architecting in AWS'
     *               negated: boolean,
     *               propertyKey: string, //courseTitle
     *               propertyLabel: string, //Course title
     *               value: string //'Architecting in AWS'
     *           }
     *       ]
     *     }
     *     ...
     * }
     *
     * for V3, event consists of:
     * {
     *     cancelBubble: false,
     *     cancellable: false,
     *     detail: {
     *         operation: 'or|and',
     *         tokens: [
     *             {
     *                 propertyKey: string,
     *                 operator: 'string', //=, <=, >=, etc
     *                 value: string
     *             }
     *         ]
     *     }
     * }
     * @param event
     */
    selectionChangeEventHandler: (event, filterConfig, uiConfig) => {
        const tokens = event.detail.tokens;
        return basePropertyFilterHandler.selectionToActiveFilterAdapter(
            tokens,
            filterConfig,
            uiConfig,
        );
    },

    /**
     * validates selected date is within permitted range.
     * @param changeDetail - {@link DateRangePickerProps.Value}
     * @param filterConfig - contains filter configuration including date boundaries.
     */
    classStartDateRangeValidator: (dateValue, filterConfig, uiConfig) => {
        let isValid = true;
        if (dateValue && dateValue.type === 'absolute') {
            const fromMoment = moment(dateValue.startDate);
            const toMoment = moment(dateValue.endDate);

            if (
                !isDateWithinRange(
                    fromMoment,
                    filterConfig.referenceMoment,
                    filterConfig.boundaries[COLUMN_IDS.startDate],
                ) ||
                !isDateWithinRange(
                    toMoment,
                    filterConfig.referenceMoment,
                    filterConfig.boundaries[COLUMN_IDS.startDate],
                )
            ) {
                isValid = false;
            }
        } else if (dateValue && dateValue.type === 'relative') {
            const actualMoment = moment(filterConfig.referenceMoment).add(
                dateValue.amount,
                dateValue.unit,
            );
            isValid = isDateWithinRange(
                actualMoment,
                filterConfig.referenceMoment,
                filterConfig.boundaries[COLUMN_IDS.startDate],
            );
        }

        if (isValid) {
            return {
                valid: true,
            };
        } else {
            return {
                valid: false,
                errorMessage: uiConfig.dateRangePickerI18nStrings.invalidRangeText,
            };
        }
    },
};

const handleDateRangeChangeToRelativeDuration = (changeDetail, uiComponentConfig, filterConfig) => {
    let startDateFilterUpdatedEvent = null;
    let dateRangePickerNewValue = changeDetail;

    let actualAmount = changeDetail.amount;
    if (filterConfig.archivedMode && actualAmount > 0) {
        //If custom duration is entered in archiveMode, it has to be converted into negative amount
        actualAmount = 0 - actualAmount;

        //update the value of the date range picker
        dateRangePickerNewValue = {
            ...dateRangePickerNewValue,
            amount: actualAmount,
        };
    }

    if (isKeyWithActiveClasses(changeDetail.key)) {
        const lowerStartDateBoundary = filterConfig.boundaries[COLUMN_IDS.startDate].lower;
        startDateFilterUpdatedEvent = classListingFilterEventBuilder.newAbsoluteStartDateEvent({
            afterMoment: moment(filterConfig.referenceMoment).add(
                lowerStartDateBoundary.amount,
                lowerStartDateBoundary.unit,
            ),
            beforeMoment: moment(filterConfig.referenceMoment).add(actualAmount, changeDetail.unit),
        });
    } else {
        startDateFilterUpdatedEvent = classListingFilterEventBuilder.newRelativeStartDateEvent({
            amount: actualAmount,
            unit: changeDetail.unit,
        });
    }

    return {
        event: startDateFilterUpdatedEvent,
        valueForUI: dateRangePickerNewValue,
    };
};

/**
 * exporting for testing
 */
export const basePropertyFilterHandlerForTest = {
    basePropertyFilterHandler,
};

export default ClassroomListFilter;
