import moment from 'moment';
import { createContext, useState } from 'react';

const GilmoreHealthContext = createContext({
    gilmoreEventData: [],
    mostRecent: null,
    thereIsActiveEvent: false,
});

const GILMORE_DOMAIN = 'GILMORE';

const getMostRecent = (events) => {
    if (!events?.length) {
        return null;
    }
    return events
        .filter((ev) => ev.service === GILMORE_DOMAIN)
        .sort((a, b) => b.startTime - a.startTime)
        .pop();
};

const isThereActiveEvent = (events) => {
    if (!events?.length) {
        return false;
    }
    const now = moment().unix();
    return events.some(
        (ev) => ev.service === GILMORE_DOMAIN && ev.startTime <= now && now <= ev.endTime,
    );
};

/**
 * Context provider to hold data related to Gilmore outage events, this should be used close to the
 * root of the app to reduce redundancy
 * @param {object[]} value array of events.
 */
const GilmoreHealthProvider = ({ children, value = [] }) => {
    const [data, dataSet] = useState({
        gilmoreEventData: value,
        mostRecent: getMostRecent(value),
        thereIsActiveEvent: isThereActiveEvent(value),
    });
    const gilmoreEventDataSet = (events) => {
        dataSet({
            gilmoreEventData: events,
            mostRecent: getMostRecent(events),
            thereIsActiveEvent: isThereActiveEvent(events),
        });
    };
    const initialValue = {
        ...data,
        gilmoreEventDataSet,
    };
    return (
        <GilmoreHealthContext.Provider value={initialValue}>
            {children}
        </GilmoreHealthContext.Provider>
    );
};

export { GilmoreHealthProvider, GilmoreHealthContext };
