import { defineMessages } from 'react-intl.macro';

export default defineMessages({
    AFG: {
        id: 'classForm.countries.AFG',
        defaultMessage: 'Afghanistan',
    },
    ALB: {
        id: 'classForm.countries.ALB',
        defaultMessage: 'Albania',
    },
    DZA: {
        id: 'classForm.countries.DZA',
        defaultMessage: 'Algeria',
    },
    ASM: {
        id: 'classForm.countries.ASM',
        defaultMessage: 'American Samoa',
    },
    AND: {
        id: 'classForm.countries.AND',
        defaultMessage: 'Andorra',
    },
    AGO: {
        id: 'classForm.countries.AGO',
        defaultMessage: 'Angola',
    },
    AIA: {
        id: 'classForm.countries.AIA',
        defaultMessage: 'Anguilla',
    },
    ATA: {
        id: 'classForm.countries.ATA',
        defaultMessage: 'Antarctica',
    },
    ATG: {
        id: 'classForm.countries.ATG',
        defaultMessage: 'Antigua and Barbuda',
    },
    ARG: {
        id: 'classForm.countries.ARG',
        defaultMessage: 'Argentina',
    },
    ARM: {
        id: 'classForm.countries.ARM',
        defaultMessage: 'Armenia',
    },
    ABW: {
        id: 'classForm.countries.ABW',
        defaultMessage: 'Aruba',
    },
    AUS: {
        id: 'classForm.countries.AUS',
        defaultMessage: 'Australia',
    },
    AUT: {
        id: 'classForm.countries.AUT',
        defaultMessage: 'Austria',
    },
    AZE: {
        id: 'classForm.countries.AZE',
        defaultMessage: 'Azerbaijan',
    },
    BHS: {
        id: 'classForm.countries.BHS',
        defaultMessage: 'Bahamas (the)',
    },
    BHR: {
        id: 'classForm.countries.BHR',
        defaultMessage: 'Bahrain',
    },
    BGD: {
        id: 'classForm.countries.BGD',
        defaultMessage: 'Bangladesh',
    },
    BRB: {
        id: 'classForm.countries.BRB',
        defaultMessage: 'Barbados',
    },
    BLR: {
        id: 'classForm.countries.BLR',
        defaultMessage: 'Belarus',
    },
    BEL: {
        id: 'classForm.countries.BEL',
        defaultMessage: 'Belgium',
    },
    BLZ: {
        id: 'classForm.countries.BLZ',
        defaultMessage: 'Belize',
    },
    BEN: {
        id: 'classForm.countries.BEN',
        defaultMessage: 'Benin',
    },
    BMU: {
        id: 'classForm.countries.BMU',
        defaultMessage: 'Bermuda',
    },
    BTN: {
        id: 'classForm.countries.BTN',
        defaultMessage: 'Bhutan',
    },
    BOL: {
        id: 'classForm.countries.BOL',
        defaultMessage: 'Bolivia (Plurinational State of)',
    },
    BES: {
        id: 'classForm.countries.BES',
        defaultMessage: 'Bonaire, Sint Eustatius and Saba',
    },
    BIH: {
        id: 'classForm.countries.BIH',
        defaultMessage: 'Bosnia and Herzegovina',
    },
    BWA: {
        id: 'classForm.countries.BWA',
        defaultMessage: 'Botswana',
    },
    BVT: {
        id: 'classForm.countries.BVT',
        defaultMessage: 'Bouvet Island',
    },
    BRA: {
        id: 'classForm.countries.BRA',
        defaultMessage: 'Brazil',
    },
    IOT: {
        id: 'classForm.countries.IOT',
        defaultMessage: 'British Indian Ocean Territory (the)',
    },
    BRN: {
        id: 'classForm.countries.BRN',
        defaultMessage: 'Brunei Darussalam',
    },
    BGR: {
        id: 'classForm.countries.BGR',
        defaultMessage: 'Bulgaria',
    },
    BFA: {
        id: 'classForm.countries.BFA',
        defaultMessage: 'Burkina Faso',
    },
    BDI: {
        id: 'classForm.countries.BDI',
        defaultMessage: 'Burundi',
    },
    CPV: {
        id: 'classForm.countries.CPV',
        defaultMessage: 'Cabo Verde',
    },
    KHM: {
        id: 'classForm.countries.KHM',
        defaultMessage: 'Cambodia',
    },
    CMR: {
        id: 'classForm.countries.CMR',
        defaultMessage: 'Cameroon',
    },
    CAN: {
        id: 'classForm.countries.CAN',
        defaultMessage: 'Canada',
    },
    CYM: {
        id: 'classForm.countries.CYM',
        defaultMessage: 'Cayman Islands (the)',
    },
    CAF: {
        id: 'classForm.countries.CAF',
        defaultMessage: 'Central African Republic (the)',
    },
    TCD: {
        id: 'classForm.countries.TCD',
        defaultMessage: 'Chad',
    },
    CHL: {
        id: 'classForm.countries.CHL',
        defaultMessage: 'Chile',
    },
    CHN: {
        id: 'classForm.countries.CHN',
        defaultMessage: 'China',
    },
    CXR: {
        id: 'classForm.countries.CXR',
        defaultMessage: 'Christmas Island',
    },
    CCK: {
        id: 'classForm.countries.CCK',
        defaultMessage: 'Cocos (Keeling) Islands (the)',
    },
    COL: {
        id: 'classForm.countries.COL',
        defaultMessage: 'Colombia',
    },
    COM: {
        id: 'classForm.countries.COM',
        defaultMessage: 'Comoros (the)',
    },
    COD: {
        id: 'classForm.countries.COD',
        defaultMessage: 'Congo (the Democratic Republic of the)',
    },
    COG: {
        id: 'classForm.countries.COG',
        defaultMessage: 'Congo (the)',
    },
    COK: {
        id: 'classForm.countries.COK',
        defaultMessage: 'Cook Islands (the)',
    },
    CRI: {
        id: 'classForm.countries.CRI',
        defaultMessage: 'Costa Rica',
    },
    HRV: {
        id: 'classForm.countries.HRV',
        defaultMessage: 'Croatia',
    },
    CUB: {
        id: 'classForm.countries.CUB',
        defaultMessage: 'Cuba',
    },
    CUW: {
        id: 'classForm.countries.CUW',
        defaultMessage: 'Curaçao',
    },
    CYP: {
        id: 'classForm.countries.CYP',
        defaultMessage: 'Cyprus',
    },
    CZE: {
        id: 'classForm.countries.CZE',
        defaultMessage: 'Czechia',
    },
    CIV: {
        id: 'classForm.countries.CIV',
        defaultMessage: "Côte d'Ivoire",
    },
    DNK: {
        id: 'classForm.countries.DNK',
        defaultMessage: 'Denmark',
    },
    DJI: {
        id: 'classForm.countries.DJI',
        defaultMessage: 'Djibouti',
    },
    DMA: {
        id: 'classForm.countries.DMA',
        defaultMessage: 'Dominica',
    },
    DOM: {
        id: 'classForm.countries.DOM',
        defaultMessage: 'Dominican Republic (the)',
    },
    ECU: {
        id: 'classForm.countries.ECU',
        defaultMessage: 'Ecuador',
    },
    EGY: {
        id: 'classForm.countries.EGY',
        defaultMessage: 'Egypt',
    },
    SLV: {
        id: 'classForm.countries.SLV',
        defaultMessage: 'El Salvador',
    },
    GNQ: {
        id: 'classForm.countries.GNQ',
        defaultMessage: 'Equatorial Guinea',
    },
    ERI: {
        id: 'classForm.countries.ERI',
        defaultMessage: 'Eritrea',
    },
    EST: {
        id: 'classForm.countries.EST',
        defaultMessage: 'Estonia',
    },
    SWZ: {
        id: 'classForm.countries.SWZ',
        defaultMessage: 'Eswatini',
    },
    ETH: {
        id: 'classForm.countries.ETH',
        defaultMessage: 'Ethiopia',
    },
    FLK: {
        id: 'classForm.countries.FLK',
        defaultMessage: 'Falkland Islands (the) [Malvinas]',
    },
    FRO: {
        id: 'classForm.countries.FRO',
        defaultMessage: 'Faroe Islands (the)',
    },
    FJI: {
        id: 'classForm.countries.FJI',
        defaultMessage: 'Fiji',
    },
    FIN: {
        id: 'classForm.countries.FIN',
        defaultMessage: 'Finland',
    },
    FRA: {
        id: 'classForm.countries.FRA',
        defaultMessage: 'France',
    },
    GUF: {
        id: 'classForm.countries.GUF',
        defaultMessage: 'French Guiana',
    },
    PYF: {
        id: 'classForm.countries.PYF',
        defaultMessage: 'French Polynesia',
    },
    ATF: {
        id: 'classForm.countries.ATF',
        defaultMessage: 'French Southern Territories (the)',
    },
    GAB: {
        id: 'classForm.countries.GAB',
        defaultMessage: 'Gabon',
    },
    GMB: {
        id: 'classForm.countries.GMB',
        defaultMessage: 'Gambia (the)',
    },
    GEO: {
        id: 'classForm.countries.GEO',
        defaultMessage: 'Georgia',
    },
    DEU: {
        id: 'classForm.countries.DEU',
        defaultMessage: 'Germany',
    },
    GHA: {
        id: 'classForm.countries.GHA',
        defaultMessage: 'Ghana',
    },
    GIB: {
        id: 'classForm.countries.GIB',
        defaultMessage: 'Gibraltar',
    },
    GRC: {
        id: 'classForm.countries.GRC',
        defaultMessage: 'Greece',
    },
    GRL: {
        id: 'classForm.countries.GRL',
        defaultMessage: 'Greenland',
    },
    GRD: {
        id: 'classForm.countries.GRD',
        defaultMessage: 'Grenada',
    },
    GLP: {
        id: 'classForm.countries.GLP',
        defaultMessage: 'Guadeloupe',
    },
    GUM: {
        id: 'classForm.countries.GUM',
        defaultMessage: 'Guam',
    },
    GTM: {
        id: 'classForm.countries.GTM',
        defaultMessage: 'Guatemala',
    },
    GGY: {
        id: 'classForm.countries.GGY',
        defaultMessage: 'Guernsey',
    },
    GIN: {
        id: 'classForm.countries.GIN',
        defaultMessage: 'Guinea',
    },
    GNB: {
        id: 'classForm.countries.GNB',
        defaultMessage: 'Guinea-Bissau',
    },
    GUY: {
        id: 'classForm.countries.GUY',
        defaultMessage: 'Guyana',
    },
    HTI: {
        id: 'classForm.countries.HTI',
        defaultMessage: 'Haiti',
    },
    HMD: {
        id: 'classForm.countries.HMD',
        defaultMessage: 'Heard Island and McDonald Islands',
    },
    VAT: {
        id: 'classForm.countries.VAT',
        defaultMessage: 'Holy See (the)',
    },
    HND: {
        id: 'classForm.countries.HND',
        defaultMessage: 'Honduras',
    },
    HKG: {
        id: 'classForm.countries.HKG',
        defaultMessage: 'Hong Kong',
    },
    HUN: {
        id: 'classForm.countries.HUN',
        defaultMessage: 'Hungary',
    },
    ISL: {
        id: 'classForm.countries.ISL',
        defaultMessage: 'Iceland',
    },
    IND: {
        id: 'classForm.countries.IND',
        defaultMessage: 'India',
    },
    IDN: {
        id: 'classForm.countries.IDN',
        defaultMessage: 'Indonesia',
    },
    IRN: {
        id: 'classForm.countries.IRN',
        defaultMessage: 'Iran (Islamic Republic of)',
    },
    IRQ: {
        id: 'classForm.countries.IRQ',
        defaultMessage: 'Iraq',
    },
    IRL: {
        id: 'classForm.countries.IRL',
        defaultMessage: 'Ireland',
    },
    IMN: {
        id: 'classForm.countries.IMN',
        defaultMessage: 'Isle of Man',
    },
    ISR: {
        id: 'classForm.countries.ISR',
        defaultMessage: 'Israel',
    },
    ITA: {
        id: 'classForm.countries.ITA',
        defaultMessage: 'Italy',
    },
    JAM: {
        id: 'classForm.countries.JAM',
        defaultMessage: 'Jamaica',
    },
    JPN: {
        id: 'classForm.countries.JPN',
        defaultMessage: 'Japan',
    },
    JEY: {
        id: 'classForm.countries.JEY',
        defaultMessage: 'Jersey',
    },
    JOR: {
        id: 'classForm.countries.JOR',
        defaultMessage: 'Jordan',
    },
    KAZ: {
        id: 'classForm.countries.KAZ',
        defaultMessage: 'Kazakhstan',
    },
    KEN: {
        id: 'classForm.countries.KEN',
        defaultMessage: 'Kenya',
    },
    KIR: {
        id: 'classForm.countries.KIR',
        defaultMessage: 'Kiribati',
    },
    PRK: {
        id: 'classForm.countries.PRK',
        defaultMessage: "Korea (the Democratic People's Republic of)",
    },
    KOR: {
        id: 'classForm.countries.KOR',
        defaultMessage: 'Korea (the Republic of)',
    },
    KWT: {
        id: 'classForm.countries.KWT',
        defaultMessage: 'Kuwait',
    },
    KGZ: {
        id: 'classForm.countries.KGZ',
        defaultMessage: 'Kyrgyzstan',
    },
    LAO: {
        id: 'classForm.countries.LAO',
        defaultMessage: "Lao People's Democratic Republic (the)",
    },
    LVA: {
        id: 'classForm.countries.LVA',
        defaultMessage: 'Latvia',
    },
    LBN: {
        id: 'classForm.countries.LBN',
        defaultMessage: 'Lebanon',
    },
    LSO: {
        id: 'classForm.countries.LSO',
        defaultMessage: 'Lesotho',
    },
    LBR: {
        id: 'classForm.countries.LBR',
        defaultMessage: 'Liberia',
    },
    LBY: {
        id: 'classForm.countries.LBY',
        defaultMessage: 'Libya',
    },
    LIE: {
        id: 'classForm.countries.LIE',
        defaultMessage: 'Liechtenstein',
    },
    LTU: {
        id: 'classForm.countries.LTU',
        defaultMessage: 'Lithuania',
    },
    LUX: {
        id: 'classForm.countries.LUX',
        defaultMessage: 'Luxembourg',
    },
    MAC: {
        id: 'classForm.countries.MAC',
        defaultMessage: 'Macao',
    },
    MDG: {
        id: 'classForm.countries.MDG',
        defaultMessage: 'Madagascar',
    },
    MWI: {
        id: 'classForm.countries.MWI',
        defaultMessage: 'Malawi',
    },
    MYS: {
        id: 'classForm.countries.MYS',
        defaultMessage: 'Malaysia',
    },
    MDV: {
        id: 'classForm.countries.MDV',
        defaultMessage: 'Maldives',
    },
    MLI: {
        id: 'classForm.countries.MLI',
        defaultMessage: 'Mali',
    },
    MLT: {
        id: 'classForm.countries.MLT',
        defaultMessage: 'Malta',
    },
    MHL: {
        id: 'classForm.countries.MHL',
        defaultMessage: 'Marshall Islands (the)',
    },
    MTQ: {
        id: 'classForm.countries.MTQ',
        defaultMessage: 'Martinique',
    },
    MRT: {
        id: 'classForm.countries.MRT',
        defaultMessage: 'Mauritania',
    },
    MUS: {
        id: 'classForm.countries.MUS',
        defaultMessage: 'Mauritius',
    },
    MYT: {
        id: 'classForm.countries.MYT',
        defaultMessage: 'Mayotte',
    },
    MEX: {
        id: 'classForm.countries.MEX',
        defaultMessage: 'Mexico',
    },
    FSM: {
        id: 'classForm.countries.FSM',
        defaultMessage: 'Micronesia (Federated States of)',
    },
    MDA: {
        id: 'classForm.countries.MDA',
        defaultMessage: 'Moldova (the Republic of)',
    },
    MCO: {
        id: 'classForm.countries.MCO',
        defaultMessage: 'Monaco',
    },
    MNG: {
        id: 'classForm.countries.MNG',
        defaultMessage: 'Mongolia',
    },
    MNE: {
        id: 'classForm.countries.MNE',
        defaultMessage: 'Montenegro',
    },
    MSR: {
        id: 'classForm.countries.MSR',
        defaultMessage: 'Montserrat',
    },
    MAR: {
        id: 'classForm.countries.MAR',
        defaultMessage: 'Morocco',
    },
    MOZ: {
        id: 'classForm.countries.MOZ',
        defaultMessage: 'Mozambique',
    },
    MMR: {
        id: 'classForm.countries.MMR',
        defaultMessage: 'Myanmar',
    },
    NAM: {
        id: 'classForm.countries.NAM',
        defaultMessage: 'Namibia',
    },
    NRU: {
        id: 'classForm.countries.NRU',
        defaultMessage: 'Nauru',
    },
    NPL: {
        id: 'classForm.countries.NPL',
        defaultMessage: 'Nepal',
    },
    NLD: {
        id: 'classForm.countries.NLD',
        defaultMessage: 'Netherlands (the)',
    },
    NCL: {
        id: 'classForm.countries.NCL',
        defaultMessage: 'New Caledonia',
    },
    NZL: {
        id: 'classForm.countries.NZL',
        defaultMessage: 'New Zealand',
    },
    NIC: {
        id: 'classForm.countries.NIC',
        defaultMessage: 'Nicaragua',
    },
    NER: {
        id: 'classForm.countries.NER',
        defaultMessage: 'Niger (the)',
    },
    NGA: {
        id: 'classForm.countries.NGA',
        defaultMessage: 'Nigeria',
    },
    NIU: {
        id: 'classForm.countries.NIU',
        defaultMessage: 'Niue',
    },
    NFK: {
        id: 'classForm.countries.NFK',
        defaultMessage: 'Norfolk Island',
    },
    MKD: {
        id: 'classForm.countries.MKD',
        defaultMessage: 'North Macedonia',
    },
    MNP: {
        id: 'classForm.countries.MNP',
        defaultMessage: 'Northern Mariana Islands (the)',
    },
    NOR: {
        id: 'classForm.countries.NOR',
        defaultMessage: 'Norway',
    },
    OMN: {
        id: 'classForm.countries.OMN',
        defaultMessage: 'Oman',
    },
    PAK: {
        id: 'classForm.countries.PAK',
        defaultMessage: 'Pakistan',
    },
    PLW: {
        id: 'classForm.countries.PLW',
        defaultMessage: 'Palau',
    },
    PSE: {
        id: 'classForm.countries.PSE',
        defaultMessage: 'Palestine, State of',
    },
    PAN: {
        id: 'classForm.countries.PAN',
        defaultMessage: 'Panama',
    },
    PNG: {
        id: 'classForm.countries.PNG',
        defaultMessage: 'Papua New Guinea',
    },
    PRY: {
        id: 'classForm.countries.PRY',
        defaultMessage: 'Paraguay',
    },
    PER: {
        id: 'classForm.countries.PER',
        defaultMessage: 'Peru',
    },
    PHL: {
        id: 'classForm.countries.PHL',
        defaultMessage: 'Philippines (the)',
    },
    PCN: {
        id: 'classForm.countries.PCN',
        defaultMessage: 'Pitcairn',
    },
    POL: {
        id: 'classForm.countries.POL',
        defaultMessage: 'Poland',
    },
    PRT: {
        id: 'classForm.countries.PRT',
        defaultMessage: 'Portugal',
    },
    PRI: {
        id: 'classForm.countries.PRI',
        defaultMessage: 'Puerto Rico',
    },
    QAT: {
        id: 'classForm.countries.QAT',
        defaultMessage: 'Qatar',
    },
    ROU: {
        id: 'classForm.countries.ROU',
        defaultMessage: 'Romania',
    },
    RUS: {
        id: 'classForm.countries.RUS',
        defaultMessage: 'Russian Federation (the)',
    },
    RWA: {
        id: 'classForm.countries.RWA',
        defaultMessage: 'Rwanda',
    },
    REU: {
        id: 'classForm.countries.REU',
        defaultMessage: 'Réunion',
    },
    BLM: {
        id: 'classForm.countries.BLM',
        defaultMessage: 'Saint Barthélemy',
    },
    SHN: {
        id: 'classForm.countries.SHN',
        defaultMessage: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    KNA: {
        id: 'classForm.countries.KNA',
        defaultMessage: 'Saint Kitts and Nevis',
    },
    LCA: {
        id: 'classForm.countries.LCA',
        defaultMessage: 'Saint Lucia',
    },
    MAF: {
        id: 'classForm.countries.MAF',
        defaultMessage: 'Saint Martin (French part)',
    },
    SPM: {
        id: 'classForm.countries.SPM',
        defaultMessage: 'Saint Pierre and Miquelon',
    },
    VCT: {
        id: 'classForm.countries.VCT',
        defaultMessage: 'Saint Vincent and the Grenadines',
    },
    WSM: {
        id: 'classForm.countries.WSM',
        defaultMessage: 'Samoa',
    },
    SMR: {
        id: 'classForm.countries.SMR',
        defaultMessage: 'San Marino',
    },
    STP: {
        id: 'classForm.countries.STP',
        defaultMessage: 'Sao Tome and Principe',
    },
    SAU: {
        id: 'classForm.countries.SAU',
        defaultMessage: 'Saudi Arabia',
    },
    SEN: {
        id: 'classForm.countries.SEN',
        defaultMessage: 'Senegal',
    },
    SRB: {
        id: 'classForm.countries.SRB',
        defaultMessage: 'Serbia',
    },
    SYC: {
        id: 'classForm.countries.SYC',
        defaultMessage: 'Seychelles',
    },
    SLE: {
        id: 'classForm.countries.SLE',
        defaultMessage: 'Sierra Leone',
    },
    SGP: {
        id: 'classForm.countries.SGP',
        defaultMessage: 'Singapore',
    },
    SXM: {
        id: 'classForm.countries.SXM',
        defaultMessage: 'Sint Maarten (Dutch part)',
    },
    SVK: {
        id: 'classForm.countries.SVK',
        defaultMessage: 'Slovakia',
    },
    SVN: {
        id: 'classForm.countries.SVN',
        defaultMessage: 'Slovenia',
    },
    SLB: {
        id: 'classForm.countries.SLB',
        defaultMessage: 'Solomon Islands',
    },
    SOM: {
        id: 'classForm.countries.SOM',
        defaultMessage: 'Somalia',
    },
    ZAF: {
        id: 'classForm.countries.ZAF',
        defaultMessage: 'South Africa',
    },
    SGS: {
        id: 'classForm.countries.SGS',
        defaultMessage: 'South Georgia and the South Sandwich Islands',
    },
    SSD: {
        id: 'classForm.countries.SSD',
        defaultMessage: 'South Sudan',
    },
    ESP: {
        id: 'classForm.countries.ESP',
        defaultMessage: 'Spain',
    },
    LKA: {
        id: 'classForm.countries.LKA',
        defaultMessage: 'Sri Lanka',
    },
    SDN: {
        id: 'classForm.countries.SDN',
        defaultMessage: 'Sudan (the)',
    },
    SUR: {
        id: 'classForm.countries.SUR',
        defaultMessage: 'Suriname',
    },
    SJM: {
        id: 'classForm.countries.SJM',
        defaultMessage: 'Svalbard and Jan Mayen',
    },
    SWE: {
        id: 'classForm.countries.SWE',
        defaultMessage: 'Sweden',
    },
    CHE: {
        id: 'classForm.countries.CHE',
        defaultMessage: 'Switzerland',
    },
    SYR: {
        id: 'classForm.countries.SYR',
        defaultMessage: 'Syrian Arab Republic (the)',
    },
    TWN: {
        id: 'classForm.countries.TWN',
        defaultMessage: 'Taiwan (Province of China)',
    },
    TJK: {
        id: 'classForm.countries.TJK',
        defaultMessage: 'Tajikistan',
    },
    TZA: {
        id: 'classForm.countries.TZA',
        defaultMessage: 'Tanzania, the United Republic of',
    },
    THA: {
        id: 'classForm.countries.THA',
        defaultMessage: 'Thailand',
    },
    TLS: {
        id: 'classForm.countries.TLS',
        defaultMessage: 'Timor-Leste',
    },
    TGO: {
        id: 'classForm.countries.TGO',
        defaultMessage: 'Togo',
    },
    TKL: {
        id: 'classForm.countries.TKL',
        defaultMessage: 'Tokelau',
    },
    TON: {
        id: 'classForm.countries.TON',
        defaultMessage: 'Tonga',
    },
    TTO: {
        id: 'classForm.countries.TTO',
        defaultMessage: 'Trinidad and Tobago',
    },
    TUN: {
        id: 'classForm.countries.TUN',
        defaultMessage: 'Tunisia',
    },
    TUR: {
        id: 'classForm.countries.TUR',
        defaultMessage: 'Turkey',
    },
    TKM: {
        id: 'classForm.countries.TKM',
        defaultMessage: 'Turkmenistan',
    },
    TCA: {
        id: 'classForm.countries.TCA',
        defaultMessage: 'Turks and Caicos Islands (the)',
    },
    TUV: {
        id: 'classForm.countries.TUV',
        defaultMessage: 'Tuvalu',
    },
    UGA: {
        id: 'classForm.countries.UGA',
        defaultMessage: 'Uganda',
    },
    UKR: {
        id: 'classForm.countries.UKR',
        defaultMessage: 'Ukraine',
    },
    ARE: {
        id: 'classForm.countries.ARE',
        defaultMessage: 'United Arab Emirates (the)',
    },
    GBR: {
        id: 'classForm.countries.GBR',
        defaultMessage: 'United Kingdom of Great Britain and Northern Ireland (the)',
    },
    UMI: {
        id: 'classForm.countries.UMI',
        defaultMessage: 'United States Minor Outlying Islands (the)',
    },
    USA: {
        id: 'classForm.countries.USA',
        defaultMessage: 'United States of America (the)',
    },
    URY: {
        id: 'classForm.countries.URY',
        defaultMessage: 'Uruguay',
    },
    UZB: {
        id: 'classForm.countries.UZB',
        defaultMessage: 'Uzbekistan',
    },
    VUT: {
        id: 'classForm.countries.VUT',
        defaultMessage: 'Vanuatu',
    },
    VEN: {
        id: 'classForm.countries.VEN',
        defaultMessage: 'Venezuela (Bolivarian Republic of)',
    },
    VNM: {
        id: 'classForm.countries.VNM',
        defaultMessage: 'Viet Nam',
    },
    VGB: {
        id: 'classForm.countries.VGB',
        defaultMessage: 'Virgin Islands (British)',
    },
    VIR: {
        id: 'classForm.countries.VIR',
        defaultMessage: 'Virgin Islands (U.S.)',
    },
    WLF: {
        id: 'classForm.countries.WLF',
        defaultMessage: 'Wallis and Futuna',
    },
    ESH: {
        id: 'classForm.countries.ESH',
        defaultMessage: 'Western Sahara*',
    },
    YEM: {
        id: 'classForm.countries.YEM',
        defaultMessage: 'Yemen',
    },
    ZMB: {
        id: 'classForm.countries.ZMB',
        defaultMessage: 'Zambia',
    },
    ZWE: {
        id: 'classForm.countries.ZWE',
        defaultMessage: 'Zimbabwe',
    },
    ALA: {
        id: 'classForm.countries.ALA',
        defaultMessage: 'Åland Islands',
    },
});
