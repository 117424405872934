import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    loading: {
        id: 'classPage.loading',
        defaultMessage: 'Loading class data...',
    },
});

export default messages;
