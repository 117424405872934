import { AppLayout, Flashbar } from '@amzn/awsui-components-react-v3';
import { useState } from 'react';

import { Breadcrumbs } from '@/components';
import { FOOTER_SELECTOR } from '@/components/footer/Footer';
import { NAV_BAR_SELECTOR } from '@/components/NavBar';
import { useAppNotifications } from '@/utils/appNotifications';

import './index.scss';

import type { AppLayoutProps, BreadcrumbGroupProps } from '@amzn/awsui-components-react-v3';
import type { PropsWithChildren } from 'react';

export const Layout = ({
    useMaxWidth = false,
    navigation,
    breadcrumbItems,
    children,
}: PropsWithChildren<{
    useMaxWidth: boolean;
    navigation: AppLayoutProps['navigation'];
    breadcrumbItems: BreadcrumbGroupProps['items'];
}>) => {
    const [isNavigationOpen, isNavigationOpenSet] = useState(true);
    const { currentNotifications } = useAppNotifications();
    return (
        <AppLayout
            data-styles='app-layout'
            breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}
            navigationHide={!navigation}
            navigationOpen={isNavigationOpen}
            onNavigationChange={() => isNavigationOpenSet(!isNavigationOpen)}
            navigation={navigation}
            notifications={<Flashbar items={currentNotifications} stackItems />}
            toolsHide
            headerSelector={`[data-id="${NAV_BAR_SELECTOR}"]`}
            footerSelector={`[data-id="${FOOTER_SELECTOR}"]`}
            maxContentWidth={useMaxWidth ? 980 : 1860}
            content={children}
        />
    );
};
