import { createContext, useContext } from 'react';

export const FlagContext = createContext();

const toBooleanValue = (val) => /^true$/i.test(val);
export const FlagContextProvider = ({ children }) => {
    const flags = JSON.parse(process.env.REACT_APP_featureToggles ?? '{}');
    return (
        <FlagContext.Provider
            value={{
                studentRoster: toBooleanValue(flags.studentRoster),
                studentLandingPage: toBooleanValue(flags.studentLandingPage), // https://sim.amazon.com/issues/BKR-6057
                cancelClassroom: toBooleanValue(flags.cancelClassroom), // https://sim.amazon.com/issues/BKR-4805
                scheduledEvents: toBooleanValue(flags.scheduledEvents),
                multiGilmoreId: toBooleanValue(flags.multiGilmoreId),
                endLabs: toBooleanValue(flags.endLabs), // https://sim.amazon.com/issues/BKR-5986
                persistedRegionSelection: toBooleanValue(flags.persistedRegionSelection), // https://issues.amazon.com/issues/BKR-6322
                pooledLabs: toBooleanValue(flags.pooledLabs),
                aurousIntegration: toBooleanValue(flags.aurousIntegration),
                ccsIntegration: toBooleanValue(flags.ccsIntegration), // https://issues.amazon.com/issues/BKR-6821,
                enableCcsIntegrationWithMock: toBooleanValue(flags.enableCcsIntegrationWithMock), // https://issues.amazon.com/issues/BKR-6821
                attendanceMarking: toBooleanValue(flags.attendanceMarking), // https://issues.amazon.com/issues/BKR-7555
                resourceConsolidation: toBooleanValue(flags.resourceConsolidation), // https://issues.amazon.com/issues/BKR-6995
                requiresReset: toBooleanValue(flags.requiresReset), // https://issues.amazon.com/issues/BKR-6995
                grimsbyIntegration: toBooleanValue(flags.grimsbyIntegration), // https://sim.amazon.com/issues/BKR-7186
                enableMultiGilmoreIdPhase2: toBooleanValue(flags.enableMultiGilmoreIdPhase2), // https://sim.amazon.com/issues/BKR-7159
            }}
        >
            {children}
        </FlagContext.Provider>
    );
};

export const useFlags = () => useContext(FlagContext) ?? {};
