import {
    Box,
    Container,
    DatePicker,
    FormField,
    Grid,
    Input,
    TimeInput,
} from '@amzn/awsui-components-react-v3';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

import {
    handleFormValueChange,
    MIN_JAM_TEAM_SIZE,
    MAX_JAM_TEAM_SIZE,
    JAM_DURATION,
} from '@/components/classForm/ClassForm.utils';
import { FieldErrors, showErrorMessage } from '@/components/classForm/FieldErrors';
import { JamHeader } from '@/components/jamHeader/JamHeader';
import { DATEPICKER_FORMAT } from '@/utils';

import { messages } from './JamFormSection.messages';

import './JamFormSection.scss';

const {
    startDateInputLabel,
    startDateInputDescription,
    startDateInputWarning,
    startTimeInputLabel,
    startTimeInputDescription,
    startTimeInputWarning,
    teamSizeInputLabel,
    teamSizeInputDescription,
} = messages;

const JamFormSection = ({
    jamInputDataSet,
    jamInputData,
    enabled,
    fieldsInvalid,
    jamTraining = {},
}) => {
    const { formatMessage } = useIntl();
    const inputSet =
        (keyPath) =>
        ({ detail }) =>
            handleFormValueChange({ value: detail?.value, setData: jamInputDataSet, keyPath });
    if (!enabled) return null;
    const gridDefinition = [{ colspan: { default: 5 } }, { colspan: { default: 4 } }];
    const jamEventHasStarted = jamTraining?.metaData?.startsOn < moment().unix();
    const jamEventHasEnded = jamTraining?.metaData?.endsOn < moment().unix();
    return (
        <Box margin={{ bottom: 'l' }} data-testid='class-form-jam-section'>
            <Container header={<JamHeader />}>
                <Box margin={{ top: 'xxs', bottom: 'l' }}>
                    <Grid gridDefinition={gridDefinition}>
                        <FormField
                            label={formatMessage(startDateInputLabel)}
                            constraintText={formatMessage(startDateInputWarning)}
                            description={formatMessage(startDateInputDescription)}
                        >
                            <DatePicker
                                data-testid='startDate'
                                placeholder={DATEPICKER_FORMAT}
                                value={jamInputData.startDate}
                                invalid={!!fieldsInvalid?.jamStartDate}
                                disabled={jamEventHasStarted}
                                onChange={inputSet('startDate')}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property='jamStartDate' />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property='jamDuration' />
                        </FormField>
                        <FormField
                            label={formatMessage(startTimeInputLabel)}
                            constraintText={formatMessage(startTimeInputWarning, {
                                duration: JAM_DURATION,
                            })}
                            description={formatMessage(startTimeInputDescription)}
                        >
                            <TimeInput
                                format='hh:mm'
                                placeholder='hh:mm'
                                disabled={jamEventHasStarted}
                                value={jamInputData.startTime}
                                onChange={inputSet('startTime')}
                            />
                        </FormField>
                    </Grid>
                </Box>
                <FormField
                    label={formatMessage(teamSizeInputLabel)}
                    description={formatMessage(teamSizeInputDescription, {
                        lower: MIN_JAM_TEAM_SIZE,
                        upper: MAX_JAM_TEAM_SIZE,
                    })}
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'jamTeamSize',
                    })}
                >
                    <Input
                        onChange={inputSet('teamSize')}
                        disabled={jamEventHasEnded}
                        value={jamInputData.teamSize}
                        invalid={!!fieldsInvalid?.jamTeamSize}
                        controlId='class-form-jam__team-size'
                    />
                </FormField>
            </Container>
        </Box>
    );
};

export default JamFormSection;
