import { Authenticator, ComponentDidCatch } from '@/components';
import { LangLocale } from '@/components/locale/Locale';
import { ClassroomProvider } from '@/data/ClassroomContext';
import { GilmoreHealthProvider } from '@/data/GilmoreHealthContext';
import { ProviderCtxProvider } from '@/data/ProviderContext';
import { FlagContextProvider } from '@/utils';
import { AppNotificationsProvider } from '@/utils/appNotifications';
import { QueryClientProviderWrapper } from '@/utils/QueryClientProvider';
import { UserInfoProvider } from '@/utils/userInfo';

const Provides = ({ contexts = [], children }) =>
    contexts.reduceRight((reactNode, Wrapper) => <Wrapper>{reactNode}</Wrapper>, children);

export const AppProviders = ({ children }) => (
    <Provides
        contexts={[
            FlagContextProvider,
            LangLocale,
            ComponentDidCatch,
            ClassroomProvider,
            Authenticator,
            QueryClientProviderWrapper,
            ProviderCtxProvider,
            UserInfoProvider,
            GilmoreHealthProvider,
            AppNotificationsProvider,
        ]}
    >
        {children}
    </Provides>
);
