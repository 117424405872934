import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getClassAttendance, markAttendance } from '@/modules';

import { useFlags } from '../utils';

export const useGetClassAttendance = (classData) => {
    const flags = useFlags();
    const classroomId = classData?.classroom?.classroomId;
    const providerArn = classData?.classroom?.providerArn;
    const learningActivityID = classData?.classroom?.learningActivityID;
    const { data, isLoading, refetch, error } = useQuery(
        [classroomId, providerArn, learningActivityID, 'getClassAttendance'],
        () => getClassAttendance(classroomId, providerArn, learningActivityID),
        {
            refetchOnWindowFocus: false,
            enabled: flags.attendanceMarking && !!learningActivityID,
        },
    );
    return {
        data: data?.learningActivityAttendanceRecords,
        isLoading,
        error,
        refetch,
    };
};

export const useMarkClassAttendance = (classData) => {
    const classroomId = classData?.classroom?.classroomId;
    const providerArn = classData?.classroom?.providerArn;
    const learningActivityID = classData?.classroom?.learningActivityID;
    return async (markAttendanceInputs, onCompleteHandler, onErrorHandler) => {
        return Promise.all(
            _.map(markAttendanceInputs, async (markAttendanceInput) => {
                if (!classroomId || !providerArn || !learningActivityID) {
                    throw new Error('Attempted to mark attendance for unsupported classroom');
                }
                return await markAttendance({
                    classroomId,
                    providerArn,
                    learningActivityID,
                    ...markAttendanceInput,
                });
            }),
        )
            .then(onCompleteHandler)
            .catch(onErrorHandler);
    };
};

export const useGetAttendanceListingEnabled = (learningActivity) => {
    const flags = useFlags();
    const attendanceMarkingEnabled = useMemo(() => {
        if (
            flags.attendanceMarking &&
            (learningActivity?.lms_type === 'myClass' ||
                learningActivity?.lms_type === 'CustomerLMS')
        ) {
            return true;
        }
        return undefined;
    }, [flags, learningActivity]);

    return attendanceMarkingEnabled;
};
