import _ from 'lodash';
import { defineMessages } from 'react-intl.macro';

import { sendClientLog } from '@/modules/api';

export const messages = defineMessages({
    // BUTTONS + LABELS
    cancelButtonText: {
        id: 'classForm.cancelButtonText',
        defaultMessage: 'Cancel',
    },
    addButtonText: {
        id: 'classForm.addButtonText',
        defaultMessage: 'Add',
    },
    addButtonLabel: {
        id: 'classForm.addButtonLabel',
        defaultMessage: 'Add new class',
    },
    saveButtonText: {
        id: 'classForm.saveButtonText',
        defaultMessage: 'Save',
    },
    saveButtonLabel: {
        id: 'classForm.saveButtonLabel',
        defaultMessage: 'Save changes to class data',
    },
    addInstructorButtonText: {
        id: 'classForm.addInstructorButtonText',
        defaultMessage: 'Add instructor',
    },
    removeButtonText: {
        id: 'classForm.removeButtonText',
        defaultMessage: 'Remove',
    },
    doneButtonText: {
        id: 'classForm.doneButtonText',
        defaultMessage: 'Done',
    },
    okButtonText: {
        id: 'classForm.okButtonText',
        defaultMessage: 'OK',
    },
    createClassButtonText: {
        id: 'classForm.createClassButtonText',
        defaultMessage: 'Create class',
    },
    closeModalButtonLabel: {
        id: 'classForm.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
    infoButtonText: {
        id: 'classForm.infoButtonText',
        defaultMessage: 'Info',
    },
    infoButtonOpenLabel: {
        id: 'classForm.infoButtonOpenLabel',
        defaultMessage: 'Open info panel',
    },
    infoButtonCloseLabel: {
        id: 'classForm.infoButtonCloseLabel',
        defaultMessage: 'Close info panel',
    },

    // PAGE TITLES
    addPageTitle: {
        id: 'classForm.addPageTitle',
        defaultMessage: 'Create class',
    },
    editPageTitle: {
        id: 'classForm.editPageTitle',
        defaultMessage: 'Edit class',
    },

    // CONTAINER HEADERS
    courseInfoHeader: {
        id: 'classForm.courseInfoHeader',
        defaultMessage: 'Course information',
    },
    codeInfoHeader: {
        id: 'classform.codeInfoHeader',
        defaultMessage: 'Create student code',
    },
    classTimeHeader: {
        id: 'classForm.classTimeHeader',
        defaultMessage: 'Class time',
    },
    classProviderHeader: {
        id: 'classForm.classProviderHeader',
        defaultMessage: 'Class provider',
    },
    classPartnerHeader: {
        id: 'classForm.classPartnerHeader',
        defaultMessage: 'Class partner',
    },
    classLocationHeader: {
        id: 'classForm.classLocationHeader',
        defaultMessage: 'Class location',
    },
    advancedSettingsHeader: {
        id: 'classForm.advancedSettingsHeader',
        defaultMessage: 'Advanced Settings',
    },

    // FIELD LABELS
    selectedLabel: {
        id: 'classForm.selectedLabel',
        defaultMessage: 'Selected',
    },

    courseTemplateLabel: {
        id: 'classForm.courseTemplateLabel',
        defaultMessage: 'Course template',
    },
    filterCourseTemplatePlaceholder: {
        id: 'classForm.filterCourseTemplatePlaceholder',
        defaultMessage: 'Search course',
    },
    courseTemplatePlaceholder: {
        id: 'classForm.courseTemplatePlaceholder',
        defaultMessage: 'Select course template',
    },
    courseTemplateEmptyLabel: {
        id: 'classForm.courseTemplateEmptyLabel',
        defaultMessage: 'No course templates available',
    },

    courseVersionLabel: {
        id: 'classForm.courseVersionLabel',
        defaultMessage: 'Course version',
    },
    courseVersionPlaceholder: {
        id: 'classForm.courseVersionPlaceholder',
        defaultMessage: 'Select course version',
    },
    filterCourseVersionPlaceholder: {
        id: 'classForm.filterCourseVersionPlaceholder',
        defaultMessage: 'Search course versions',
    },
    courseVersionEmptyLabel: {
        id: 'classForm.courseVersionEmptyLabel',
        defaultMessage: 'No course versions available',
    },
    courseVersionContentLabel: {
        id: 'classForm.courseVersionContentLabel',
        defaultMessage: 'Content version',
    },
    newestCourseVersion: {
        id: 'classForm.newestCourseVersion',
        defaultMessage: 'newest',
    },
    courseLanguageLabel: {
        id: 'classForm.courseLanguageLabel',
        defaultMessage: 'Course language',
    },
    courseLanguagePlaceholder: {
        id: 'classForm.courseLanguagePlaceholder',
        defaultMessage: 'Select course language',
    },
    courseLanguageEmptyLabel: {
        id: 'classForm.courseLanguageEmptyLabel',
        defaultMessage: 'No course languages available',
    },

    instructorInputLabel: {
        id: 'classForm.instructorInputLabel',
        defaultMessage: 'Instructor email number {index}',
    },

    accessTypeLabel: {
        id: 'classForm.accessTypeLabel',
        defaultMessage: 'Access type',
    },
    singleCodeLabel: {
        id: 'classForm.singleCodeLabel',
        defaultMessage: 'Create single code',
    },
    multiUseCodeLabel: {
        id: 'classForm.multiUseCodeLabel',
        defaultMessage: 'Multi-use code',
    },
    uniqueCodeLabel: {
        id: 'classForm.uniqueCodeLabel',
        defaultMessage: 'Create unique codes',
    },
    createdSingleCodeLabel: {
        id: 'classForm.createdSingleCodeLabel',
        defaultMessage: 'Single code created',
    },
    createdUniqueCodeLabel: {
        id: 'classForm.createdUniqueCodeLabel',
        defaultMessage: 'Unique codes created',
    },
    noAccessCodesLabel: {
        id: 'classForm.noAccessCodesLabel',
        defaultMessage: 'No access codes created',
    },
    numberOfStudentSubProviderInputLabel: {
        id: 'classForm.numberOfStudentSubProviderInputLabel',
        defaultMessage: 'Number of students for {subProviderName}.',
    },
    numberOfStudentParentProviderTotal: {
        id: 'classForm.numberOfStudentParentProviderTotal',
        defaultMessage: 'Total',
    },
    numberOfStudentParentProviderTotalInputLabel: {
        id: 'classForm.numberOfStudentParentProviderTotalInputLabel',
        defaultMessage: 'Total number of students.',
    },
    numberOfStudentsLabel: {
        id: 'classForm.numberOfStudentsLabel',
        defaultMessage: 'Number of students',
    },
    availableLicensesLabel: {
        id: 'classForm.availableLicensesLabel',
        defaultMessage: 'Available Licenses',
    },
    numberOfStudentsHint: {
        id: 'classForm.numberOfStudentsHint',
        defaultMessage: 'Must be between {min} and {max}',
    },
    capacityDisabledPlannedHint: {
        id: 'classForm.capacityDisabledPlannedHint',
        defaultMessage: 'You’ll be able to change this after {time} on {date}.',
    },
    capacityDisabledUnplannedHint: {
        id: 'classForm.capacityDisabledUnplannedHint',
        defaultMessage: 'Temporarily unavailable. Check back later.',
    },
    capacityMultiProviderLabel: {
        id: 'classForm.capacityMultiProviderLabel',
        defaultMessage: 'Eligible associated Gilmore ID providers',
    },

    dateRangeLabel: {
        id: 'classForm.dateRangeLabel',
        defaultMessage: 'Date range',
    },
    startDateLabel: {
        id: 'classForm.startDateLabel',
        defaultMessage: 'Start date',
    },
    endDateLabel: {
        id: 'classForm.endDateLabel',
        defaultMessage: 'End date',
    },
    endDateDescription: {
        id: 'classForm.endDateDescription',
        defaultMessage: 'Labs will expire after end-date. Recommended +4 weeks from start date.',
    },
    todayLabel: {
        id: 'classForm.todayLabel',
        defaultMessage: 'Today',
    },
    nextMonthLabel: {
        id: 'classForm.nextMonthLabel',
        defaultMessage: 'Next month',
    },
    previousMonthLabel: {
        id: 'classForm.previousMonthLabel',
        defaultMessage: 'Previous month',
    },

    startTimeLabel: {
        id: 'classForm.startTimeLabel',
        defaultMessage: 'Start time',
    },
    endTimeLabel: {
        id: 'classForm.endTimeLabel',
        defaultMessage: 'End time',
    },
    timeZoneLabel: {
        id: 'classForm.timeZoneLabel',
        defaultMessage: 'Time zone',
    },

    trainingProviderLabel: {
        id: 'classForm.trainingProviderLabel',
        defaultMessage: 'Training provider',
    },
    trainingPartnerLabel: {
        id: 'classForm.trainingPartnerLabel',
        defaultMessage: 'Training partner',
    },
    instructorLabel: {
        id: 'classForm.instructorLabel',
        defaultMessage: 'Instructor',
    },
    instructorPlaceholder: {
        id: 'classForm.instructorPlaceholder',
        defaultMessage: 'Assign instructor',
    },
    instructorInputPlaceholder: {
        id: 'classForm.instructorInputPlaceholder',
        defaultMessage: 'example@email.com',
    },

    locationTypeLabel: {
        id: 'classForm.locationTypeLabel',
        defaultMessage: 'Location type',
    },
    virtualClassUrlLabel: {
        id: 'classForm.virtualClassUrlLabel',
        defaultMessage: 'Virtual classroom URL',
    },
    virtualDescription: {
        id: 'classForm.virtualDescription',
        defaultMessage: 'Enter URL of Webex or GoToTraining for virtual class.',
    },
    studentViewUrlLabel: {
        id: 'classForm.studentViewUrlLabel',
        defaultMessage: 'Student view URL',
    },

    addressLine1Label: {
        id: 'classForm.addressLine1Label',
        defaultMessage: 'Street address line 1',
    },
    addressLine2Label: {
        id: 'classForm.addressLine2Label',
        defaultMessage: 'Street address line 2',
    },
    cityLabel: {
        id: 'classForm.cityLabel',
        defaultMessage: 'City',
    },
    stateLabel: {
        id: 'classForm.stateLabel',
        defaultMessage: 'State / province / territory',
    },
    postalCodeLabel: {
        id: 'classForm.postalCodeLabel',
        defaultMessage: 'Postal / zip code',
    },
    countryLabel: {
        id: 'classForm.countryLabel',
        defaultMessage: 'Country',
    },
    countryPlaceholder: {
        id: 'classForm.countryPlaceholder',
        defaultMessage: 'Select country',
    },
    loadingField: {
        id: 'classForm.loadingField',
        defaultMessage: 'Loading...',
    },
    grimsbyActivityIdLabel: {
        id: 'classForm.grimsbyActivityIdLabel',
        defaultMessage: 'Grimsby Activity ID',
    },
    grimsbyActivityIdDescription: {
        id: 'classForm.grimsbyActivityIdDescription',
        defaultMessage: 'Find in the associated activity URL in Grimsby',
    },
    locationSourceLabel: {
        id: 'classForm.locationSourceLabel',
        defaultMessage: 'Use meeting from Grimsby activity',
    },

    // field validation errors
    courseIdRequired: {
        id: 'classForm.courseIdRequired',
        defaultMessage: 'Course template is required',
    },
    courseIdInvalid: {
        id: 'classForm.courseIdInvalid',
        defaultMessage: 'Course template is invalid',
    },
    courseVersionIdRequired: {
        id: 'classForm.courseVersionIdRequired',
        defaultMessage: 'Course version is required',
    },
    courseVersionIdInvalid: {
        id: 'classForm.courseVersionIdInvalid',
        defaultMessage: 'Course version is invalid',
    },
    courseVersionIdChangeInvalid: {
        id: 'classForm.courseVersionIdChangeInvalid',
        defaultMessage: "Course's major and minor versions can't be changed",
    },
    langLocaleRequired: {
        id: 'classForm.langLocaleRequired',
        defaultMessage: 'Language is required',
    },
    langLocaleInvalid: {
        id: 'classForm.langLocaleInvalid',
        defaultMessage: 'Language is invalid',
    },
    langLocaleChangeInvalid: {
        id: 'classForm.langLocaleChangeInvalid',
        defaultMessage: "Language can't be changed after class is created",
    },
    classCapacityRequired: {
        id: 'classForm.classCapacityRequired',
        defaultMessage: 'Number of students is required',
    },
    classCapacityNumLimit: {
        id: 'classForm.classCapacityNumLimit',
        defaultMessage: 'Number of students must be between 1 and 300',
    },
    classCapacityIncreaseInvalid: {
        id: 'classForm.classCapacityIncreaseInvalid',
        defaultMessage: "Class size can't be increased due to insufficient licenses",
    },
    classCapacityIncreaseInvalidDetailed: {
        id: 'classForm.classCapacityIncreaseInvalidDetailed',
        defaultMessage:
            "Class size can't be increased due to insufficient licenses. {values} available.",
    },
    classCapacityDecreaseInvalid: {
        id: 'classForm.classCapacityDecreaseInvalid',
        defaultMessage: "Class size can't be decreased below the number of students in the class.",
    },
    classCapacityDecreaseInvalidDetailed: {
        id: 'classForm.classCapacityDecreaseInvalidDetailed',
        defaultMessage:
            "Class size can't be decreased below the number of students in the class. {values} students in the class.",
    },
    classCapacityInvalid: {
        id: 'classForm.classCapacityInvalid',
        defaultMessage: 'Number of students is not valid',
    },
    classCapacityAvailableVirtualStocksMismatch: {
        id: 'classForm.classCapacityAvailableVirtualStocksMismatch',
        defaultMessage: 'Class size is more than available student eKits',
    },
    classCapacityAvailableVirtualStocksMismatchDetailed: {
        id: 'classForm.classCapacityAvailableVirtualStocksMismatchDetailed',
        defaultMessage:
            'Number of students exceeds {values} available ekits, purchase more in Gilmore Storefront.',
    },
    endDateEndDatePassed: {
        id: 'classForm.endDateEndDatePassed',
        defaultMessage: "End date can't be in the past",
    },
    startDateRequired: {
        id: 'classForm.startDateRequired',
        defaultMessage: 'Start date is required',
    },
    endDateRequired: {
        id: 'classForm.endDateRequired',
        defaultMessage: 'End date is required',
    },
    endDateLengthDetailed: {
        id: 'classForm.endDateLengthDetailed',
        defaultMessage: 'Class duration less than recommended',
    },
    endDateEndBeforeStart: {
        id: 'classForm.endDateEndBeforeStart',
        defaultMessage: "End date can't be before start date",
    },
    endDateDurationExceeded: {
        id: 'classForm.endDateDurationExceeded',
        defaultMessage: "End date can't be more than 5 weeks after start date",
    },
    endDateExtensionInvalid: {
        id: 'classForm.endDateExtensionInvalid',
        defaultMessage:
            "The new end date can't be extended more than 5 weeks past the current end date. Please pick a date before {date}",
    },
    endDateReductionInvalid: {
        id: 'classForm.endDateReductionInvalid',
        defaultMessage: "Class duration can't be decreased after the class has started",
    },
    startTimeRequired: {
        id: 'classForm.startTimeRequired',
        defaultMessage: 'Start time is required',
    },
    endTimeRequired: {
        id: 'classForm.endTimeRequired',
        defaultMessage: 'End time is required',
    },
    startEndTimeInvalid: {
        id: 'classForm.startEndTimeInvalid',
        defaultMessage: "End time can't be before start time",
    },
    timezoneRequired: {
        id: 'classForm.timezoneRequired',
        defaultMessage: 'Timezone is required',
    },
    timezoneInvalid: {
        id: 'classForm.timezoneInvalid',
        defaultMessage: 'Timezone is invalid',
    },
    virtualUrlRequired: {
        id: 'classForm.virtualUrlRequired',
        defaultMessage: 'Virtual url is required for virtual classroom',
    },
    virtualUrlPattern: {
        id: 'classForm.virtualUrlPattern',
        defaultMessage: 'Virtual url must be a valid url',
    },
    virtualUrlInvalid: {
        id: 'classForm.virtualUrlInvalid',
        defaultMessage: 'Virtual url is invalid',
    },
    virtualUrlLength: {
        id: 'classForm.virtualUrlLength',
        defaultMessage: 'Virtual url cannot exceed 255 characters',
    },
    addressLine1Required: {
        id: 'classForm.addressLine1Required',
        defaultMessage: 'Address is required for in-person classroom',
    },
    addressLine1Length: {
        id: 'classForm.addressLine1Length',
        defaultMessage: 'Address line 1 cannot exceed 100 characters',
    },
    addressLine1Invalid: {
        id: 'classForm.addressLine1Invalid',
        defaultMessage: 'Address is invalid',
    },
    addressLine2Length: {
        id: 'classForm.addressLine2Length',
        defaultMessage: 'Address line 2 cannot exceed 100 characters',
    },
    addressLine2Invalid: {
        id: 'classForm.addressLine2Invalid',
        defaultMessage: 'Address line 2 is invalid',
    },
    cityRequired: {
        id: 'classForm.cityRequired',
        defaultMessage: 'City is required for in-person classroom',
    },
    cityLength: {
        id: 'classForm.cityLength',
        defaultMessage: 'City cannot exceed 60 characters',
    },
    cityInvalid: {
        id: 'classForm.cityInvalid',
        defaultMessage: 'City is invalid',
    },
    stateRequired: {
        id: 'classForm.stateRequired',
        defaultMessage: 'State is required for in-person classroom',
    },
    stateLength: {
        id: 'classForm.stateLength',
        defaultMessage: 'State cannot exceed 60 characters',
    },
    stateInvalid: {
        id: 'classForm.stateInvalid',
        defaultMessage: 'State is invalid',
    },
    postalCodeRequired: {
        id: 'classForm.postalCodeRequired',
        defaultMessage: 'Postal / zip code is required for in-person classroom',
    },
    postalCodeLength: {
        id: 'classForm.postalCodeLength',
        defaultMessage: 'Postal / zip code cannot exceed 16 characters',
    },
    postalCodeInvalid: {
        id: 'classForm.postalCodeInvalid',
        defaultMessage: 'Postal / zip code is invalid',
    },
    countryRequired: {
        id: 'classForm.countryRequired',
        defaultMessage: 'Country is required for in-person classroom',
    },
    countryLength: {
        id: 'classForm.countryLength',
        defaultMessage: 'Country must be between 2 and 60 characters',
    },
    countryInvalid: {
        id: 'classForm.countryInvalid',
        defaultMessage: 'Country is invalid',
    },
    instructorsPattern: {
        id: 'classForm.instructorsPattern',
        defaultMessage: 'Instructor email is invalid',
    },
    instructorsRequired: {
        id: 'classForm.instructorsRequired',
        defaultMessage: 'Instructor email is required',
    },
    instructorsInvalidInclusion: {
        id: 'classForm.instructorsInvalid',
        defaultMessage: "Training coordinators can't be class instructors",
    },
    instructorsUnauthorized: {
        id: 'classForm.unauthorizedInstructors',
        defaultMessage: 'Unauthorized instructors cannot be co-instructors for this class',
    },
    jamStartDateRequired: {
        id: 'classForm.jamStartDateRequired',
        defaultMessage: 'Jam event start date is required',
    },
    jamStartDateInvalid: {
        id: 'classForm.jamStartDateInvalid',
        defaultMessage:
            'Jam Event must begin at least 10 minutes from now and after the start of the class',
    },
    jamTeamSizeInvalid: {
        id: 'classForm.jamTeamSizeInvalid',
        defaultMessage: 'Invalid Jam team size',
    },
    jamTeamSizeRequired: {
        id: 'classForm.jamTeamSizeRequired',
        defaultMessage: 'Jam team size is required',
    },
    jamDurationInvalid: {
        id: 'classForm.jamDurationInvalid',
        defaultMessage: 'Jam event must conclude before the end of the class',
    },
    learningActivityIDInvalid: {
        id: 'classForm.learningActivityIDInvalid',
        defaultMessage: 'Grimsby activity ID is invalid or does not match the classroom',
    },
    learningActivityIDLearningActivityMismatchDetailed: {
        id: 'classForm.learningActivityIDLearningActivityMismatchDetailed',
        defaultMessage:
            'Classroom input does not match the associated Grimsby activity.  These fields do not match: {values}',
    },
    learningActivityIDMissingLearningActivityID: {
        id: 'classForm.learningActivityIDMissingLearningActivityID',
        defaultMessage: 'Grimsby activity ID is required if using meeting from Grimsby',
    },
    learningActivityIDLearningActivityNotFound: {
        id: 'classForm.learningActivityIDLearningActivityNotFound',
        defaultMessage: 'Grimsby activity not found',
    },
    learningActivityIDLength: {
        id: 'classForm.learningActivityIDLength',
        defaultMessage: 'Grimsby activity ID should not be longer than 50 characters',
    },
    learningActivityIDPattern: {
        id: 'classForm.learningActivityIDPattern',
        defaultMessage:
            'Grimsby activity ID should only contain alphanumeric characters, backslashes, and dashes',
    },
    grimsbyClassEditBanner: {
        id: 'classForm.grimsbyClassEditBanner',
        defaultMessage: 'This class was created via Grimsby. Please make edits to the class in',
    },
    locationTypeInvalidLocationType: {
        id: 'classForm.locationTypeInvalidLocationType',
        defaultMessage: 'Location type must be virtual if using meeting from Grimsby',
    },

    // Roster resize modal
    rosterResizeIncreaseHeader: {
        id: 'classForm.rosterResizeIncreaseHeader',
        defaultMessage: 'Increasing class size',
    },
    rosterResizeIncreaseContent: {
        id: 'classForm.rosterResizeIncreaseContent',
        defaultMessage:
            'Increasing the class size will reserve an additional {additionalNum} Student eKits from your virtual stock.',
    },
    rosterResizeDecreaseHeader: {
        id: 'classForm.rosterResizeDecreaseHeader',
        defaultMessage: 'Decreasing class size',
    },
    rosterResizeDecreaseContent: {
        id: 'classForm.rosterResizeDecreaseContent',
        defaultMessage:
            'Decreasing the class size will return {additionalNum} Student eKits to your virtual stock.',
    },
    classCapacityAndLicenseQuantityMismatchErrorHeader: {
        id: 'classForm.classCapacityAndLicenseQuantityMismatchErrorHeader',
        defaultMessage: 'Not enough eKits available',
    },
    classCapacityAndLicenseQuantityMismatchErrorContent: {
        id: 'classForm.classCapacityAndLicenseQuantityMismatchErrorContent',
        defaultMessage:
            'Class size is more than available student eKits. You have {availableQuantity} available licenses. Visit Gilmore storefront to buy more.',
    },
    classCapacityAndLicenseQuantityMismatchErrorContentWithSubProvider: {
        id: 'classForm.classCapacityAndLicenseQuantityMismatchErrorContentWithSubProvider',
        defaultMessage:
            'Class size for one or more providers is more than available student eKits. Visit Gilmore storefront to buy more.',
    },
    maximumInstructorNote: {
        id: 'classForm.maximumInstructorNote',
        defaultMessage: 'A class can have up to 5 instructors.',
    },
    expiredClassHeader: {
        id: 'classForm.expiredClassHeader',
        defaultMessage: 'Class has expired.',
    },
    expiredClassBody: {
        id: 'classForm.expiredClassBody',
        defaultMessage: 'An expired class cannot be edited, please create a new class.',
    },
    // Class Access
    classAccessHeader: {
        id: 'classForm.classAccessHeader',
        defaultMessage: 'Class access',
    },

    // Course Authorization
    coursesLabel: {
        id: 'classForm.courseAuthorization.selectLabel',
        defaultMessage: 'Course',
    },
    coursesPlaceholder: {
        id: 'classForm.courseAuthorization.selectPlaceholder',
        defaultMessage: 'Select course',
    },
    courseEmptyLabel: {
        id: 'classForm.courseAuthorizationEmptyLabel',
        defaultMessage: 'No course available',
    },
    filterCoursePlaceholder: {
        id: 'classForm.filterCoursePlaceholder',
        defaultMessage: 'Search course',
    },
});

export const getErrorMessage = (property, code, values) => {
    let message;
    if (!_.isUndefined(values)) {
        message = messages[`${property}${code}Detailed`];
    }
    if (!message) {
        message = messages[`${property}${code}`];
    }
    if (!message) {
        // report that we dont have a translation for that property/code combination
        sendClientLog({
            type: 'Translations',
            attributes: { [property]: code },
            metrics: { missingValidationCodeTranslation: [1, 'Count'] },
        });
        return messages[`${property}Invalid`];
    }
    return message;
};
