import { Box, ContentLayout, Header } from '@amzn/awsui-components-react-v3';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { ManageClassDropdown } from '@/components';
import { LabCard, JamCard } from '@/components/labCard/LabCard';

import messages from './LabList.messages';

import './LabList.css';

const translateDurationUnit = (value, unit, locale) => {
    // crlalva: to match Content API duration enum
    // https://code.amazon.com/packages/BunsenContent/blobs/mainline/--/src/libraries/data/blueprintVersionEnums.js
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment.duration(value, unit).locale(locale).humanize();
};

const LabListBase = ({ children, label, title, description, hideActions }) => {
    return (
        <ContentLayout
            data-testid='lab-list-base'
            header={
                <Header
                    variant='h1'
                    description={description}
                    actions={hideActions ? null : <ManageClassDropdown />}
                >
                    {title}
                </Header>
            }
        >
            {label ? <Box variant='awsui-key-label'>{label}</Box> : null}
            {children}
        </ContentLayout>
    );
};

export const LabList = ({ labs = [], title, description }) => {
    const { locale, formatMessage } = useIntl();

    const formattedLabs = labs.map((content, index) => ({
        ...content,
        index,
        title: content.title.replace(new RegExp(`^\\s*${title}\\s*-\\s*`), ''),
        duration: translateDurationUnit(content.duration, content.durationUnit || 'minute', locale),
    }));

    return formattedLabs.length ? (
        <LabListBase
            title={title}
            description={description}
            label={formatMessage(messages.labsHeaderLabel)}
        >
            <ol className='lab-list'>
                {formattedLabs.map((lab) => (
                    <LabCard {...lab} key={lab.title} />
                ))}
            </ol>
        </LabListBase>
    ) : null;
};

export const JamList = ({ title, jamTrainings = [], hideActions }) => {
    const { formatMessage } = useIntl();
    return jamTrainings.length ? (
        <LabListBase
            title={formatMessage(messages.jamTitle)}
            label={formatMessage(messages.jamHeaderLabel)}
            hideActions={hideActions}
        >
            {jamTrainings.map((training, index) => (
                <JamCard
                    courseTitle={title}
                    jamIndex={index}
                    jamTraining={training}
                    key={training.contentId}
                />
            ))}
        </LabListBase>
    ) : null;
};
