import { Redirect, Switch, Route } from 'react-router-dom';

import {
    ClassPage,
    ClassDetailPage,
    ClassEditPage,
    ClassCreatePage,
    ErrorPage,
    LabPage,
    ProvidersPage,
    ClassListPageV2,
    AttendancePage,
    SplitLoginPage,
} from '@/containers';
import { useFlags, useUserInfo } from '@/utils';
import { paths, parameters, redirect } from '@/utils/paths';
const { classroomId } = parameters;

export const Router = ({ globals = window }) => {
    const features = useFlags();
    const userInfo = useUserInfo();

    return (
        <Switch>
            {userInfo?.originalSignInMethodName ? (
                <Route
                    render={() => (
                        <SplitLoginPage
                            originalSignInMethodName={userInfo.originalSignInMethodName}
                        />
                    )}
                />
            ) : null}
            <Route path='/callback' exact render={() => <Redirect to={redirect(globals)} />} />
            <Route path='/' exact render={() => <Redirect to={redirect(globals)} />} />
            <Route
                exact
                strict
                path='(.*//+.*)'
                render={({ location }) => (
                    <Redirect to={location.pathname.replace(/\/\/+/g, '/')} />
                )}
            />
            <Route exact path={paths.providersPage} component={ProvidersPage} />
            <Route exact path={paths.classListPage} component={ClassListPageV2} />
            <Route exact path={paths.classPage('new')} component={ClassCreatePage} />
            <Route exact path={paths.classPage(classroomId)} component={ClassPage} />
            <Route
                exact
                path={paths.classDetailPage(parameters.classroomId)}
                component={ClassDetailPage}
            />
            <Route
                exact
                path={paths.classEditPage(parameters.classroomId)}
                component={ClassEditPage}
            />

            {features.attendanceMarking && (
                <Route
                    exact
                    path={paths.classAttendance(parameters.classroomId)}
                    component={AttendancePage}
                />
            )}

            <Route
                path={paths.labPage(parameters.classroomId, parameters.labNumber)}
                component={LabPage}
            />
            <Route component={ErrorPage} />
        </Switch>
    );
};
