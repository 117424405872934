import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    surveyLinkHeader: {
        id: 'surveyLink.surveyLinkHeader',
        defaultMessage: 'Survey Link',
    },
    copyLinkLabel: {
        id: 'surveyLink.copyLinkLabel',
        defaultMessage: 'Copy survey URL',
    },
});

export default messages;
