import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { acceptInvitations } from '@/graphql/mutations';
import { executeRequest } from '@/modules/api';

import { useUserInfo } from './userInfo';

const acceptAllInvitations = async (stateSetter, errorSetter, context, collection = []) => {
    const acceptedInvitations = await executeRequest({
        operation: acceptInvitations,
        options: { context },
    })
        .then((response) => response?.acceptInvitations?.acceptedInvitations ?? [])
        .catch((err) => {
            errorSetter(err);
            return [];
        });

    if (acceptedInvitations.length) {
        acceptAllInvitations(stateSetter, errorSetter, context, [
            ...collection,
            ...acceptedInvitations,
        ]);
    }
    stateSetter(collection);
};

export const useAcceptInvitations = () => {
    const [acceptedInvitations, acceptedInvitationsSet] = useState(null);
    const { showBoundary } = useErrorBoundary();
    const userInfo = useUserInfo();

    useEffect(() => {
        acceptAllInvitations(
            acceptedInvitationsSet,
            showBoundary,
            { setOriginalSignInMethodName: userInfo?.setOriginalSignInMethodName },
            [],
        );
    }, [showBoundary, userInfo]);

    return acceptedInvitations;
};
