import 'moment/min/locales';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop } from '@/components';
import App from '@/containers/app/App';
import { configureAmplify } from '@/modules';

import { AppProviders } from './utils/Providers';

configureAmplify();

const Root = () => (
    <BrowserRouter>
        <ScrollToTop />
        <AppProviders>
            <App />
        </AppProviders>
    </BrowserRouter>
);

const root = createRoot(document.getElementById('root'));
root.render(<Root />);
