import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    noClasses: {
        id: 'studentRoster.noStudents',
        defaultMessage: 'No students to display',
    },
    headerStudentNumber: {
        id: 'studentRoster.headerStudentNumber',
        defaultMessage: 'Student number',
    },
    headerFirstName: {
        id: 'studentRoster.headerfirstName',
        defaultMessage: 'First name',
    },
    headerLastName: {
        id: 'studentRoster.headerLastName',
        defaultMessage: 'Last name',
    },
    headerEmail: {
        id: 'studentRoster.headerEmail',
        defaultMessage: 'Email',
    },
    headerStudentAttendance: {
        id: 'studentRoster.headerStudentAttendance',
        defaultMessage: 'Attendance',
    },
    headerProvider: {
        id: 'studentRoster.headerProvider',
        defaultMessage: 'Provider',
    },
    headerAccessControl: {
        id: 'studentRoster.headerAccessControl',
        defaultMessage: 'Access control',
    },
    accessControlButton: {
        id: 'studentRoster.accessControlButton',
        defaultMessage: 'Remove',
    },
    studentPresenceField: {
        id: 'studentRoster.studentPresenceField',
        defaultMessage: 'Present',
    },
    headerLabRegion: {
        id: 'studentRoster.labRegion',
        defaultMessage: 'Region',
    },
});
