import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    notificationDismissLabel: {
        id: 'AppNotification.notificationDismissLabel',
        defaultMessage: 'Dismiss',
    },
    'statusIconLabels-info': {
        id: 'AppNotification.statusIconLabels-info',
        defaultMessage: 'Info',
    },
    'statusIconLabels-success': {
        id: 'AppNotification.statusIconLabels-success',
        defaultMessage: 'Success',
    },
    'statusIconLabels-warning': {
        id: 'AppNotification.statusIconLabels-warning',
        defaultMessage: 'Warning',
    },
    'statusIconLabels-error': {
        id: 'AppNotification.statusIconLabels-error',
        defaultMessage: 'Error',
    },
    'statusIconLabels-in-progress': {
        id: 'AppNotification.statusIconLabels-in-progress',
        defaultMessage: 'In progress',
    },
});

export default messages;
