import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    courseTitle: {
        id: 'ClassTable.CourseTitle',
        defaultMessage: 'Course title',
    },
    courseTitleAriaLabel: {
        id: 'ClassTable.CourseTitleAriaLabel',
        description: 'Label used to distinguish multiple classes from the same course',
        defaultMessage: '{title} starting {startDate} and ending {endDate}',
    },

    tableAriaStatus: {
        id: 'ClassTable.TableAriaStatus',
        description: 'Summary of table data for announcing changes to screen reader',
        defaultMessage: 'Displaying items {firstIndex} to {lastIndex} of {totalItemsCount}',
    },

    startDate: {
        id: 'ClassTable.StartDate',
        defaultMessage: 'Start date',
    },

    endsOnDate: {
        id: 'ClassTable.EndDate',
        defaultMessage: 'End date',
    },

    deliveryMethod: {
        id: 'ClassTable.DeliveryMethod',
        defaultMessage: 'Delivery method',
    },

    instructorList: {
        id: 'ClassTable.InstructorAscending',
        defaultMessage: 'Instructor',
    },

    timezone: {
        id: 'ClassTable.Timezone',
        defaultMessage: 'Timezone',
    },

    country: {
        id: 'ClassTable.Country',
        defaultMessage: 'Country',
    },

    location: {
        id: 'ClassTable.Location',
        defaultMessage: 'Location',
    },

    createdBy: {
        id: 'ClassTable.CreatedBy',
        defaultMessage: 'Created by',
    },

    classListHeading: {
        id: 'ClassTable.classListHeading',
        description: 'List of classes to display to user',
        defaultMessage: 'Classes',
    },

    classListActiveUpcomingHeading: {
        id: 'ClassTable.classListActiveUpcomingHeading',
        description: 'Header when displaying active and upcoming classes',
        defaultMessage: 'Active & Upcoming Classes',
    },

    classListArchivedHeading: {
        id: 'ClassTable.classListArchivedHeading',
        description: 'Header when displaying archived classes',
        defaultMessage: 'Archived Classes',
    },

    virtualLocation: {
        id: 'ClassTable.virtual',
        description: 'Location to display when location is virtual',
        defaultMessage: 'Virtual',
    },
    physicalLocation: {
        id: 'ClassTable.physical',
        description: 'Location to display when location is physical',
        defaultMessage: 'In-person',
    },
    noClasses: {
        id: 'ClassTable.noClasses',
        description: 'When no classes are returned from the backend',
        defaultMessage: 'No classes',
    },
    noClassesToDisplay: {
        id: 'ClassTable.noClassesToDisplay',
        description: 'When no classes are returned from the backend',
        defaultMessage: 'No classes to display.',
    },
    viewAllActiveClasses: {
        id: 'ClassTable.viewAllActiveClasses',
        description: 'button text to view all active classes',
        defaultMessage: 'View all active classes',
    },
    viewAllFutureClasses: {
        id: 'ClassTable.viewAllFutureClasses',
        description: 'button text to view all upcoming classes',
        defaultMessage: 'View all upcoming classes',
    },
    loadingResources: {
        id: 'ClassTable.loadingResources',
        description: 'text describing the table loading data',
        defaultMessage: 'Loading resources',
    },
    classStatusLabel: {
        id: 'ClassTable.classStatus',
        description: 'Header text for the column showing whether a class is active or upcoming',
        defaultMessage: 'Class status',
    },
    classStatusActive: {
        id: 'ClassTable.classStatus.active',
        description: 'Class status for classes currently active or in progress',
        defaultMessage: 'Active',
    },
    classStatusUpcoming: {
        id: 'ClassTable.classStatus.upcoming',
        description: 'Class status for classes scheduled in future',
        defaultMessage: 'Upcoming',
    },
    classStatusArchived: {
        id: 'ClassTable.classStatus.archived',
        description: 'Class status for classes with end date past current time',
        defaultMessage: 'Archived',
    },
    classTimeLabel: {
        id: 'ClassTable.classTime',
        description: 'Header text for the column showing the class start time',
        defaultMessage: 'Time',
    },
    preferencesLabel: {
        id: 'ClassTable.preference.title',
        description: 'Header displayed when selecting table preferences',
        defaultMessage: 'Preferences',
    },
    preferencesPageSizeLabel: {
        id: 'ClassTable.preference.pageSize',
        description: 'Header shown in the table preference section for selecting page size',
        defaultMessage: 'Results per page',
    },
    preferencesConfirm: {
        id: 'ClassTable.preference.confirm',
        description: 'Button text to confirm selected preferences',
        defaultMessage: 'Confirm',
    },
    preferencesCancel: {
        id: 'ClassTable.preference.cancel',
        description: 'Button text to cancel selected preferences',
        defaultMessage: 'Cancel',
    },
    preferencesPageSizeOptionLabel: {
        id: 'ClassTable.preference.pageSizeOptionLabel',
        description: 'Label used to show page size option in preferences',
        defaultMessage: '{pageSize} results',
    },
    preferencesSelectVisibleColumnHeader: {
        id: 'ClassTable.preferences.selectVisibleColumnHeader',
        description: 'Header for selecting visible classroom columns',
        defaultMessage: 'Classroom properties',
    },
    preferencesSelectVisibleColumnLabel: {
        id: 'ClassTable.preferences.selectVisibleColumnLabel',
        description: 'Label for selecting visible classroom columns',
        defaultMessage: 'Select visible columns',
    },

    /**
     * strings for filtering
     */
    filterPlaceholder: {
        id: 'ClassTable.filter.placeholder',
        description:
            'Placeholder text displayed on filter input when actual filter text for searching active and upcoming classes is empty',
        defaultMessage: 'Search active and upcoming classes',
    },
    filterPlaceholderForArchived: {
        id: 'ClassTable.filter.placeholderArchived',
        description:
            'Placeholder text displayed on filter input when filter text for searching archived classes is empty',
        defaultMessage: 'Search archived classes',
    },
    filterOperationAnd: {
        id: 'ClassTable.filter.operationAnd',
        description: 'Text for logical operator AND',
        defaultMessage: 'and',
    },
    filterOperatorsText: {
        id: 'ClassTable.filter.operatorsText',
        description: 'Text for Operators label',
        defaultMessage: 'Operators',
    },
    filterOperatorLessText: {
        id: 'ClassTable.filter.operatorLessText',
        description: 'Text for Less than label',
        defaultMessage: 'Less than',
    },
    filterOperatorLessOrEqualText: {
        id: 'ClassTable.filter.operatorLessOrEqualText',
        description: 'Text for Less than or equal label',
        defaultMessage: 'Less than or equal',
    },
    filterOperatorGreaterText: {
        id: 'ClassTable.filter.operatorGreaterText',
        description: 'Text for Greater than label',
        defaultMessage: 'Greater than',
    },
    filterOperatorGreaterOrEqualText: {
        id: 'ClassTable.filter.operatorGreaterOrEqualText',
        description: 'Text for Greater than or equal label',
        defaultMessage: 'Greater than or equal',
    },
    filterOperatorContainsText: {
        id: 'ClassTable.filter.operatorContainsText',
        description: 'Text for Contains label',
        defaultMessage: 'Contains',
    },
    filterOperatorDoesNotContainText: {
        id: 'ClassTable.filter.operatorDoesNotContainText',
        description: 'Text for Does not contain label',
        defaultMessage: 'Does not contain',
    },
    filterOperatorEqualsText: {
        id: 'ClassTable.filter.operatorEqualsText',
        description: 'Text for Equals label',
        defaultMessage: 'Equals',
    },
    filterOperatorDoesNotEqualText: {
        id: 'ClassTable.filter.operatorDoesNotEqualText',
        description: 'Text for Does not equal label',
        defaultMessage: 'Does not equal',
    },
    filterPropertyText: {
        id: 'ClassTable.filter.propertyText',
        description: 'Text for Property label',
        defaultMessage: 'Property',
    },
    filterOperatorText: {
        id: 'ClassTable.filter.operatorText',
        description: 'Text for Operator label',
        defaultMessage: 'Operator',
    },
    filterValueText: {
        id: 'ClassTable.filter.valueText',
        description: 'Text for Value label',
        defaultMessage: 'Value',
    },

    filterOperationOr: {
        id: 'ClassTable.filter.operationOr',
        description: 'Text for logical operator OR',
        defaultMessage: 'or',
    },

    filterAllPropertiesLabel: {
        id: 'ClassTable.filter.allPropertiesLabel',
        description: 'Label for All properties button',
        defaultMessage: 'All properties',
    },
    filterDismissAriaLabel: {
        id: 'ClassTable.filter.dismissAriaLabel',
        description: 'Label for Dismiss button',
        defaultMessage: 'Dismiss',
    },
    filterEditTokenHeader: {
        id: 'ClassTable.filter.editTokenHeader',
        description: 'Label for Edit filter button',
        defaultMessage: 'Edit filter',
    },
    filterCancelActionText: {
        id: 'ClassTable.filter.cancelActionText',
        description: 'Label for Cancel button',
        defaultMessage: 'Cancel',
    },
    filterApplyActionText: {
        id: 'ClassTable.filter.applyActionText',
        description: 'Label for Apply button',
        defaultMessage: 'Apply',
    },

    filterEnteredTextLabel: {
        id: 'ClassTable.filter.enteredText',
        description: 'Label to use what is already typed',
        defaultMessage: 'Use: {text}',
    },

    filterClear: {
        id: 'ClassTable.filter.clear',
        description: 'Label for the clear button',
        defaultMessage: 'Clear filters',
    },

    filterRemoveToken: {
        id: 'ClassTable.filter.removeToken',
        description:
            'Label used for the button to remove an active filter token from property filter',
        defaultMessage: 'Remove filtering token for {filterProperty} with value {filterValue}',
    },

    filterTokenLimitShowMore: {
        id: 'ClassTable.filter.tokenLimitShowMore',
        description: 'Label for Show more button',
        defaultMessage: 'Show more',
    },
    filterTokenLimitShowFewer: {
        id: 'ClassTable.filter.tokenLimitShowFewer',
        description: 'Label for Show fewer button',
        defaultMessage: 'Show fewer',
    },

    filterGroupProperty: {
        id: 'ClassTable.filter.groupProperty',
        description: 'Header displayed on the filter UI on the filter group section',
        defaultMessage: 'Filter by',
    },
    filterGroupValue: {
        id: 'ClassTable.filter.groupValue',
        description: 'Header displayed on the filter UI on the properties value section',
        defaultMessage: 'Values',
    },

    filterLabel: {
        id: 'ClassTable.filter.label',
        description: 'Label that will be passed down to the Autosuggest label property',
        defaultMessage: 'Filter classes',
    },

    /**
     * strings for filtering, specifically for date range picker
     */
    filterDateToday: {
        id: 'ClassTable.filter.dateToday',
        description: 'Label for date picker UI for today button',
        defaultMessage: 'Today',
    },
    filterDateUnitSecond: {
        id: 'ClassTable.filter.dateUnitSecond',
        description: 'Unit of time for a single second (singular)',
        defaultMessage: 'second',
    },
    filterDateUnitSeconds: {
        id: 'ClassTable.filter.dateUnitSecondss',
        description: 'Unit of time for seconds (plural)',
        defaultMessage: 'seconds',
    },
    filterDateUnitMinute: {
        id: 'ClassTable.filter.dateUnitMinute',
        description: 'Unit of time for a single minute (singular)',
        defaultMessage: 'minute',
    },
    filterDateUnitMinutes: {
        id: 'ClassTable.filter.dateUnitMinutes',
        description: 'Unit of time for minutes (plural)',
        defaultMessage: 'minutes',
    },
    filterDateUnitHour: {
        id: 'ClassTable.filter.dateUnitHour',
        description: 'Unit of time for a single hour (singular)',
        defaultMessage: 'hour',
    },
    filterDateUnitHours: {
        id: 'ClassTable.filter.dateUnitHours',
        description: 'Unit of time for hours (plural)',
        defaultMessage: 'hours',
    },
    filterDateUnitDay: {
        id: 'ClassTable.filter.dateUnitDay',
        description: 'Unit of time for a single day (singular)',
        defaultMessage: 'day',
    },
    filterDateUnitDays: {
        id: 'ClassTable.filter.dateUnitDays',
        description: 'Unit of time for days (plural)',
        defaultMessage: 'days',
    },
    filterDateUnitWeek: {
        id: 'ClassTable.filter.dateUnitWeek',
        description: 'Unit of time for a single week (singular)',
        defaultMessage: 'week',
    },
    filterDateUnitWeeks: {
        id: 'ClassTable.filter.dateUnitWeeks',
        description: 'Unit of time for weeks (plural)',
        defaultMessage: 'weeks',
    },
    filterDateUnitMonth: {
        id: 'ClassTable.filter.dateUnitMonth',
        description: 'Unit of time for a single month (singular)',
        defaultMessage: 'month',
    },
    filterDateUnitMonths: {
        id: 'ClassTable.filter.dateUnitMonths',
        description: 'Unit of time for months (plural)',
        defaultMessage: 'months',
    },
    filterDateUnitYear: {
        id: 'ClassTable.filter.dateUnitYear',
        description: 'Unit of time for a single year (singular)',
        defaultMessage: 'year',
    },
    filterDateUnitYears: {
        id: 'ClassTable.filter.dateUnitYears',
        description: 'Unit of time for weeks (plural)',
        defaultMessage: 'years',
    },
    filterDateNextUnit: {
        id: 'ClassTable.filter.dateNextPeriod',
        description: 'Text indicating a single next period i.e. next 1 week',
        defaultMessage: 'next {unitOfTime}',
    },
    filterDateNextUnits: {
        id: 'ClassTable.filter.dateNextPeriods',
        description: 'Text indicating next multi period i.e. next 2 weeks',
        defaultMessage: 'next {numPeriod} {unitOfTime}',
    },
    filterDateActiveAndToday: {
        id: 'ClassTable.filter.dateActiveAndToday',
        description: 'Label for date picker UI for active and starting today button',
        defaultMessage: 'active and starting today',
    },
    filterDateActiveAndNextUnit: {
        id: 'ClassTable.filter.dateActiveAndNextPeriod',
        description:
            'Label indicating a duration including active classes i.e. active and starting within 1 week',
        defaultMessage: 'Active and starting within next {unitOfTime}',
    },
    filterDateActiveAndNextUnits: {
        id: 'ClassTable.filter.dateActiveAndNextPeriods',
        description:
            'Label indicating a duration consisting in 1+ numPeriod including active classes i.e. active and starting within 2 weeks',
        defaultMessage: 'Active and starting within next {numPeriod} {unitOfTime}',
    },
    filterDatePreviousUnit: {
        id: 'ClassTable.filter.datePreviousPeriod',
        description: 'Label indicating a single previous period i.e. last 1 week',
        defaultMessage: 'last {unitOfTime}',
    },
    filterDatePreviousUnits: {
        id: 'ClassTable.filter.datePreviousPeriods',
        description: 'Label indicating previous multi period i.e. last 2 weeks',
        defaultMessage: 'last {numPeriod} {unitOfTime}',
    },
    filterDateRangeInvalidText: {
        id: 'ClassTable.filter.filterDateRangeInvalidText',
        description:
            'Text describing the date range constraint i.e. Please select a range within -1 year and 0 days',
        defaultMessage: 'Please select a valid range between {start} and {end}',
    },
    filterInvalidDate: {
        id: 'ClassTable.filter.filterInvalidDate',
        description: 'Error message shown to user when invalid input is entered',
        defaultMessage: 'Invalid date format. Please enter date as {dateFormat}',
    },
    filterInvalidFreeTextFilter: {
        id: 'ClassTable.filter.filterInvalidFreeTextFilter',
        description:
            'Error message shown when user has entered some text without selecting a field to filter by and input cannot be handled',
        defaultMessage: 'Please select a Filter by field then select or enter a value',
    },
    filterEnterValidDateWithinRange: {
        id: 'ClassTable.filter.filterEnterValidDateWithinRange',
        description:
            'Text shown when user entered dates outside valid ranges and prompt to enter correct date',
        defaultMessage: 'Date is out of range. Please enter a date between {start} and {end}',
    },
    filterInvalidDateRange: {
        id: 'ClassTable.filter.filterInvalidDateRange',
        description:
            'Error message shown when user has entered some text without selecting a field to filter by and input cannot be handled',
        defaultMessage: 'Please select a Filter by field and select or enter a value',
    },
    filterInvalidInputText: {
        id: 'ClassTable.filter.filterInvalidInputText',
        description: 'Error message shown when user input has invalid characters',
        defaultMessage: 'Filter value entered is not valid',
    },
    filterInvalidInputDetailedText: {
        id: 'ClassTable.filter.filterInvalidInputDetailedText',
        description: 'Error message shown when user input has invalid characters',
        defaultMessage:
            'Filter value entered for {field} is invalid. {restrictedChars} are not allowed',
    },
    filterInvalidNamedInputText: {
        id: 'ClassTable.filter.filterInvalidNamedInputText',
        description: 'Error message shown when user input has invalid characters',
        defaultMessage: 'Filter value entered for {field} is not valid',
    },
    filterInvalidEmailInputText: {
        id: 'ClassTable.filter.filterInvalidEmailInputText',
        description: 'Error message shown when user input has invalid email',
        defaultMessage:
            'Filter value entered for {field} is not a valid email address. Please enter a valid email address',
    },
    filterInvalidCountryText: {
        id: 'ClassTable.filter.filterInvalidCountryText',
        description: 'Error message shown when user entered wrong country code',
        defaultMessage: 'Unsupported country code. Please enter a valid alpha 3 country code.',
    },
});
