import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'SplitLoginPage.pageTitle',
        description: 'SplitLoginPage page meta title',
        defaultMessage: 'Navigate to original sign-in method - AWS Training and Certification',
    },
    title: {
        id: 'SplitLoginPage.title',
        description: 'SplitLoginPage page title',
        defaultMessage: 'Please continue with your original sign-in method',
    },
    mainMessage: {
        id: 'SplitLoginPage.mainParagraph',
        description: 'SplitLoginPage page main message',
        defaultMessage:
            'We have detected you are attempting to sign-in via <bold>{currentSignInMethod}</bold>. We currently only support one sign-in method per email. Your email, <bold>{userEmail}</bold>, is already associated with <bold>{originalSignInMethod}</bold>. Please click the button below to sign in with the original, <bold>{originalSignInMethod}</bold>.',
    },
    buttonRedirect: {
        id: 'SplitLoginPage.buttonRedirect',
        description: 'SplitLoginPage page button to redirect',
        defaultMessage: 'Continue with {signInMethod}',
    },
});

export default messages;
