import { Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';

import { EditProvisionPoolModal } from '@/components/provisionPoolModal';
import { useFlags } from '@/utils';

import messages from './index.messages';

import './index.scss';

interface LabPoolDetailsProps {
    provisioning: number;
    unassigned: number;
    assigned: number;
    ended: number;
    failures: number;
}

export const LabPoolDetails = ({
    provisioning,
    unassigned,
    assigned,
    ended,
    failures,
}: LabPoolDetailsProps) => {
    const flags = useFlags();
    const { formatMessage } = useIntl();

    if (!flags.pooledLabs) return null;

    const poolData = [
        {
            label: formatMessage(messages.labPoolDetailsProvisioning),
            value: provisioning,
        },
        {
            label: formatMessage(messages.labPoolDetailsUnassigned),
            value: unassigned,
        },
        {
            label: formatMessage(messages.labPoolDetailsAssigned),
            value: assigned,
        },
        {
            label: formatMessage(messages.labPoolDetailsEnded),
            value: ended,
        },
        {
            label: formatMessage(messages.labPoolDetailsFailure),
            value: failures,
        },
    ];

    return (
        <Box>
            <SpaceBetween direction='horizontal' size='m' data-styles='space-between'>
                <Box variant='h3'>{formatMessage(messages.labPoolDetailsTitle)}</Box>
                <EditProvisionPoolModal region='us-west-2' maxLabs={10} />
            </SpaceBetween>
            <dl className='lab-pool-details__data'>
                <SpaceBetween direction='horizontal' size='xl'>
                    {poolData.map(({ label, value }) => (
                        <div key={label}>
                            <dt>{label}</dt>
                            <dd>{value}</dd>
                        </div>
                    ))}
                </SpaceBetween>
            </dl>
        </Box>
    );
};
