import { redirectToLoginPage } from '@/modules';
import { userRequiresReset } from '@/modules/api';
import { getCurrentUser } from '@/utils/helpers';

const findAuthZVersionValue = (attributes) => {
    for (var key in attributes) {
        if (
            // Need to allow a custom key for cognito, as there is a character limit
            (key.startsWith('aws/learningclassrooms/') || key.startsWith('custom')) &&
            key.endsWith(':authZ_version')
        ) {
            return attributes[key];
        }
    }
    return undefined;
};

export const checkResourceConsolidation = async () => {
    let hasResourcesConsolidated = false;
    let isRedirecting = false;
    const authZVersion = findAuthZVersionValue(await getCurrentUser());
    if (authZVersion && Number(authZVersion) >= 2) {
        hasResourcesConsolidated = true;
    } else {
        const resetRequired = await userRequiresReset();
        if (!resetRequired) {
            redirectToLoginPage(false);
            isRedirecting = true;
        }
    }
    return {
        hasResourcesConsolidated,
        isRedirecting,
    };
};
