import { Modal, Button, Box, TextContent, SpaceBetween } from '@amzn/awsui-components-react-v3';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

import { messages as genericMessages } from '@/components/classForm/ClassForm.messages';

import componentMessages from './EndLabsModal.messages';

const {
    modalFooterEndLabLabel,
    modalFooterEndLabPluralLabel,
    modalHeaderTitle,
    modalBodyHeading,
    modalBodyHeadingAll,
    modalBodyMainContent,
} = componentMessages;

const ModalFooter = ({ onConfirm, onCancel, numberOfStudents }) => {
    const { formatMessage } = useIntl();
    return (
        <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='normal' data-testid='end-labs-modal-cancel' onClick={onCancel}>
                    {formatMessage(genericMessages.cancelButtonText)}
                </Button>
                <Button variant='primary' data-testid='end-labs-modal-confirm' onClick={onConfirm}>
                    {formatMessage(
                        numberOfStudents === 1
                            ? modalFooterEndLabLabel
                            : modalFooterEndLabPluralLabel,
                    )}
                </Button>
            </SpaceBetween>
        </Box>
    );
};

const EndLabsModal = ({
    labTitle,
    endDate,
    onConfirm,
    onCancel,
    visible,
    numberOfStudents,
    classCapacity,
}) => {
    const { formatMessage, formatDate, formatTime } = useIntl();
    const endDateMoment = moment.unix(endDate).valueOf();
    const endDateFormatted = `${formatDate(endDateMoment)} ${formatTime(endDateMoment)}`;
    const bodyHeadingMessage =
        numberOfStudents && numberOfStudents < classCapacity
            ? modalBodyHeading
            : modalBodyHeadingAll;
    return (
        <Modal
            onDismiss={onCancel}
            visible={visible}
            data-testid='end-labs-modal'
            footer={<ModalFooter {...{ onConfirm, onCancel, numberOfStudents }} />}
            header={<>{formatMessage(modalHeaderTitle)}</>}
        >
            <TextContent>
                <p>
                    <strong>{labTitle}</strong>
                    <br />
                    {formatMessage(bodyHeadingMessage, { num: numberOfStudents })}
                </p>
                <p>{formatMessage(modalBodyMainContent, { endDate: endDateFormatted })}</p>
            </TextContent>
        </Modal>
    );
};

export default EndLabsModal;
