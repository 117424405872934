import { Box } from '@amzn/awsui-components-react-v3';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { paths } from '@/utils';

import messages from './ErrorPage.messages';

const ErrorPage = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Box padding='l' textAlign='center'>
                <Box variant='h1'>{formatMessage(messages.pageHeading)}</Box>
                <h2>{formatMessage(messages.pageNotFound)}</h2>
                <p>{formatMessage(messages.pageAdvice)}</p>
                <Link to={paths.landingPage}>{formatMessage(messages.pageLink)}</Link>
            </Box>
        </>
    );
};

export default ErrorPage;
