import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ClassAttendancePage.pageTitle',
        description: 'Attendance page title',
        defaultMessage: 'Class Attendance - AWS Training and Certification',
    },
    breadcrumbLabel: {
        id: 'ClassAttendancePage.breadcrumbLabel',
        description: 'Label for the breadcrumb',
        defaultMessage: 'Attendance',
    },
    loadingLearningActivity: {
        id: 'ClassAttendancePage.loadingLearningActivity',
        description: 'Label to display while loading learning activity',
        defaultMessage: 'Loading...',
    },
});

export default messages;
