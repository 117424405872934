import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    ongoingPlannedHeader: {
        id: 'gilmoreHealthBanner.ongoingPlanned.header',
        description: 'Alert header for ongoing planned',
        defaultMessage: 'Maintenance underway',
    },
    ongoingPlannedText: {
        id: 'gilmoreHealthBanner.ongoingPlanned.text',
        description: 'Main text for ongoing planned',
        defaultMessage:
            'You may not change the number of students in a class until {time} on {date}.',
    },
    upcomingPlannedHeader: {
        id: 'gilmoreHealthBanner.upcomingPlanned.header',
        description: 'Alert header for upcoming planned',
        defaultMessage: 'Maintenance starting soon',
    },
    upcomingTodayPlannedHeader: {
        id: 'gilmoreHealthBanner.upcomingTodayPlanned.header',
        description: 'Alert header for event starting in the same calendar day',
        defaultMessage: 'Maintenance starting today',
    },
    upcomingTomorrowPlannedHeader: {
        id: 'gilmoreHealthBanner.upcomingTomorrowPlanned.header',
        description: 'Alert header for event starting tomorrow',
        defaultMessage: 'Maintenance starting tomorrow',
    },
    upcomingSingleDayPlannedText: {
        id: 'gilmoreHealthBanner.upcomingSingleDayPlannedText.text',
        description:
            'Messaging for upcoming outage when the event does not span multiple calendar day',
        defaultMessage:
            'You won’t be able to change the number of students in a class on {date} from {startTime} - {endTime}',
    },
    upcomingPlannedText: {
        id: 'gilmoreHealthBanner.upcomingPlanned.text',
        description: 'Main text for upcoming planned',
        defaultMessage:
            'You won’t be able to change the number of students in a class from {startTime} - {endTime}',
    },
    unplannedText: {
        id: 'gilmoreHealthBanner.unplanned.text',
        description: 'Main text for unplanned outage',
        defaultMessage:
            'You may not make changes to class capacity right now. We’re working to fix it. Please check back later.',
    },
});

export default messages;
