import { Spinner } from '@amzn/awsui-components-react-v3';

import './Loader.css';

const Loader = ({ isLoading, hasError, fallback, children, message, backgroundColor }) => {
    if (hasError) {
        return fallback ? fallback() : null;
    }

    return isLoading ? (
        <div className='loading-wrapper' data-testid='loader' style={{ backgroundColor }}>
            {message ? <p>{message}</p> : null}
            <Spinner size='large' />
        </div>
    ) : (
        children
    );
};

export default Loader;
