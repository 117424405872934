import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    manageLabsBtnLabel: {
        id: 'labCard.btn.label',
        defaultMessage: 'Manage Student Labs',
    },
    manageLabsBtnAriaLabel: {
        id: 'labCard.btn.ariaLabel',
        defaultMessage: 'Manage Student Labs - {title}',
    },
    manageLabsBtnAriaIndex: {
        id: 'labCard.btn.label.index',
        defaultMessage: 'Lab {index}',
    },
    jamCardLabel: {
        id: 'jamCard.label',
        defaultMessage: 'AWS Jam Event - {courseTitle}',
    },
    jamCardDescription: {
        id: 'jamCard.description',
        defaultMessage:
            'In this event, you are going to put your AWS skills to the test by solving various challenges.',
    },
    jamCardLinkLabel: {
        id: 'jamCard.link.label',
        defaultMessage: 'AWS Jam URL',
    },
    jamCardFacilitateLabel: {
        id: 'jamCard.facilitateBtn.label',
        defaultMessage: 'Facilitate event',
    },
});

export default messages;
