const generateClassroomData = ({
    startsOn = 1626900050,
    courseId = 'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-ARCHIT-6:6.8.24-725e0c7f',
    langLocale = 'en-US',
    locationType = 'physical',
    timezone = 'America/Detroit',
    country = 'USA',
}) => ({
    classroomArn: 'arn:aws:learningcontent:us-west-2:classroom/msAUS2JSoNDHeJefWZjyCJ',
    startsOn,
    langLocale,
    courseId,
    locationData: {
        locationType,
        timezone,
        physicalAddress: {
            country,
        },
    },
});

const bigDataCourseId =
    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-BIGDAT-3:3.9.11-2c92dd72';
const other =
    'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-BF-100-MSMEDM-1:9M5XbwwEQ7XHhnxqet97zT';

const mockClassList = [
    generateClassroomData({
        startsOn: 1626900050,
        timezone: 'America/Regina',
        langLocale: 'es-ES',
    }),
    generateClassroomData({
        startsOn: 1575406801,
        timezone: 'America/Regina',
        courseId: bigDataCourseId,
    }),
    generateClassroomData({ startsOn: 1575406802, timezone: 'America/Regina', country: 'VEN' }),
    generateClassroomData({
        startsOn: 1575406803,
        timezone: 'America/Regina',
        country: 'GBR',
    }),
    generateClassroomData({ startsOn: 1575406804, courseId: bigDataCourseId }),
    generateClassroomData({ startsOn: 1575406805, courseId: bigDataCourseId }),
    generateClassroomData({ locationType: 'virtual', startsOn: 1575406806, country: null }),
    generateClassroomData({
        locationType: 'virtual',
        country: null,
        startsOn: 1575406807,
    }),
    generateClassroomData({ locationType: 'virtual', startsOn: 1575406808, country: null }),
    generateClassroomData({
        locationType: 'virtual',
        country: null,
        startsOn: 1575406809,
        courseId: other,
    }),
    generateClassroomData({
        locationType: 'virtual',
        country: 'JPN',
        startsOn: 1575406800,
        courseId: bigDataCourseId,
        langLocale: 'ja-JP',
    }),
    generateClassroomData({ locationType: 'virtual', startsOn: 1575406801, country: null }),
];

export default mockClassList;
