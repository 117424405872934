import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    dayOf: {
        id: 'NavigationDropdown.dayOf',
    },
    labsHeaderLabel: {
        id: 'labList.header.label',
        defaultMessage:
            'Manage class day-of settings such as lab availability. A maximum of 3 labs can be available or loaded.',
    },
    jamTitle: {
        id: 'labList.jam.title',
        defaultMessage: 'Jams',
    },
    jamHeaderLabel: {
        id: 'labList.jam.header.label',
        defaultMessage: 'Activate the Jam event to make it available for students to join.',
    },
});

export default messages;
