import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    noUpdateTrainingHeader: {
        id: 'LabToggle.modal.noUpdateTrainingHeader',
        description:
            'Header text in modal when encountering an error updating training permission=',
        defaultMessage: 'Lab not updated',
    },
    updateTrainingPermissions403Error: {
        id: 'LabToggle.modal.updateTraining403Error',
        description:
            'The error body when a user encounters 403 when activating or deactivating a lab',
        defaultMessage: "You don't have access to update labs",
    },
    noUpdateTrainingPermissions: {
        id: 'LabToggle.modal.noUpdateTrainingError',
        description: "The fallback error body when a training wasn't updated",
        defaultMessage: 'We were not able to update this lab, please try again',
    },
    closeModalButtonLabel: {
        id: 'LabToggle.modal.closeButtonLabel',
        defaultMessage: 'Close dialog',
    },
    active: {
        id: 'LabToggle.Lab.active',
        description: 'The text to display for an active lab',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'LabToggle.Lab.inactive',
        description: 'The text to display for an inactive lab',
        defaultMessage: 'Inactive',
    },
    deactivateLab: {
        id: 'LabToggle.toggle.deactivate',
        defaultMessage: 'Deactivate lab {index}',
    },
    deactivateJam: {
        id: 'LabToggle.toggle.deactivateJam',
        defaultMessage: 'Deactivate Jam {index}',
    },
    activateLab: {
        id: 'LabToggle.toggle.activate',
        defaultMessage: 'Activate lab {index}',
    },
    activateJam: {
        id: 'LabToggle.toggle.activateJam',
        defaultMessage: 'Activate Jam {index}',
    },
});

export default messages;
