import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ClassEditPage.pageTitle',
        description: 'Class edit page title',
        defaultMessage: 'Edit class - AWS Training and Certification',
    },
    notUpdatedHeader: {
        id: 'ClassEditPage.modal.notUpdatedHeader',
        description: 'header message for when class is unable to be updated',
        defaultMessage: 'Class data not updated',
    },
    notUpdatedContent: {
        id: 'ClassEditPage.modal.notUpdatedContent',
        description: 'body message for when class is unable to be updated',
        defaultMessage: 'Class data was not updated, try again.',
    },
    updateClass409Error: {
        id: 'ClassEditPage.modal.updateClass409Error',
        description: 'body message for when class data is not updated due to a 409 error',
        defaultMessage: 'We are having trouble processing the request, try again later',
    },
    closeModalButtonLabel: {
        id: 'ClassEditPage.modal.closeButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default messages;
