import { useContext } from 'react';
import { useQuery } from 'react-query';

import { GilmoreHealthContext } from '@/data/GilmoreHealthContext';
import { getScheduledEvents } from '@/modules';
import { useFlags } from '@/utils/flags';

import { useProvider } from './ProviderContext';

export const useGilmoreEvents = () => {
    const flags = useFlags();
    const { gilmoreEventDataSet, ...rest } = useContext(GilmoreHealthContext);

    const provider = useProvider();
    const isPartner = provider?.type !== 'DIRECT';
    const checkForScheduledEvents = isPartner && flags?.scheduledEvents;
    const queryFn = () => (checkForScheduledEvents ? getScheduledEvents(provider.arn) : []);
    useQuery([getScheduledEvents, provider, 'getScheduledEvents'], queryFn, {
        refetchOnWindowFocus: true,
        onSuccess: gilmoreEventDataSet,
        onError: console.error,
    });

    return rest;
};
