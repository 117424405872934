import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    warningLabel: {
        id: 'alert.warningLabel',
        defaultMessage: 'Warning',
    },
    errorLabel: {
        id: 'alert.errorLabel',
        defaultMessage: 'Error',
    },
    infoLabel: {
        id: 'alert.infoLabel',
        defaultMessage: 'Info',
    },
    breadcrumbsLabel: {
        id: 'alert.breadcrumbsLabel',
        defaultMessage: 'Breadcrumbs',
    },
    successLabel: {
        id: 'alert.successLabel',
        defaultMessage: 'Success',
    },
});
