import { Button } from '@amzn/awsui-components-react-v3';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { copyToClipboard } from '@/utils/helpers';

import detailPageMessages from '../../containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import accessCodeMessages from '../accessCodeTable/AccessCodeTable.messages';

export const CopyButton = ({ children, label }) => {
    const COPY_BUTTON_TIMEOUT_MS = 3000;
    const { formatMessage } = useIntl();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let id;
        if (copied) id = setTimeout(() => setCopied(false), COPY_BUTTON_TIMEOUT_MS);
        return () => clearTimeout(id);
    }, [copied]);

    return (
        <Button
            variant='normal'
            wrapText={false}
            onClick={() =>
                copyToClipboard({
                    globals: window,
                    value: children,
                    onSuccess: () => setCopied(true),
                })
            }
        >
            <span aria-live='polite'>
                {copied
                    ? formatMessage(accessCodeMessages.codeCopied)
                    : formatMessage(label ?? detailPageMessages.copyToClipboard)}
            </span>
        </Button>
    );
};
