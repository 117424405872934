import { defineMessages } from 'react-intl.macro';

const selectReportModalMessages = defineMessages({
    modalHeader: {
        id: 'SelectReportModal.modalHeader',
        defaultMessage: 'Select a report',
    },
    modalContent: {
        id: 'SelectReportModal.modalContent',
        defaultMessage: 'Select the report you wish to download.',
    },
    closeButtonText: {
        id: 'SelectReportModal.closeButtonText',
        defaultMessage: 'Close',
    },
    dismissButtonLabel: {
        id: 'SelectReportModal.dismissButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default selectReportModalMessages;
