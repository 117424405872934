import { Alert, Modal, Box, Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import throttle from 'lodash/throttle';
import { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { messages as utilMessages } from '@/index.messages';

import { messages } from './RemoveStudentModal.messages';

const THROTTLE_MS = 500;

const RemoveStudentModal = ({
    showRemoveStudentModal,
    setShowRemoveStudentModal,
    removeStudentFromRoster,
    showBtnSpinner,
    email,
    isRemovingAcceptedStudent,
}) => {
    const { formatMessage } = useIntl();
    const removeBtnHandler = useMemo(
        () => throttle(() => removeStudentFromRoster(email), THROTTLE_MS),
        [email, removeStudentFromRoster],
    );
    useEffect(
        () => () => {
            removeBtnHandler.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    return (
        <Modal
            onDismiss={() => setShowRemoveStudentModal(false)}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
            data-testid='remove-student-roster-modal'
            visible={showRemoveStudentModal}
            header={formatMessage(messages.headerLabel)}
            footer={
                <div>
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                onClick={() => setShowRemoveStudentModal(false)}
                                variant='normal'
                            >
                                {formatMessage(messages.closeModalButtonText)}
                            </Button>
                            <Button
                                data-testid={'confirm-remove'}
                                onClick={removeBtnHandler}
                                loading={showBtnSpinner}
                                variant='primary'
                            >
                                {formatMessage(messages.removeStudentModalButtonText)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                </div>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                {isRemovingAcceptedStudent && (
                    <Alert
                        statusIconAriaLabel={formatMessage(utilMessages.warningLabel)}
                        type='warning'
                    >
                        {formatMessage(messages.removeAcceptedStudentAlert, {
                            email,
                            bold: (str) => <b>{str}</b>,
                        })}
                    </Alert>
                )}
                {formatMessage(messages.removeStudentModalBody, { email })}
            </SpaceBetween>
        </Modal>
    );
};

export default RemoveStudentModal;
