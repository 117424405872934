import { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import messages from '@/utils/appNotifications/index.messages';

import type { FlashbarProps } from '@amzn/awsui-components-react-v3';
import type { PropsWithChildren } from 'react';

interface Notification extends FlashbarProps.MessageDefinition {
    route: string;
    update: (updates: Partial<FlashbarProps.MessageDefinition>) => void;
    onDismiss: () => void;
}

interface AppNotificationsState {
    currentNotifications: Notification[];
    addNotification: (notification: FlashbarProps.MessageDefinition) => void;
}

type NotificationInput = Pick<
    Notification,
    'header' | 'content' | 'dismissible' | 'loading' | 'type' | 'id'
>;

export const AppNotificationsCtx = createContext<AppNotificationsState>({
    currentNotifications: [],
    addNotification: () => {},
});

export const AppNotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
    const [allNotifications, allNotificationsSet] = useState<Notification[]>([]);
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();

    const updateNotification =
        (index: number) => (updates: Partial<FlashbarProps.MessageDefinition>) => {
            allNotificationsSet((current) => {
                return [
                    ...current.slice(0, index),
                    supplementNotificationProps({ ...current[index], ...updates }),
                    ...current.slice(index + 1, current.length),
                ].map(bindHandlers);
            });
        };

    const dismissNotification = (index: number) => () => {
        allNotificationsSet((current) => {
            return current.slice(0, index).concat(current.slice(index + 1, current.length));
        });
    };

    const bindHandlers = (
        notification: Omit<Notification, 'update' | 'onDismiss'>,
        index: number,
    ): Notification => ({
        ...notification,
        update: updateNotification(index),
        onDismiss: dismissNotification(index),
    });

    const supplementNotificationProps = (
        input: NotificationInput,
    ): Omit<Notification, 'update' | 'onDismiss'> => {
        const { type = 'info', dismissible = true, loading = false } = input;
        return {
            ...input,
            dismissible,
            type,
            loading,
            route: pathname,
            dismissLabel: formatMessage(messages.notificationDismissLabel),
            statusIconAriaLabel: formatMessage(messages[`statusIconLabels-${type}`]),
            ariaRole: type === 'error' ? 'alert' : 'status',
        };
    };

    const addNotification = (newNotification: NotificationInput) =>
        allNotificationsSet((current) => {
            return [supplementNotificationProps(newNotification), ...current].map(
                (notification, index) => bindHandlers(notification, index),
            );
        });

    const value: AppNotificationsState = {
        currentNotifications: allNotifications.filter(({ route }) => route === pathname),
        addNotification,
    };

    return <AppNotificationsCtx.Provider value={value}>{children}</AppNotificationsCtx.Provider>;
};

export const useAppNotifications = () => useContext(AppNotificationsCtx);
