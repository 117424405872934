import { Badge, Spinner } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';

import WidgetContainer from '@/components/widgetContainer/WidgetContainer';
import * as JAM_STATUSES from '@/utils/jam-status-definitions';

import messages from './JamStatusWidget.messages';
import { getStatusTranslationMessageBase } from './JamStatusWidget.utils';

const { eventStatusLabel } = messages;

const getStatusBadgeColor = (status) => {
    switch (status) {
        case JAM_STATUSES.JAM_EVENT_ONGOING:
            return 'green';
        case JAM_STATUSES.JAM_EVENT_ENDED:
            return 'blue';
        case JAM_STATUSES.JAM_EVENT_ERROR:
            return 'red';
        default:
            return 'grey';
    }
};

/**
 * A widget to display the status of a Jam training.
 * @param status - the Jam's status
 */
const JamStatusWidget = ({ status }) => {
    const { formatMessage } = useIntl();
    const getStatusTranslationMessage = getStatusTranslationMessageBase(formatMessage);
    return (
        <WidgetContainer title={formatMessage(eventStatusLabel)}>
            <>
                <Badge color={getStatusBadgeColor(status)}>
                    {getStatusTranslationMessage(status)}
                </Badge>
                {status === JAM_STATUSES.JAM_EVENT_PENDING ? (
                    <Spinner size='normal' data-testid='jam-spinner' />
                ) : null}
            </>
        </WidgetContainer>
    );
};

export default JamStatusWidget;
