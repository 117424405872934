import { defineMessages } from 'react-intl.macro';
export const messages = defineMessages({
    eventStatusLabel: {
        id: 'jamStatusWidget.eventStatusLabel',
        defaultMessage: 'Event Status',
    },
    jamStatusNotStarted: {
        id: 'jamStatusWidget.jamStatusNotStarted',
        defaultMessage: 'Not started',
    },
    jamStatusOngoing: {
        id: 'jamStatusWidget.jamStatusOngoing',
        defaultMessage: 'Ongoing',
    },
    jamStatusEnded: {
        id: 'jamStatusWidget.jamStatusEnded',
        defaultMessage: 'Ended',
    },
    jamStatusCancelled: {
        id: 'jamStatusWidget.jamStatusCancelled',
        defaultMessage: 'Cancelled',
    },
    jamStatusError: {
        id: 'jamStatusWidget.jamStatusError',
        defaultMessage: 'Error',
    },
    jamStatusPending: {
        id: 'jamStatusWidget.jamStatusPending',
        defaultMessage: 'Pending',
    },
    jamStatusUpdating: {
        id: 'jamStatusWidget.Updating',
        defaultMessage: 'Updating',
    },
});

export default messages;
