import _ from 'lodash';

export const getErrorPropertyKey = (property, providerArn) => `${property}:${providerArn}`;

/**
 * Parses errors thrown from backend during class creation or update.
 * errorObject has the following shape:
 * errorObject : {
 *     errors : [
 *          {
 *               property: "classCapacity",
 *               errorCodes: [
 *                   {
 *                       message: string,
 *                       code: string, // aways starts with PropertyError.${errorCode}
 *                       values: any //depends on the error code
 *                   }
 *               ]
 *          }
 *     ]
 * }
 *
 *
 * In addition, for global providers that require sub providers (multi Gilmore id), errors can be sub provider specific.
 * There are two types of error possible on the sub providers: AvailableVirtualStocksMismatch and IncreaseInvalid.
 * Both error types are thrown when there isn't enough license to accommodate request student capacity.
 * AvailableVirtualStocksMismatch is thrown during class creation and IncreaseInvalid is thrown in class update.
 * The shape of AvailableVirtualStocksMismatch is:
 * {
 *   "errors": [
 *     {
 *       "property": "classCapacity",
 *       "errorCodes": [
 *         {
 *           "message": "Class capacity doesnt match the available license quantity for one or more providers",
 *           "code": "PropertyError:AvailableVirtualStocksMismatch",
 *           "values": [
 *             {
 *               "providerArn": "arn:aws:learningclassrooms:::provider/5f5465ab-4e29-48ef-9d82-dde58f92cfb6",
 *               "availableQuantity": 20
 *             },
 *             {
 *               "providerArn": "arn:aws:learningclassrooms:::provider/a84ecb62-bb31-4d8d-a123-8a5aa917ec56",
 *               "availableQuantity": 19
 *             }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 * }
 *
 * This function returns a map of property to array of errors.
 * For errors that are specific to a sub providers, subprovider provider arn is appended to the property using @link getErrorPropertyKey
 *
 * @param errorObject
 * @param provider
 */
export const parseError = (errorObject) => {
    const pushError = (allErrorsByProperty, property, code, message, values, providerArn) => {
        const key = _.isEmpty(providerArn) ? property : getErrorPropertyKey(property, providerArn);
        if (!allErrorsByProperty[key]) {
            allErrorsByProperty[key] = [];
        }
        allErrorsByProperty[key].push({
            ...{
                code: code.replace('PropertyError:', ''),
                message,
            },
            ...(_.isUndefined(values) ? {} : { values }),
            ...(!_.isEmpty(providerArn) ? { actualProperty: property, providerArn } : {}),
        });
    };

    const errorParser = (accumulated, property, code, values, message) => {
        let isSubProviderError = _.isArray(values) && !_.isEmpty(values) && values[0].providerArn;

        if (isSubProviderError) {
            let hasParentProviderLevelError = false;
            values.forEach((value) => {
                if (value.providerArn) {
                    pushError(
                        accumulated,
                        property,
                        code,
                        message,
                        value.availableQuantity,
                        value.providerArn,
                    );
                } else {
                    hasParentProviderLevelError = true;
                }
            });
            if (!hasParentProviderLevelError) {
                pushError(accumulated, property, code, message);
            }
        } else {
            pushError(accumulated, property, code, message, values);
        }
    };

    return errorObject.errors.reduce((acc, { property, errorCodes }) => {
        const errorCodesProcessed = {};
        errorCodes.forEach(({ code, values, message }) => {
            errorParser(errorCodesProcessed, property, code, values, message);
        });

        return {
            ...acc,
            ...errorCodesProcessed,
        };
    }, {});
};
