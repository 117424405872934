import {
    ColumnLayout,
    FormField,
    Input,
    Container,
    Header,
    RadioGroup,
    Select,
} from '@amzn/awsui-components-react-v3';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '../ClassForm.messages';
import {
    LOCATION_TYPES,
    handleFormValueChange,
    useLocationTypeLabel,
    prepareCountriesForSelect,
} from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import '../ClassForm.css';

const ClassLocation = ({
    setClassData,
    locationType,
    virtualUrl,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    fieldsInvalid,
    handleFormSubmit,
    isGrimsbyClass,
    isNewClass,
}) => {
    const { formatMessage } = useIntl();
    const countryOptions = useMemo(() => prepareCountriesForSelect(formatMessage), [formatMessage]);
    const virtualLocationLabel = useLocationTypeLabel(LOCATION_TYPES.virtual);
    const physicalLocationLabel = useLocationTypeLabel(LOCATION_TYPES.physical);

    const controlsDisabled = !!isGrimsbyClass && !isNewClass;

    const getOptionForCountry = (countryOption) =>
        countryOptions.find((o) => o.id === countryOption);

    const handleKeyboardSubmit = (e) => {
        if (e.detail.key === 'Enter') handleFormSubmit(e);
    };

    return (
        <Container
            id='class-location'
            header={<Header variant='h3'>{formatMessage(messages.classLocationHeader)}</Header>}
        >
            <ColumnLayout>
                <FormField
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'locationType',
                    })}
                    label={formatMessage(messages.locationTypeLabel)}
                >
                    <RadioGroup
                        value={locationType}
                        onChange={(e) => {
                            handleFormValueChange({
                                value: e.detail.value,
                                setData: setClassData,
                                keyPath: 'locationType',
                            });
                        }}
                        items={[
                            {
                                value: LOCATION_TYPES.virtual,
                                label: virtualLocationLabel,
                                disabled: controlsDisabled,
                            },
                            {
                                value: LOCATION_TYPES.physical,
                                label: physicalLocationLabel,
                                disabled: controlsDisabled,
                            },
                        ]}
                        ariaRequired
                        id='ClassForm__locationType'
                    ></RadioGroup>
                </FormField>
                {locationType === LOCATION_TYPES.virtual ? (
                    <FormField
                        stretch
                        description={formatMessage(messages.virtualDescription)}
                        label={formatMessage(messages.virtualClassUrlLabel)}
                        errorText={showErrorMessage(formatMessage, {
                            fieldsInvalid,
                            property: 'virtualUrl',
                        })}
                    >
                        <Input
                            data-testid='virtual-url'
                            ariaRequired
                            value={virtualUrl}
                            invalid={!!fieldsInvalid.virtualUrl}
                            onChange={(e) =>
                                handleFormValueChange({
                                    value: e.detail.value,
                                    setData: setClassData,
                                    keyPath: 'virtualUrl',
                                })
                            }
                            placeholder='https://aws.training'
                            onKeyDown={handleKeyboardSubmit}
                            disabled={controlsDisabled}
                        ></Input>
                    </FormField>
                ) : null}
                {locationType === LOCATION_TYPES.physical ? (
                    <Fragment>
                        <ColumnLayout columns={2}>
                            <FormField
                                stretch
                                label={formatMessage(messages.addressLine1Label)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'addressLine1',
                                })}
                            >
                                <Input
                                    ariaRequired
                                    data-testid='address-line-1'
                                    invalid={!!fieldsInvalid.addressLine1}
                                    value={addressLine1}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'addressLine1',
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                stretch
                                label={formatMessage(messages.addressLine2Label)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'addressLine2',
                                })}
                            >
                                <Input
                                    data-testid='address-line-2'
                                    value={addressLine2}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'addressLine2',
                                        })
                                    }
                                />
                            </FormField>
                        </ColumnLayout>
                        <ColumnLayout columns={2}>
                            <FormField
                                stretch
                                label={formatMessage(messages.cityLabel)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'city',
                                })}
                            >
                                <Input
                                    ariaRequired
                                    data-testid='address-city'
                                    value={city}
                                    invalid={!!fieldsInvalid.city}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'city',
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                stretch
                                label={formatMessage(messages.stateLabel)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'state',
                                })}
                            >
                                <Input
                                    ariaRequired
                                    data-testid='address-state'
                                    invalid={!!fieldsInvalid.state}
                                    value={state}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'state',
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                stretch
                                label={formatMessage(messages.postalCodeLabel)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'postalCode',
                                })}
                            >
                                <Input
                                    ariaRequired
                                    data-testid='address-postal'
                                    invalid={!!fieldsInvalid.postalCode}
                                    value={postalCode}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'postalCode',
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                stretch
                                label={formatMessage(messages.countryLabel)}
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid,
                                    property: 'country',
                                })}
                            >
                                <Select
                                    ariaRequired
                                    data-testid='address-country'
                                    invalid={!!fieldsInvalid.country}
                                    selectedOption={getOptionForCountry(country)}
                                    filteringType='auto'
                                    ariaLabel={formatMessage(messages.countryPlaceholder)}
                                    options={countryOptions}
                                    placeholder={formatMessage(messages.countryPlaceholder)}
                                    selectedLabel={formatMessage(messages.selectedLabel)}
                                    onChange={(e) =>
                                        handleFormValueChange({
                                            value: e.detail.selectedOption.id,
                                            setData: setClassData,
                                            keyPath: 'country',
                                        })
                                    }
                                ></Select>
                            </FormField>
                        </ColumnLayout>
                    </Fragment>
                ) : null}
            </ColumnLayout>
        </Container>
    );
};

export default ClassLocation;
