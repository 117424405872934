import { defaultTo } from 'lodash';
import { useIntl } from 'react-intl';

import { getErrorMessage } from './ClassForm.messages';

export const ErrorMessage = ({ children }) => (
    <p className='class-form__error-message'>{children}</p>
);

export const showErrorMessage = (formatMessage, { fieldsInvalid, property, valueFormatter }) => {
    const errors = fieldsInvalid[property] ?? [];
    if (!errors.length) return undefined;
    const { code, actualProperty, values } = errors[0];

    return formatMessage(getErrorMessage(defaultTo(actualProperty, property), code, values), {
        values: valueFormatter ? valueFormatter(code, values) : values,
    });
};

export const FieldErrors = ({ fieldsInvalid, property, valueFormatter = null }) => {
    const { formatMessage } = useIntl();

    return fieldsInvalid[property] ? (
        <div id={`errors--${property}`}>
            {fieldsInvalid[property].map(
                ({ code, actualProperty, values }, i) =>
                    code && (
                        <ErrorMessage data-testid={code} key={`${code}-${i}`}>
                            {formatMessage(
                                getErrorMessage(defaultTo(actualProperty, property), code, values),
                                { values: valueFormatter ? valueFormatter(code, values) : values },
                            )}
                        </ErrorMessage>
                    ),
            )}
        </div>
    ) : null;
};
